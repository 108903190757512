import { Routes, Route } from "react-router-dom";
import ProposalList from "../../../components/Private/Professional/ProposalList";
import ContractList from "../../../components/Private/Professional/ContractList";
import ContractDetails from "../../../components/Private/Professional/ContractDetails";
import ProposalDetails from "../../../components/Private/Professional/ProposalDetails";

const ProfessionalMyJobs = () => {
  return (
    <>
      <ProposalList />
      <ContractList />
      <Routes>
        <Route
          path="/contracts/details/:contractId"
          element={<ContractDetails />}
        />
        <Route
          path="/proposals/details/:proposalId"
          element={<ProposalDetails />}
        />
      </Routes>
    </>
  );
};

export default ProfessionalMyJobs;
