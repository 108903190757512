import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addShiftModalIsOpen: false,
  editShiftModal: {
    isOpen: false,
    shiftId: null
  },
  addProposalModalIsOpen: false,
  addProfileImgModalIsOpen: false,
  editProfileModalIsOpen: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openAddShiftModal(state, action) {
      state.addShiftModalIsOpen = true;
    },
    closeAddShiftModal(state) {
      state.addShiftModalIsOpen = false;
    },
    openEditShiftModal(state, action) {
      state.editShiftModal.isOpen = true;
      state.editShiftModal.shiftId = action.payload ?? null;
    },
    closeEditShiftModal(state) {
      state.editShiftModal.isOpen = false;
      state.editShiftModal.shiftId = null;
    },
    openAddProposalModal(state) {
      state.addProposalModalIsOpen = true;
    },
    closeAddProposalModal(state) {
      state.addProposalModalIsOpen = false;
    },
    openAddProfileImgModal(state) {
      state.addProfileImgModalIsOpen = true;
    },
    closeAddProfileImgModal(state) {
      state.addProfileImgModalIsOpen = false;
    },
    openEditProfileModal(state) {
      state.editProfileModalIsOpen = true;
    },
    closeEditProfileModal(state) {
      state.editProfileModalIsOpen = false;
    },
  },
});

export const {
  openAddShiftModal,
  closeAddShiftModal,
  openEditShiftModal,
  closeEditShiftModal,
  openAddProposalModal,
  closeAddProposalModal,
  openAddProfileImgModal,
  closeAddProfileImgModal,
  openEditProfileModal,
  closeEditProfileModal,
} = uiSlice.actions;

export const getUI = (state) => state.ui;

export default uiSlice.reducer;
