import Container from "../../components/UI/Container";
import { List, Title } from '@mantine/core';
import { TypographyStylesProvider } from '@mantine/core';


const PrivacyPolicy = () => {
    return (
        <>
            <Container>
            <TypographyStylesProvider>
                <div className="max-w-[700px]">
                    <Title order={1}>Pol&iacute;tica de Privacidad</Title>
                    <p>
                        <span size="3">Esta Política de Privacidad describe cómo Carendar.app (el “Sitio”)
                            recopila, utiliza y protege tu información personal cuando visitas
                        </span><u><a href="http://www.carendar.app/"> www.carendar.app</a></u>
                    </p>
                </div>

                <div className="w-[94px] h-[7px] mb-[35px]"></div>
                <Title order={2}>INFORMACI&Oacute;N PERSONAL QUE RECOPILAMOS</Title>
                <p>Cuando visitas nuestro sitio web, recopilamos automáticamente cierta
                    información sobre tu dispositivo, incluida información sobre tu navegador
                    web, dirección IP, zona horaria y algunas de las cookies que están
                    instaladas en tu dispositivo.
                </p>
                <p>
                    <span size="3">Además, a medida que navegas por nuestro Sitio, recopilamos información
                        sobre las páginas web individuales o los productos que ves, qué sitios web o términos
                        de búsqueda te remiten a nuestro Sitio, e información sobre cómo interactúas con el
                        Sitio. </span>
                </p>
                <p>Recopilamos informaci&oacute;n del dispositivo utilizando las siguientes tecnolog&iacute;as:</p>

                <div className="w-fit text-m mt-[35px] flex items-center list bullet">
                    <List listStyleType="disc">
                        <List.Item><strong>Cookies</strong>: son archivos de datos que se colocan en
                            tu dispositivo o computadora y con frecuencia incluyen un identificador
                            &uacute;nico an&oacute;nimo.
                        </List.Item>
                        <List.Item><strong>Archivos de registro</strong>: rastrean las acciones que
                            ocurren en el Sitio y recopilan datos, incluida tu direcci&oacute;n IP, el
                            tipo de navegador, el proveedor de servicios de Internet, las p&aacute;ginas
                            de referencia/salida y las marcas de fecha y hora.
                        </List.Item>

                    </List>

                </div>

            

                <div>
                    <span size="3">La forma en que recopilamos y almacenamos la información
                        dependerá de los Servicios que utilices. Puedes usar parte del Sitio y
                        los Servicios de Carendar sin proporcionar ninguna información que no sea
                        la recopilada automáticamente.
                    </span>
                </div>

            

                <div>
                    <p>Los visitantes que acceden y navegan por el Sitio sin registrarse les
                        llamamos los "Visitantes del sitio". Para utilizar todos los Servicios
                        ofrecidos por Carendar.app, debes registrarte en el Sitio. Todos los
                        usuarios que se registran en Carendar.app los llamamos "Usuarios registrados".
                    </p>

                

                    <div>
                        <p>Independientemente de si eres un Visitante del sitio o un Usuario registrado,
                            toda la información que nos proporciones cuando te registres, publiques un
                            trabajo, publiques un perfil profesional o te comunique a través del Sitio,
                            será almacenado por nosotros. Puedes proporcionarnos información de varias
                            maneras, como, por ejemplo:
                        </p>
                    
                        <div>

                            <List>
                                <List.Item>
                                    <Title order={2}>Al registrarse</Title>
                                    <p>
                                        Cuando te registres directamente a través de Carendar.app, recopilaremos y
                                        almacenaremos la información que nos proporciones en nuestros formularios de
                                        registro en línea.
                                    </p>
                                    <p>Si usas el botón de registro de Google, recopilaremos la información personal
                                        que hayas hecho pública en el perfil asociado a tu cuenta, incluyendo la dirección
                                        de correo electrónico.
                                    </p>
                                
                                </List.Item>
                                <List.Item>
                                    <Title order={2}>Al publicar un trabajo</Title>
                                    <p>
                                        Si tú estás buscando un profesional, recopilamos y almacenamos toda la información
                                        adicional que nos proporciones en tu publicación del trabajo. Esto puede incluir,
                                        entre otra información:
                                    </p>

                                    <List listStyleType="disc">
                                        <List.Item>
                                            el tipo de atención que está buscando, horario, ubicación, tarifa por hora,
                                            descripción narrativa del trabajo, etc.
                                        </List.Item>
                                        <List.Item>
                                            imágenes que elijas proporcionar
                                        </List.Item>

                                        <List.Item>
                                            información de salud, si decides proporcionarla
                                        </List.Item>
                                        <List.Item>
                                            cualquier otra información que elijas incluir en tu anuncio de trabajo
                                        </List.Item>
                                    </List>
                                </List.Item>
                            
                                <List.Item>
                                    <Title order={2}>Al publicar un perfil</Title>
                                    <p>
                                        Si eres un profesional de atención, recopilamos y almacenamos toda la información
                                        adicional que nos proporciones en tu perfil. Esto puede incluir, entre otra
                                        información:
                                    </p>

                                    <List listStyleType="disc">

                                        <List.Item>
                                            detalles sobre la atención que ofreces, por ejemplo, el tipo de atención que
                                            brindas, tu disponibilidad, tu ubicación, nivel de educación, etc.
                                        </List.Item>
                                        <List.Item>
                                            servicios relacionados que proporcionas
                                        </List.Item>
                                        <List.Item>
                                            fotos que elijas publicar
                                        </List.Item>
                                        <List.Item>
                                            números de teléfono
                                        </List.Item>
                                        <List.Item>
                                            referencias, si elige proporcionarlas
                                        </List.Item>
                                        <List.Item>
                                            cualquier otra informaci&oacute;n que elijas incluir en tu perfil
                                        </List.Item>
                                    </List>
                                </List.Item>

                            </List>
                        </div>
                    </div>
                </div>

            
                <div>
                    <Title order={2}>C&Oacute;MO USAMOS TU INFORMACI&Oacute;N PERSONAL</Title>
                    <p>
                        Usamos esta Información para: comunicarnos contigo, para (en línea con las preferencias
                        que has compartido con nosotros) ofrecerte información o publicidad relacionada con nuestros
                        productos o servicios.
                    </p>
                
                    <p>
                        Utilizamos la Información del Dispositivo que recopilamos para ayudarnos a detectar posibles
                        riesgos y fraudes (en particular, tu dirección IP) y, en general, para mejorar y optimizar
                        nuestro sitio.
                    </p>
                </div>

            

                <Title order={2}>COMPARTIENDO TU INFORMACI&Oacute;N PERSONAL</Title>

                <p>
                    Compartimos tu Información Personal con terceros para ayudarnos a utilizarla como se
                    describió anteriormente
                </p>

                <p><span size="3">También empleamos Google Analytics para ayudarnos a comprender cómo nuestros
                    clientes usan Carendar.app.</span><a href="https://www.google.com/intl/en/policies/privacy/">
                        <u>C&oacute;mo usa Google tu Informaci&oacute;n Personal.</u></a>
                </p>
                <p>
                    Finalmente, también podemos compartir tu Información Personal para cumplir con las leyes y
                    regulaciones aplicables, para responder a una citación, una orden de registro u otras
                    solicitudes legales de información que recibimos, o para proteger nuestros derechos.
                </p>

            

                <Title order={2}>Enlaces a otros sitios web</Title>
                <p>
                    El sitio web de Carendar y los Servicios pueden contener enlaces a sitios web y servicios de
                    terceros. Sin embargo, no somos responsables de las prácticas de privacidad empleadas por
                    esos sitios web o servicios, ni somos responsables de la información o el contenido que
                    contienen. Esta Política de Privacidad se aplica únicamente a la información recopilada
                    por nosotros a través del Sitio y los Servicios; por lo tanto, cuando utilizas un enlace
                    para ir del Sitio o los Servicios a un sitio web o servicio de un tercero, esta política
                    ya no estará vigente y prevalecerá la política de privacidad de dicho sitio o servicio de
                    un tercero.
                </p>

            

                <Title order={2}>PUBLICIDAD DE COMPORTAMIENTO</Title>
                <p>
                    Utilizamos tu Información Personal para proporcionarte anuncios específicos o comunicaciones 
                    de marketing que creemos que pueden ser de tu interés.
                </p>
                <p>
                    Estos anuncios pueden estar dirigidos a ti en función de la información que terceros 
                    autorizados conocen o infieren sobre ti e incluyen en las cookies colocadas en tu navegador 
                    y/o en la información sobre tus actividades de navegación en Internet recopilada a través 
                    de tu dispositivo.
                </p>
                <p>
                    Puedes inhabilitar la publicidad dirigida si prefieres no recibir estos anuncios 
                    deshabilitando las cookies de exclusión voluntaria.
                </p>

            

                <Title order={2}>TUS DERECHOS</Title>
                <p>Todos los usuarios pueden hacer uso de sus derechos otorgados por la normativa de 
                    protección de datos, como el derecho de acceso, limitación del tratamiento, supresión, 
                    portabilidad, etc. Puedes hacerlo enviando un correo electrónico a <u><a href="mailto:contacto@reebit.cl">contacto@Carendar.cl</a></u>.
                </p>

            

                <Title order={2}>CAMBIOS EN POL&Iacute;TICAS DE PRIVACIDAD</Title>
                <p>
                    Las Políticas de Privacidad de CARENDAR podrían sufrir actualizaciones, cambios y 
                    modificaciones acordes a las necesidades legales de la empresa. Te recomendamos 
                    visitar y acceder a las Políticas de Privacidad de forma periódica para poder conocer 
                    los últimos cambios que pudieran ser incorporados en este documento.
                </p>

                <p>
                    La lectura y comprensión de las Políticas de Privacidad de Carendar son obligatorias 
                    para cualquier persona que ingrese o use los productos del Sitio. En caso de tener 
                    dudas o inquietudes te puedes contactar con nosotros a través del siguiente email:  
                    <u><a href="mailto:contacto@reebit.cl">contacto@Carendar.cl</a></u>.</p>

            

                <Title order={2}>MENORES DE EDAD</Title>
                <p>
                    <span size="3">Nuestros servicios s&oacute;lo est&aacute;n disponibles para aquellas
                        personas que tengan capacidad legal para contratar un servicio o para entregarlo. Por lo
                        tanto, aquellos que no cumplan con esta condici&oacute;n deber&aacute;n abstenerse de
                        crear cuentas y/o suministrar informaci&oacute;n personal para ser incluida en nuestras
                        bases de datos. Carendar se reserva el derecho a rechazar su inscripci&oacute;n sin
                        expresi&oacute;n de causa.&nbsp;</span>
                </p>
            </TypographyStylesProvider>
            </Container>

        </>
    );
};

export default PrivacyPolicy;


// .../auth/userinfo.email	See your primary Google Account email address	
// .../auth/userinfo.profile	See your personal info, including any personal info you've made publicly available