import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mantine/core";

const ChooseSignUp = () => {
  const [isPatient, setIsPatient] = useState(null);
  const navigate = useNavigate();

  const patientClickHandler = () => {
    setIsPatient(true);
  };

  const profesionalClickHandler = () => {
    setIsPatient(false);
  };

  const submitButtonHandler = () => {
    if (isPatient === null) return;
    navigate(`../${isPatient ? "patient-signup" : "professional-signup"}`);
  };

  return (
    <div className="mt-16 mb-12">
      <div className="sm:flex justify-between mb-6">
        <div
          className={`relative bg-white rounded-[23px] text-center flex flex-col justify-center items-center border h-72 p-6 cursor-pointer w-full ${
            isPatient ? "border-primary-dark" : "border-[#8EC2F2]"
          }`}
          onClick={patientClickHandler}
        >
          <div
            className={`w-6 h-6 absolute right-3 top-3 border border-primary-dark rounded-full ${
              isPatient ? "bg-primary-dark" : ""
            }`}
          ></div>
          <img src="/assets/images/auth-patient.png" alt="" className="w-20" />
          <p className="font-bold mt-4">
            Soy un paciente o una institución contratando a un profesional
          </p>
        </div>
        <div
          className={`relative bg-white rounded-[23px] text-center flex flex-col justify-center items-center border  h-72 p-6 cursor-pointer mt-8 sm:mt-0 sm:ml-14 w-full ${
            isPatient === false ? "border-primary-dark" : "border-[#8EC2F2]"
          }`}
          onClick={profesionalClickHandler}
        >
          <div
            className={`w-6 h-6 absolute right-3 top-3 border border-primary-dark rounded-full ${
              isPatient === false ? "bg-primary-dark" : ""
            }`}
          ></div>
          <img
            src="/assets/images/auth-professional.png"
            alt=""
            className="w-20"
          />
          <p className="font-bold mt-4">Soy un profesional busco trabajo</p>
        </div>
      </div>

      <Button
        className="bg-primary-dark text-white w-full hover:bg-primary-dark rounded-[5px] h-10 border-none"
        onClick={submitButtonHandler}
      >
        {isPatient === null
          ? "Seleccione uno:)"
          : isPatient
          ? "Únase como paciente"
          : "Únete como profesional"}
      </Button>
    </div>
  );
};

export default ChooseSignUp;
