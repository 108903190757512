import React from "react";
import { NavLink } from "react-router-dom";

import Container from "../UI/Container";

const Hero = ({ title, topLabel, text, buttons, img, cards }) => {
  const paragraphs = text.map((paragraph) => {
    return paragraph.body.split(" ").map((word) => {
      return { textWord: word, isBold: paragraph.bolds.includes(word) };
    });
  });

  return (
    <section className="bg-light-gray pb-3">
      <Container>
        <div className="w-full md:w-[110%] md:flex items-center mb-16">
          <div className="w-full font-bold md:w-[44%]">
            {/* Top Label */}
            {topLabel && (
              <p className="text-base text-primary-dark mb-9">{topLabel}</p>
            )}

            {/* Title */}
            <h1 className="text-[2.7em] md:text-[3.5em] leading-snug text-primary-text-color mb-9">
              {title ? title : "Give a Title"}
            </h1>

            {/* Text content */}
            {paragraphs &&
              paragraphs.length > 0 &&
              paragraphs.map((paragraph, idx) => (
                <p
                  key={idx}
                  className="text-xl font-normal text-secondary-text-color mb-9"
                >
                  {paragraph.map((word, idx) => (
                    <span key={idx} className={word.isBold ? "font-bold" : ""}>
                      {word.textWord}{" "}
                    </span>
                  ))}
                </p>
              ))}

            {/* Buttons */}
            {buttons && buttons.length > 0 && (
              <div className="text-sm flex items-center">
                <NavLink
                  to={buttons[0].link}
                  className="text-white bg-primary-dark py-[10px] px-4 rounded-[5px] border border-primary-dark block"
                >
                  {buttons[0].text}
                </NavLink>
                <NavLink
                  to={buttons[1].link}
                  className="text-primary-dark bg-transparent py-[10px] px-4 rounded-[5px] border border-primary-dark block ml-4"
                >
                  {buttons[1].text}
                </NavLink>
              </div>
            )}
          </div>

          {/* Image */}
          <div className={`w-full md:w-[45em] md:min-h-[1em] mt-12 md:mt-0 flex flex-col justify-center`}>
            <img src={img} alt="" />
          </div>
        </div>

        {/* Cards */}
        {cards && cards.length > 0 && (
          <div className="flex justify-between flex-wrap gap-6">
            <div className="group bg-white w-full max-w-[300px] px-10 py-[35px] shadow-customBox transition-all hover:shadow-xl hover:-translate-y-2 hover:rotate-2 hover:scale-105">
              <div className="w-[4.5em] h-[4.5em] rounded-full bg-primary-light flex justify-center items-center">
                {cards[0].icon}
              </div>
              <h3 className="text-base font-bold text-primary-text-color my-5">
                {cards[0].title}
              </h3>
              <div className="w-[50px] h-[2px] bg-primary-light mb-5 transition-all group-hover:w-full"></div>
              <p className="text-sm font-normal text-secondary-text-color">
                {cards[0].text}
              </p>
            </div>

            <div className="group bg-white w-full max-w-[300px] px-10 py-[35px] shadow-customBox transition-all hover:shadow-xl hover:-translate-y-2 md:ml-[30px] hover:scale-105">
              <div className="w-[4.5em] h-[4.5em] rounded-full bg-primary-light flex justify-center items-center">
                {cards[1].icon}
              </div>
              <h3 className="text-base font-bold text-primary-text-color my-5">
                {cards[1].title}
              </h3>
              <div className="w-[50px] h-[2px] bg-primary-light mb-5 transition-all group-hover:w-full"></div>
              <p className="text-sm font-normal text-secondary-text-color">
                {cards[1].text}
              </p>
            </div>

            <div className="group bg-white w-full max-w-[300px] px-10 py-[35px] shadow-customBox transition-all hover:shadow-xl hover:-translate-y-2 hover:-rotate-2 md:ml-[30px] hover:scale-105">
              <div className="w-[4.5em] h-[4.5em] rounded-full bg-primary-light flex justify-center items-center">
                {cards[2].icon}
              </div>
              <h3 className="text-base font-bold text-primary-text-color my-5">
                {cards[2].title}
              </h3>
              <div className="w-[50px] h-[2px] bg-primary-light mb-5 transition-all group-hover:w-full"></div>
              <p className="text-sm font-normal text-secondary-text-color">
                {cards[2].text}
              </p>
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};

export default Hero;

// This is a complete configurable component for the Hero section of the Page
// Check below to see how this component can be configured
// We call the component like this: <Hero title="Title" topLabel="TopLabel" text={[{body: "Some text", bolds: "text"}]} buttons={[{text: "Button Title"}]} img={imgElem} cards={[{title: "", text: "", icon: svgIcon}]} />
