import { FileInput } from '@mantine/core'
import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getDocument, getVerificationDocument, updateVerificationDocument } from '../../../features/verification/verificationSlice';

export default function VerificationDocuments() {

    const [documents, setDocuments] = useState({
        "curriculum":null,
        "title_certificate":null,
        "sis_registration_number":null,
        "background_certificate":null,
        "id_card":null
    });
    const dispatch = useDispatch();

    const {verificationDocuments, status, error, updateVerificationDocumentStatus} = useSelector(getDocument)
    // const verificationDocuments = {};
    const documentStatus = {
        1: "Missing",
        2: "Uploaded",
        3: "Verified",
        4: "Rejected"
    }

    useEffect(()=>{
        dispatch(getVerificationDocument());
    }, [dispatch])

    const selectFileHandler = (event) => {
        // Get ONLY ONE file from the uploaded files(the first one)
        const file = event.target.files[0];
        const fieldId = event.target.id;
        // Uploaded file checks
        const { type } = file;
        if (type !== 'application/pdf') {
            return;
        }
        setDocuments({...documents, [fieldId]: file });
    };

    const submitFormHandler = (event) => {
        event.preventDefault();
        const formData = new FormData();

        for (const key in documents) {
            if (documents.hasOwnProperty(key)) {
            const value = documents[key];
            formData.append(key, value);
            }
        }
        console.log(documents)
        dispatch(updateVerificationDocument(formData));
    }

  return (
    <section className="border-t border-primary-dark py-4 px-8">
        
        <p className="text-primary-dark mb-1 font-bold">
            Por favor mande sus credenciales usando el siguiente formulario o enviando sus documentos a
            <b> registro@carendar.app</b>. 
        </p>
        <p className='mb-1'>
        Una vez que sus credenciales hayan sido verificadas, tendra acceso a la aplicación completa.
        </p>
        

        <form onSubmit={submitFormHandler}>
            <div className='flex flex-row my-3'>
                <div className='flex flex-col mx-3 w-40'>
                    <label>Status: {documentStatus[verificationDocuments["status_curriculum"]]}</label>
                </div>
                <div className='flex flex-col '>
                    <label
                        className="text-primary-dark font-semibold text-sm decoration-primary-dark"
                    >
                        CV del profesional of breve descripcion de la trayectoria profesional.
                    </label>
                    {verificationDocuments.status_curriculum>1 ? 
                    <label className="text-primary-dark font-semibold text-sm decoration-primary-dark text-green-900">Enviado</label> : <input
                        type="file"
                        id="curriculum"
                        name="curriculum"
                        onChange={selectFileHandler}
                        required
                    />}
                </div>
            </div>
            <div className='flex flex-row my-3'>
                <div className='flex flex-col mx-3 w-40'>
                    <label>Status: {documentStatus[verificationDocuments["status_title_certificate"]]}</label>
                </div>
            <div className='flex flex-col my-3'>
                <label
                    className="text-primary-dark font-semibold text-sm decoration-primary-dark"
                >
                    Certificado de Título (si corresponde)
                </label>
                {verificationDocuments.status_title_certificate>1 ? 
                    <label className="text-primary-dark font-semibold text-sm decoration-primary-dark text-green-900">Enviado</label> : <input
                    type="file"
                    id="title_certificate"
                    name="title_certificate"
                    onChange={selectFileHandler}
                />}
            </div>
            </div>
            <div className='flex flex-row my-3'>
                <div className='flex flex-col mx-3 w-40'>
                    <label>Status: {documentStatus[verificationDocuments["status_sis_registration_number"]]}</label>
                </div>
            <div className='flex flex-col my-3'>
                <label
                    className="text-primary-dark font-semibold text-sm decoration-primary-dark"
                >
                    Número de registro en el SII (si corresponde)
                </label>
                {verificationDocuments.status_sis_registration_number>1 ? 
                    <label className="text-primary-dark font-semibold text-sm decoration-primary-dark text-green-900">Enviado</label> : <input
                    type="file"
                    id="sis_registration_number"
                    name="sis_registration_number"
                    onChange={selectFileHandler}
                />}
            </div>
            </div>
            <div className='flex flex-row my-3'>
                <div className='flex flex-col mx-3 w-40'>
                    <label>Status: {documentStatus[verificationDocuments["status_background_certificate"]]}</label>
                </div>
            <div className='flex flex-col my-3'>
                <label
                    className="text-primary-dark font-semibold text-sm decoration-primary-dark"
                >
                    Certificado de antecedentes actual - https://www.registrocivil.cl/
                </label>
                {verificationDocuments.status_background_certificate>1 ? 
                    <label className="text-primary-dark font-semibold text-sm decoration-primary-dark text-green-900">Enviado</label> : <input
                    type="file"
                    id="background_certificate"
                    name="background_certificate"
                    onChange={selectFileHandler}
                    required
                />}
            </div>
            </div>
            <div className='flex flex-row my-3'>
                <div className='flex flex-col mx-3 w-40'>
                    <label>Status: {documentStatus[verificationDocuments["status_id_card"]]}</label>
                </div>
            <div className='flex flex-col my-3'>
                <label
                    className="text-primary-dark font-semibold text-sm decoration-primary-dark"
                >
                    Foto del carnet de identidad
                </label>
                {verificationDocuments.status_id_card>1 ? 
                    <label className="text-primary-dark font-semibold text-sm decoration-primary-dark text-green-900">Enviado</label> : <input
                    type="file"
                    id="id_card"
                    name="id_card"
                    onChange={selectFileHandler}
                    required
                />}
            </div>
            </div>
            <div className='flex flex-col my-3'>
                <label
                    className="text-primary-dark font-semibold text-sm decoration-primary-dark"
                >
                    Foto de perfil
                </label>
                Por favor actualizar la foto personal en la sección de Mi Perfil.
            </div>
            <div className='my-3'>
                <input type="submit" value="Submit" className="text-white bg-primary-dark px-4 py-2 rounded-full text-sm font-semibold"/>
            </div>
            <p className='mb-1'>
            *Por favor adjuntar archivos .pdf para cada campo obligatorio. 
            </p>
            
        </form>
    </section>
  )
}
