import * as yup from "yup";

export const shift = {
  schema: yup.object().shape({
    title: yup
      .string()
      .min(5, "El título debe tener al menos 5 caracteres")
      .required("El título es un campo obligatorio")
      .max(200, "El título debe tener como máximo 200 caracteres"),
    position: yup
      .string()
      .required("El puesto es un campo obligatorio")
      .max(30, "La posición debe tener como máximo 30 caracteres"),
    description: yup
      .string()
      .min(5, "La descripción debe tener al menos 5 caracteres")
      .required("La descripción es un campo obligatorio")
      .max(1000, "La descripción debe tener como máximo 1000 caracteres"),
    description_short: yup
      .string()
      .required("La descripción breve es un campo obligatorio")
      .max(100, "La descripción breve debe tener como máximo 100 caracteres"),
    location_city: yup
      .string()
      .required("La ubicación de la ciudad es un campo obligatorio")
      .max(
        50,
        "La ciudad de la ubicación debe tener como máximo 50 caracteres"
      ),
    location_region: yup
      .string()
      .required("La región de ubicación es un campo obligatorio")
      .max(50, "La región de ubicación debe tener como máximo 50 caracteres"),
    location_address: yup
      .string()
      .required("La dirección de la ubicación es un campo obligatorio")
      .max(
        100,
        "La dirección de la ubicación debe tener como máximo 100 caracteres"
      ),
    start_date: yup
      .date()
      .required("La fecha de inicio es un campo obligatorio")
      .nullable(),
    end_date: yup
      .date()
      .required("La fecha de finalización es un campo obligatorio")
      .nullable(),
      //.min(yup.ref("start_date")),
      //.date().min(start_date),
    shifts_per_week: yup
      .number()
      .required("El número de turnos es obligatorio")
      .nullable(true)
      .positive()
      .integer()
      .min(1),
    shift_length_in_hours: yup
      .number()
      .required("Se requiere la duración del turno")
      .nullable(true)
      .positive()
      .integer()
      .min(1),
    shift_hourly_rate: yup
      .number()
      .required("La tarifa por hora es requerida")
      .nullable(true)
      .positive(),
    institution: yup.string().required("Institución es un campo obligatorio"),
  }),
  initialValues: {
    title: "",
    position: "",
    description: "",
    description_short: "",
    location_city: "",
    location_region: "",
    location_address: "",
    start_date: null,
    end_date: null,
    shifts_per_week: null,
    shift_length_in_hours: null,
    shift_hourly_rate: null,
    institution: "",
  },
};
