import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Menu, Text, Button } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  AiFillEdit as EditIcon,
  AiFillDelete as DeleteIcon,
  AiFillEye as LiveIcon,
} from "react-icons/ai";
import { BiDotsHorizontalRounded as MenuIcon } from "react-icons/bi";
import { IoMdDoneAll as SucceessIcon } from "react-icons/io";
import { openEditShiftModal } from "../../../features/ui/uiSlice";
import { deleteShift } from "../../../features/shifts/shiftsSlice";

const ShiftItem = ({ shift }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modals = useModals();

  const openDeleteShiftModal = () => {
    modals.openConfirmModal({
      title: "Eliminar turno",
      centered: true,
      children: (
        <Text size="sm">
          {`Esta seguro/a que quiere eliminar su turno "${shift.title}"? Esta accion es 
          destructiva!`}
        </Text>
      ),
      labels: { confirm: "Eliminar turno", cancel: "Cancelar" },
      confirmProps: { className: "bg-red-600 ", color: "red" },
      onConfirm: async () => {
        showNotification({
          id: `delete-shift-${shift.id}`,
          loading: true,
          title: "Deleting shift",
          message: `The shift "${shift.title}" will be deleted soon, you cannot close this yet`,
          autoClose: false,
          disallowClose: true,
        });
        await dispatch(deleteShift(shift)).unwrap();
        updateNotification({
          id: `delete-shift-${shift.id}`,
          color: "green",
          title: "El turno fue eliminado",
          message: `El turno "${shift.title}" fue eliminado exitosamente!`,
          autoClose: 3000,
          icon: <SucceessIcon />,
        });
      },
    });
  };

  return (
    <>
      <li className="px-4 py-5 hover:bg-blue-50 cursor-pointer flex justify-between items-center">
        <div>
          <h3 className="font-bold mb-6">{shift.title}</h3>
          <p>{shift.description}</p>
        </div>
        <div className="ml-4 sm:flex">
          <Menu
            position="bottom"
            placement="end"
            transition="rotate-left"
            withArrow
          >
            <Menu.Target>
              <button className="p-2">
                <MenuIcon className="text-xl" />
              </button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Ajustes</Menu.Label>
              <Menu.Item
                icon={<EditIcon />}
                onClick={() => {
                  dispatch(openEditShiftModal(shift.id));
                }}
              >
                Editar Turno
              </Menu.Item>
              <Menu.Item
                icon={<LiveIcon />}
                onClick={() => {
                  navigate(`shifts/${shift.id}`);
                }}
              >
                Abrir Turno
              </Menu.Item>
              <Menu.Item
                icon={<DeleteIcon />}
                color="red"
                onClick={openDeleteShiftModal}
              >
                Eliminar Turno
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </li>
      <Divider />
    </>
  );
};

export default ShiftItem;
