import Hero from "../../components/Landing/Hero";
import Info from "../../components/Landing/Info";
import professionalsHeromImg from "../../assets/images/professionals_hero.png";
import { ReactComponent as Registro } from "../../assets/svg/registro.svg";
import { ReactComponent as Trabajo } from "../../assets/svg/trabajo.svg";
import { ReactComponent as Pago } from "../../assets/svg/pago.svg";
import professionalsInfoPhoneImg from "../../assets/images/phone_info.png";
import professionalsInfoPasosImg from "../../assets/images/pasos_info.png";
import professionalsInfoGirlImg from "../../assets/images/girldog_info.png";
import professionalsInfoPocketImg from "../../assets/images/pocket_info.png";

const Professionals = () => {
  return (
    <>
      <Hero
        title="La mejor forma de ganar más con horarios flexibles"
        // title="La mejor forma de ganar más en el horario que desees y de forma totalmente gratuita."
        topLabel=""
        text={[
          {
            // body: "Sólo Carendar le entrega al personal de la salud la flexibilidad para reservar trabajos on-demand",
            body: "Trabaja donde y cuando quieras! Carendar es la solución que necesitas",
            bolds: ["Carendar"],
          },
        ]}
        buttons={[
          { text: "Regístrate para trabajar" },
          { text: "Estoy buscando un profesional" },
        ]}
        img={professionalsHeromImg}
        cards={[
          {
            title: "Regístrate fácilmente",
            text: "Una vez revisado y aceptados tus antecedentes, comenzarás a recibir solicitudes de trabajo en tu área.",
            icon: <Registro />,
          },
          {
            title: "Reserva trabajos",
            text: "Sólo trabaja cuando y cuanto sea conveniente para ti. No existe un compromiso mínimo de trabajos por aceptar.",
            icon: <Trabajo />,
          },
          {
            title: "Gratis",
            // text: "Por medio de la misma plataforma, tus ganancias se depositan  en el medio de pago que tú nos indiques.",
            text: "Nuestro servicio es gratuito, no debes pagar por acceder a los trabajos!",
            icon: <Pago />,
          },
        ]}
      />
      <Info
        items={[
          {
            title: "¿Qué tal si....",
            body: [
              {
                text: "...pudieras ganar más dinero, establecer tus propios horarios y ser tu propio jefe??",
                bolds: [""],
              },
              {
                text: "Ahora puedes...con Carendar!",
                bolds: ["Carendar!"],
              },
            ],
            img: professionalsInfoPhoneImg,
          },
          {
            title: "¿Qué es Carendar?",
            body: [
              {
                // text: "Es una plataforma online gratuita que te conecta con cientos de Clínicas, Hospitales, Instituciones de Salud, Centros de adultos mayores y también con personas naturales que publican solicitudes de trabajos para profesionales de la salud como tú",
                text: "Es una plataforma online gratuita que te conecta con cientos de personas, Clínicas, Hospitales, Instituciones de Salud y Centros de adultos mayores que publican solicitudes de trabajos  para profesionales de la salud y del cuidado como tú.",
                bolds: [""],
              },
            ],
            img: professionalsInfoPasosImg,
          },
          {
            title: "Fácil de manejar",
            body: [
              {
                // text: "Podrás administrar tu agenda utilizando tu teléfono desde el lugar que tú quieras.",
                text: "Podrás administrar tu agenda desde el lugar que tú quieras.",
                bolds: [""],
              },
              {
                // text: "Cuando un particular o institución necesite un turno, recibirás una alerta de los trabajos disponibles para ti en tu área",
                text: "Cuando un particular o institución te necesite, recibirás una alerta de los trabajos disponibles para ti en tu área.",
                bolds: [""],
              },
            ],
            img: professionalsInfoGirlImg,
          },
          {
            title: "Información detallada",
            body: [
              {
                // text: "Podrás revisar los detalles del trabajo y el salario estimado.",
                text: "Podrás revisar los detalles del trabajo y el pago estimado o enviar una propuesta nueva.",
                bolds: [""],
              },
              {
                // text: "Si te acomoda y estás de acuerdo, puedes reservarlo inmediatamente desde la plataforma.",
                text: "Si te acomoda y estás de acuerdo, puedes reservarlo inmediatamente desde la plataforma.",
                bolds: [""],
              },
            //   {
            //     text: "Una vez terminado el trabajo podrás recibir el pago a través de la plataforma.",
            //     bolds: [""],
            //   },
            ],
            img: professionalsInfoPocketImg,
          },
        ]}
        footer="Regístrate gratis a través de www.carendar.app"
      />
    </>
  );
};

export default Professionals;
