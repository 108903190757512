import { useState, useEffect, useCallback } from "react";

const useAxios = () => {
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [controller, setController] = useState(null);

  const axiosFetch = useCallback(async (configObj) => {
    const { axiosInstance, method, url, requestConfig = {} } = configObj;

    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosInstance[method.toLowerCase()](url, {
        ...requestConfig,
        signal: ctrl.signal,
      });
      setResponse(res.data);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // useEffect clean up function is used to stop/abort running requests when
    // the component unmounts to prevent memory leaks
    return () => controller && controller.abort();
  }, [controller]);

  return [response, loading, error, axiosFetch];
};

export default useAxios;
