import { useState, useEffect } from "react";
import { Modal, TextInput, Button, Loader } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { closeEditProfileModal } from "../../../features/ui/uiSlice";
import {
  getAuth,
  updateProfile,
  resetUpdateProfileStatus,
} from "../../../features/auth/authSlice";
import { patientProfile } from "../../../validations/ProfileValidation";

const EditProfile = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const dispatch = useDispatch();
  const { user, updateProfileStatus } = useSelector(getAuth);

  const closeModalHandler = () => {
    setModalIsOpen(false);
    dispatch(resetUpdateProfileStatus());
    setTimeout(() => {
      dispatch(closeEditProfileModal());
    }, 350);
  };

  useEffect(() => {
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  const form = useForm({
    validate: yupResolver(patientProfile.schema),
    initialValues: {
      ...user,
    },
  });

  useEffect(() => {
    if (updateProfileStatus === "succeeded") {
      closeModalHandler();
    }
  }, [updateProfileStatus]);

  const submitFormHandler = (profile) => {
    dispatch(updateProfile(profile));
  };

  return (
    <Modal
      opened={modalIsOpen}
      onClose={closeModalHandler}
      transition="rotate-left"
      transitionDuration={350}
      withCloseButton={false}
      centered
      classNames={{
        modal: "!w-[700px] !p-0",
        title: "!text-xl",
      }}
    >
      <header className="flex justify-between items-center py-4 px-8">
        <p className="text-lg font-semibold">Edit Profile</p>
        <CloseIcon
          className="text-xl text-gray-600 cursor-pointer"
          onClick={closeModalHandler}
        />
      </header>
      <section className="border-t border-primary-dark py-4 px-8">
        <form className="mt-8" onSubmit={form.onSubmit(submitFormHandler)}>
          <TextInput
            label="Primer nombre"
            aria-label="First Name"
            size="md"
            className="mb-4"
            {...form.getInputProps("first_name")}
          />
          <TextInput
            type="text"
            name="last_name"
            placeholder="apellido"
            label="Apellido"
            aria-label="Last Name"
            size="md"
            className="mb-4"
            {...form.getInputProps("last_name")}
          />
          <TextInput
            type="email"
            name="email"
            placeholder="Tu email"
            label="Tu email"
            aria-label="Email"
            size="md"
            className="mb-4"
            {...form.getInputProps("email")}
          />
          <TextInput
            type="tel"
            name="phone_number"
            placeholder="Número de teléfono"
            label="Número de teléfono"
            aria-label="Telephone"
            size="md"
            className="mb-4"
            {...form.getInputProps("phone_number")}
          />
          <TextInput
            type="text"
            name="description"
            placeholder="Descripción personal o biografía"
            label="Descripción personal o biografía"
            aria-label="Description"
            size="md"
            className="mb-4"
            {...form.getInputProps("description")}
          />
          <div className="flex justify-center">
            <Button
              type="submit"
              className="bg-primary-dark hover:bg-primary-dark w-full sm:w-1/3"
            >
              {updateProfileStatus === "loading" && (
                <Loader variant="dots" color="dark" />
              )}
              {updateProfileStatus === "idle" && "Update Profile"}
            </Button>
          </div>
        </form>
      </section>
    </Modal>
  );
};

export default EditProfile;
