const authHeader = () => {
  const accessToken = localStorage.getItem("authToken");
  if (accessToken) {
    return { Authorization: `Token ${accessToken}` };
  } else {
    return {};
  }
};

export default authHeader;
