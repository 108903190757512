import { useEffect, useState } from "react";
import { Divider, Loader, ScrollArea, Popover } from "@mantine/core";
import { AiFillQuestionCircle as QuestionIcon } from "react-icons/ai";
import {
  getProposals,
  selectPatientProposals,
} from "../../../features/proposals/proposalsSlice";
import Container from "../../UI/Container";
import { useDispatch, useSelector } from "react-redux";

import ProposalItem from "./ProposalItem";

const ProposalList = () => {
  const [proposalsPopoverIsOpened, setProposalsPopoverIsOpened] =
    useState(false);
  const { proposals, status, error } = useSelector(selectPatientProposals);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProposals());
  }, [dispatch]);

  let content;
  if (status === "loading") {
    content = (
      <div className="flex items-center justify-center h-[410px]">
        <Loader />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <ul>
        {proposals.length > 0 &&
          proposals.map((proposal) => (
            <ProposalItem key={proposal.id} proposal={proposal} />
          ))}
      </ul>
    );
  } else if (status === "failed") {
    content = <p>{error}</p>;
  }

  return (
    <section className="mb-10">
      <Container>
        <div className="bg-white rounded-[5px]">
          <div className="flex items-center">
            <h3 className="text-lg text-primary-dark font-bold pl-4 py-5">
              Propuestas presentadas ({proposals.length}){" "}
            </h3>
            <Popover
              opened={proposalsPopoverIsOpened}
              onClose={() => setProposalsPopoverIsOpened(false)}
              position="top"
              withArrow
              width={260}
              transition="slide-down"
            >
              <Popover.Target>
                <button>
                  <QuestionIcon
                    className="text-lg ml-2 text-primary-light cursor-pointer"
                    onMouseEnter={() => setProposalsPopoverIsOpened(true)}
                    onMouseLeave={() => setProposalsPopoverIsOpened(false)}
                  />
                </button>
              </Popover.Target>
              <Popover.Dropdown>
                <p className="text-sm">
                  Estas son las propuestas que tu has enviado.
                </p>
              </Popover.Dropdown>
            </Popover>
          </div>
          <Divider className="border-primary-dark" />
          <ScrollArea style={{ height: 410 }} type="auto">
            {content}
          </ScrollArea>
        </div>
      </Container>
    </section>
  );
};

export default ProposalList;
