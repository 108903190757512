import { Divider } from "@mantine/core";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

const ProposalItem = ({ proposal }) => {
  dayjs.extend(RelativeTime);
  dayjs.extend(LocalizedFormat);

  const proposalDate = dayjs(proposal.created);
  const initiatedTime = proposalDate.format("ll");
  const timeFromNow = proposalDate.fromNow();

  return (
    <>
      <li className="px-4 py-5 group">
        <div className="text-sm sm:flex items-center justify-between w-full">
          <div>
            <p className="mb-2">Iniciada en {initiatedTime}</p>
            <p className="text-xs text-gray-600">{timeFromNow}</p>
          </div>
          <div>
            {proposal.shift_dict && (
              <Link
                to={`/professional/proposals/${proposal.id}`}
                className="text-primary-light cursor-pointer font-semibold hover:text-primary-dark hover:underline"
              >
                {proposal.shift_dict.title}
              </Link>
            )}
          </div>
          <p
            className={`inline-block ${
              proposal.status === 1
                ? "bg-yellow-500"
                : proposal.status === 3
                ? "bg-red-500"
                : proposal.status === 2
                ? "bg-green-500"
                : proposal.status === 4
                ? "bg-red-800"
                : ""
            } px-3 py-1 rounded-[5000px]`}
          >
            {proposal.status_display}
          </p>
        </div>
      </li>
      <Divider />
    </>
  );
};

export default ProposalItem;
