import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import proposalsService from "./proposalsService";

const initialState = {
  proposals: [],
  status: "idle", // "idle" | "loading" | "succeeded" | "failed"
  error: null,
  getSingleProposalStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  getSingleProposalError: null,
  createProposalStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  createProposalError: null,
  updateProposalStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  updateProposalError: null,
  rejectProposalStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  rejectProposalError: null,
  withdrawProposalStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  withdrawProposalError: null,
};

export const getProposals = createAsyncThunk(
  "proposals/getProposals",
  async (_, thunkAPI) => {
    try {
      return await proposalsService.getProposals();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const getSingleProposal = createAsyncThunk(
  "proposals/getSingleProposal",
  async (proposalId, thunkAPI) => {
    try {
      return await proposalsService.getSingleProposal(proposalId);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const createProposal = createAsyncThunk(
  "proposals/addProposal",
  async (proposal, thunkAPI) => {
    try {
      return await proposalsService.createProposal(proposal);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const updateProposal = createAsyncThunk(
  "proposals/updateProposal",
  async (proposal, thunkAPI) => {
    try {
      return await proposalsService.updateProposal(proposal);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const rejectProposal = createAsyncThunk(
  "proposals/rejectProposal",
  async (proposal, thunkAPI) => {
    try {
      return await proposalsService.rejectProposal(proposal);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const withdrawProposal = createAsyncThunk(
  "proposal/withdrawProposal",
  async (proposal, thunkAPI) => {
    try {
      return await proposalsService.withdrawProposal(proposal);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const proposalsSlice = createSlice({
  name: "proposals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getProposals
    builder.addCase(getProposals.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getProposals.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.proposals = action.payload;
    });
    builder.addCase(getProposals.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    // getSingleProposal
    builder.addCase(getSingleProposal.pending, (state) => {
      state.getSingleProposalStatus = "loading";
    });
    builder.addCase(getSingleProposal.fulfilled, (state, action) => {
      if (state.proposals.length < 1) {
        state.proposals.push(action.payload);
      } else {
        state.proposals = state.proposals.map((proposal) => {
          if (proposal.id !== +action.payload.id) return proposal;
          return action.payload;
        });
      }
      state.getSingleProposalStatus = "succeeded";
    });
    builder.addCase(getSingleProposal.rejected, (state, action) => {
      state.getSingleProposalError = action.payload;
      state.getSingleProposalStatus = "failed";
    });

    // createProposal
    builder.addCase(createProposal.pending, (state) => {
      state.createProposalStatus = "loading";
    });
    builder.addCase(createProposal.fulfilled, (state, action) => {
      state.proposals.push(action.payload);
      state.createProposalStatus = "succeeded";
    });
    builder.addCase(createProposal.rejected, (state, action) => {
      state.addProposalStatus = "failed";
      // state.error = action.payload;
    });

    // updateProposal
    builder.addCase(updateProposal.pending, (state) => {
      state.updateProposalStatus = "loading";
    });
    builder.addCase(updateProposal.fulfilled, (state, action) => {
      const proposalIdx = state.proposals.findIndex(
        (proposal) => proposal.id === action.payload.id
      );
      state.proposals[proposalIdx] = action.payload;
      state.updateProposalStatus = "succeeded";
    });
    builder.addCase(updateProposal.rejected, (state, action) => {
      state.updateProposalError = action.payload;
      state.updateProposalStatus = "failed";
    });

    // rejectProposal
    builder.addCase(rejectProposal.pending, (state) => {
      state.rejectProposalStatus = "loading";
    });
    builder.addCase(rejectProposal.fulfilled, (state, action) => {
      const proposalIdx = state.proposals.findIndex(
        (proposal) => proposal.id === action.payload.id
      );
      state.proposals[proposalIdx] = action.payload;
      state.rejectProposalStatus = "succeeded";
    });
    builder.addCase(rejectProposal.rejected, (state, action) => {
      state.rejectProposalError = action.payload;
      state.rejectProposalStatus = "failed";
    });

    // withdrawProposal
    builder.addCase(withdrawProposal.pending, (state) => {
      state.withdrawProposalStatus = "loading";
    });
    builder.addCase(withdrawProposal.fulfilled, (state, action) => {
      const proposalIdx = state.proposals.findIndex(
        (proposal) => proposal.id === action.payload.id
      );
      state.proposals[proposalIdx] = action.payload;
      state.withdrawProposalStatus = "succeeded";
    });
    builder.addCase(withdrawProposal.rejected, (state, action) => {
      state.withdrawProposalError = action.payload;
    });
  },
});

export const selectProposals = (state) => state.proposals;
export const selectPatientProposals = (state) => {
  const PatientProposals = state.proposals.proposals.filter((proposal) => {
    return (
      proposal.status !== 4 &&
      (proposal?.shift_dict?.owner_id === state.auth.user?.user ||
        proposal?.owner === state.auth.user?.user)
    );
  });

  return {
    proposals: PatientProposals,
    status: state.proposals.status,
    error: state.proposals.error,
  };
};
export const selectProposalById = (state, proposalId) => {
  const proposal = state.proposals.proposals.find(
    (proposal) => proposal.id === +proposalId
  );
  return {
    proposal,
    getSingleProposalStatus: state.proposals.getSingleProposalStatus,
    getSingleProposalError: state.proposals.getSingleProposalError,
  };
};

export default proposalsSlice.reducer;
