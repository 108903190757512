import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TextInput, Button, Checkbox } from "@mantine/core";
// import Container from "../../../components/UI/Container";
import { Center } from '@mantine/core';
import axios from "axios";
import { login } from "../../../features/auth/authSlice";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from 'react-google-button'
import { googleLogin } from "../../../features/auth/authSlice";
import { BASE_URL } from "../../../api/axiosConfig"

const baseURL = BASE_URL;

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputChangeHanlder = (event) => {
    setFormData((prevValue) => ({
      ...prevValue,
      [event.target.name]: event.target.value,
    }));
  };

  const formSubmitHanlder = async (event) => {
    event.preventDefault();
    const userCredentials = {
      ...formData,
    };
    try {
      const {
        user: { role },
      } = await dispatch(login(userCredentials)).unwrap();
      navigate(role === "Patient" ? "/patient" : "/professional", {
        replace: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      const code = codeResponse.code;

      axios
        .post(`${baseURL}/api/custom-google-convert-token/`, {
          code: code,
          //   TODO: Consider adding the rest of the codeResponse
        })
        .then(async (res) => {
          const { token, user_username, email } = res.data;

          try {
            const user = await dispatch(
              googleLogin({
                email: email,
                username: user_username,
                token: token,
              })
            );
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => {
          console.log("Couldn't convert the user token", err);
          if (err.response.status === 404) {
            navigate("/auth/signup");
          }
        });
    },
  });

  return (
    <form className="mt-16 mb-12" onSubmit={formSubmitHanlder}>
      <TextInput
        name="username"
        type="text"
        placeholder="Nombre de usuario"
        aria-label="Username"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] border-b-0 rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.username}
        onChange={inputChangeHanlder}
      />
      <TextInput
        name="password"
        type="password"
        placeholder="Tu contraseña"
        aria-label="Password"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] rounded-t-none rounded-[5px] focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.password}
        onChange={inputChangeHanlder}
      />
      <div className="my-6 flex flex-col items-center sm:flex-row sm:justify-between">
        <Checkbox label="Acuérdate de mí" />
        <Link to="/auth/forgot-password" className="text-primary-dark text-sm mt-3 sm:mt-0">
          ¿Olvidaste tu contraseña?
        </Link>
      </div>
      <Button
        type="submit"
        className="bg-primary-dark text-white w-full hover:bg-primary-dark rounded-[5px] h-10 border-none mb-4"
      >
        Iniciar sesión
      </Button>
      <div>
     <Center>
        <GoogleButton
        type="light"
        onClick={() => handleGoogleLogin()}
        />
     </Center>
     </div>

        
    </form>

  );
};

export default Login;
