import React from "react";
import axios from "axios";
import { useState } from 'react';
import { BASE_URL } from "../../api/axiosConfig";


import Container from "../UI/Container";

const Contact = () => {

    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
          baseURL: BASE_URL,  
          method: "POST",
          url:"/api/send_contact_email",
          data:  {"email": email}
        }).then((response)=>{
          if (response.status === 200) {
            alert("Tu mensaje ha sido enviado exitosamente!");
          } else if (response.status !== 200) {
            alert("No se ha podido enviar tu mensaje.")
          }
        })
      }
    
    
  return (
    <section className="text-sm py-40 bg-white">
      <Container>
        <div className="text-center mb-20">
          <p className="text-primary-dark font-bold mb-6">
            Necesitas más información
          </p>
          <h2 className="text-[40px] text-primary-text-color font-bold mb-6">
            Contáctanos
          </h2>
          <p className="text-secondary-text-color mb-6">
            Podemos resolver tus dudas
          </p>
          <p className="text-secondary-text-color">
            Déjanos tu email y te contactaremos, estamos para ayudarte
          </p>
        </div>
        <div className="max-w-[690px] mx-auto">
          <form autoComplete="off" onSubmit={(event) => handleSubmit(event)}>
            <div className="flex">
              <input
                type="email"
                placeholder="Tu email"
                value={email}
                className="peer bg-[#f9f9f9] border border-[#e6e6e6] rounded-l-[5px] h-[58px] px-4 flex-1 transition-colors focus:outline-none focus:border-primary-light"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="bg-primary-light text-white border border-[#e6e6e6] border-l-0 rounded-r-[5px] px-2 transition-colors peer-focus:border-primary-light"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
