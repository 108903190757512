import { Outlet } from "react-router-dom";

import Header from "../../Private/Patient/Header";

const PrivateWrapper = () => {
  return (
    <>
      <Header />
      <main className="py-8">
        <Outlet />
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default PrivateWrapper;
