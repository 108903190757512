import React from "react";
import { Link } from "react-router-dom";
import Container from "../../UI/Container";
import ShiftExample from "../../../pages/Landing/ShiftExample";
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';


const Tutorial = () => {
  const services = [
    {
      name: "adulto_mayor",  
      title: "Cuidados del adulto mayor",
      imgSrc: "assets/images/services/elderly-care.png",
      exampleImgSrc: "src/assets/images/adulto_mayor_ejemplo.png",
    },
  ];

  const [opened, { open, close }] = useDisclosure(false);

  const content = services.map((service, idx) => (
    <li
      key={idx}
      className="w-[238px] rounded-[20px] bg-white shadow-lg overflow-hidden shrink-0"
    >
      {/* <Link to={`/patient/shift_examples/${service.name}`}> */}
        <div className="h-[231px]">
          <img src={service.imgSrc} alt={service.title} />
        </div>
        <div className="py-[30px] px-[8px]">
          <h4 className="text-base font-bold text-[#252B42] text-center mb-[10px]">
            {service.title}
          </h4>
          <button className="text-[#1973A3] border border-[#1973A3] px-[16px] py-[10px] rounded-[5px] block mx-auto overflow-hidden relative after:absolute after:bg-[#1973A3] after:inset-0 after:translate-y-full after:transition-transform hover:after:translate-y-0 group">
            <p className="relative z-10 transition-colors group-hover:text-white">
            <Link
        onClick={open}>
            Ver ejemplo
        </Link>
        <Modal opened={opened} onClose={close} title="Tutorial" size="auto">
        {/* Modal content */}
        <ShiftExample props={service} />
        </Modal>
              
            </p>
          </button>
        </div>
      {/* </Link> */}
    </li>
  ));

  return (
    <section className="bg-white py-[20px]">
      <Container>
        <h2 className="text-[40px] text-[#252B42] font-bold mb-[48px]">
        Escoge el servicio que necesitas haciendo click en la tarjeta apropiada
        {/* ¿Qué servicio profesional necesitas? */}
        </h2>
        <h3>Por ejemplo, si necesitas cuidados para Adulto Mayor, haz click en la 
            siguiente tarjeta:</h3>
        <ul
          className={`flex flex-wrap gap-[30px] justify-center py-5 "max-h-[440px]" 
          overflow-hidden`}
        >
          {content}
        </ul>
        
      </Container>
    </section>
  );
};

export default Tutorial;
