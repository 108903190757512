import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuth } from "../../../features/auth/authSlice";

const RequireAuth = ({ allowedRole, notAuth }) => {
  const auth = useSelector(getAuth);
  const location = useLocation();

  if (!notAuth) {
    return auth?.user?.role === allowedRole ? (
      <Outlet />
    ) : auth?.isLoggedIn ? (
      <Navigate to="/" />
    ) : (
      <Navigate to="/auth/login" state={{ from: location }} replace />
    );
  } else {
    return !auth?.isLoggedIn ? (
      <Outlet />
    ) : auth?.user?.role === "Patient" ? (
      <Navigate to="/patient" replace />
    ) : (
      <Navigate to="/professional" replace />
    );
  }
};

export default RequireAuth;

// At a later point that maybe App will give the oportunity to a user to be a PATIENT and
// a PROFESSIONAL at the same time just replace the above RequireAuth arrow function with the below one.
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// const RequireAuth = ({ allowedRoles }) => {
//   const auth = useSelector(getAuth);
//   const location = useLocation();

//   return auth?.user?.roles.find((role) => allowedRoles.includes(role)) ? (
//     <Outlet />
//   ) : auth?.isLoggedIn ? (
//     <Navigate to="/unauthorized" />
//   ) : (
//     <Navigate to="/auth/login" state={{ from: location }} replace />
//   );
// };
