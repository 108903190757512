import axios from "axios";
import authHeader from "../../helpers/auth-header";
import { BASE_URL } from '../../api/axiosConfig';

//const BASE_URL = "https://alpha.api.carendar.app";
//const BASE_URL = "http://localhost:8000";


const getShifts = async () => {
  const response = await axios.get("/api/all_shifts/", { baseURL: BASE_URL });
  return response.data;
};

const getSingleShift = async (shiftId) => {
  const response = await axios.get(`/api/all_shifts/${shiftId}`, {
    baseURL: BASE_URL,
  });
  return response.data;
};

const getPatientShifts = async () => {
  const response = await axios.get("/api/my_shifts", {
    baseURL: BASE_URL,
    headers: authHeader(),
  });
  return response.data;
};

const addShift = async (newShift) => {
  const response = await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: "/api/my_shifts/",
    headers: authHeader(),
    data: {
      ...newShift,
    },
  });

  return response.data;
};

const updateShift = async (updatedShift) => {
  const response = await axios.request({
    baseURL: BASE_URL,
    method: "put",
    url: `/api/all_shifts/${updatedShift.id}/`,
    headers: authHeader(),
    data: updatedShift,
  });

  return response.data;
};

const deleteShift = async (shift) => {
    await axios.request({
      baseURL: BASE_URL,
      method: "put",
      url: `/api/all_shifts/${shift.id}/`,
      headers: authHeader(),
      data: {
        ...shift,
        status: 6, // Status deleted
      },
    });

  return shift.id;
};

const shiftsService = {
  getShifts,
  getSingleShift,
  getPatientShifts,
  addShift,
  updateShift,
  deleteShift,
};

export default shiftsService;
