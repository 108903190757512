import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from '../../../api/axiosConfig';
import { useParams } from "react-router-dom"
import { TextInput, Button } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { BiError as ErrorIcon } from "react-icons/bi";
import { IoMdDoneAll as SucceessIcon } from "react-icons/io";
import { useNavigate } from "react-router-dom";
////////////////////////////////////////////////////
//Page that send the request to set a new password//
////////////////////////////////////////////////////

const baseURL = BASE_URL;

const RestorePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const { token } = useParams()

  const submitFormHanlder = async (event) => {
    event.preventDefault();//TODO: fix if password are differents (For example: 1 and 12)
    
    try {
      await axios
        .post(`${baseURL}/api/password_reset/${token}`, {
          token: token,
          new_password: password,
          confirm_password: confirmPassword,
        })
        .then((response) => {
          console.log(response.data.success)
          showNotification({
            title: "Exito!",
            message: `Contraseña fue cambiada correctamente!`,
            color: "green",
            autoClose: 6000,
            icon: <SucceessIcon />,
          });
        })//redirect
        navigate("/auth/login")
    }catch (error){
        if (error.response.status === 400){
          showNotification({
            title: "Error!",
            message: "No se pudo cambiar la contraseña, ¡intenta nuevamente!",
            color: "red",
            autoClose: 4000,
            icon: <ErrorIcon />,
          });
        }
        else if (error.response.status === 411){
          showNotification({
            title: "Error!",
            message: "La contraseña es muy corta, intenta nuevamente!",
            color: "red",
            autoClose: 4000,
            icon: <ErrorIcon />,
          });
        }
        else if (error.response.status === 417){
          showNotification({
            title: "Error!",
            message: "¡Las contraseñas deben coincidir!",
            color: "red",
            autoClose: 4000,
            icon: <ErrorIcon />,
          });
        }
        else {
          showNotification({
            title: "Error!",
            message: "Hubo un error desconocido, ¡Intentalo denuevo!",
            color: "red",
            autoClose: 4000,
            icon: <ErrorIcon />,
          });
        }

    }
  };

  return (
    <div>
      <form className="mt-1 mb-12" onSubmit={submitFormHanlder}>
        <TextInput
          type="password"
          name="password"
          placeholder="Contraseña"
          aria-label="Password"
          required
          size="md"
          classNames={{
            input:
              "border border-[#E6E6E6] border-b-0 rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
          }}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />
        <TextInput
          type="password"
          name="confirmPassword"
          placeholder="Confirmar contraseña"
          aria-label="Confirm Password"
          required
          size="md"
          classNames={{
            input:
              "border border-[#E6E6E6] border-b-0 rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
          }}
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPassword(event.target.value)
          }}
        />
        <Button
          type="submit"
          className="bg-primary-dark text-white w-full hover:bg-primary-dark rounded-[5px] h-10 border-none mt-6"
        >
          Restablecer contraseña
        </Button>
    </form>
    
  </div>
  );
}

export default RestorePassword;
