import axios from "axios";
import authHeader from "../../helpers/auth-header";
import { BASE_URL } from '../../api/axiosConfig';

//const BASE_URL = "https://alpha.api.carendar.app";
//const BASE_URL = "http://localhost:8000";


const getProposals = async () => {
  const response = await axios.get("/api/proposals", { baseURL: BASE_URL });
  return response.data;
};

const getSingleProposal = async (proposalId) => {
  const response = await axios.get(`/api/proposals/${proposalId}`, {
    baseURL: BASE_URL,
  });

  return response.data;
};

const createProposal = async (newProposal) => {
    const response = await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: "/api/proposals/",
    headers: authHeader(),
    data: {
      ...newProposal,
    },
  });
  return response.data;
};

const updateProposal = async (proposal) => {
  const response = await axios.request({
    baseURL: BASE_URL,
    method: "put",
    url: `/api/proposals/${proposal.id}/`,
    headers: authHeader(),
    data: {
      ...proposal,
    },
  });

  return response.data;
};

const rejectProposal = async (proposal) => {
  const response = await axios.request({
    baseURL: BASE_URL,
    method: "put",
    url: `/api/proposals/${proposal.id}/`,
    headers: authHeader(),
    data: {
      ...proposal,
      status: 3, // Status 3 - archived/rejected
    },
  });

  return response.data;
};

const withdrawProposal = async (proposal) => {
  const response = await axios.request({
    baseURL: BASE_URL,
    method: "put",
    url: `/api/proposals/${proposal.id}/`,
    headers: authHeader(),
    data: {
      ...proposal,
      status: 4, // Status 4 - deleted/withdrawn
    },
  });

  return response.data;
};

const proposalsService = {
  getProposals,
  getSingleProposal,
  createProposal,
  updateProposal,
  rejectProposal,
  withdrawProposal,
};
export default proposalsService;
