import axios from "axios";
import authHeader from "../../helpers/auth-header";
import { BASE_URL } from '../../api/axiosConfig';


//const BASE_URL = "https://alpha.api.carendar.app";
//const BASE_URL = "http://localhost:8000";


const addRating = async (rating) => {
  const response = await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: "/api/ratings",
    headers: authHeader(),
    data: {
      ...rating,
    },
  });

  console.log(response.data);
  return response.data;
};

const ratingsService = {
  addRating,
};

export default ratingsService;
