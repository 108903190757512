import React from "react";
import Container from "../../components/UI/Container";
import elderlyShiftExampleImg from "../../assets/images/adulto_mayor_ejemplo.png";


const ShiftExample = () => {
  
  return (
    <section className="bg-white">
      <Container>
        <h1 className="text-xl mb-4 text-center mt-8 text-primary-dark font-bold">
         Completa los detalles de tu trabajo en el formulario que aparecerá:
        </h1>
        <div className="h-[231px]">
          <img src={elderlyShiftExampleImg} alt="adulto_mayor" />
        </div>  
        
      </Container>
        

     
    </section>
  );
};

export default ShiftExample;
