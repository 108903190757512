import { useDispatch, useSelector } from "react-redux";
import { useForm, yupResolver } from "@mantine/form";
import {
  Modal,
  TextInput,
  Textarea,
  NumberInput,
  Loader,
  Button,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import { IoMdDoneAll as SucceessIcon } from "react-icons/io";
import { BiError as ErrorIcon, BiTime as TimeIcon } from "react-icons/bi";
import {
  MdTitle as TitleIcon,
  MdDescription as DescriptionIcon,
  MdOutlineDescription as ShortDescriptionIcon,
} from "react-icons/md";
import {
  FaCity as CityIcon,
  FaTasks as ShiftsIcon,
  FaMoneyBillAlt as MoneyIcon,
} from "react-icons/fa";
import { GiModernCity as RegionIcon } from "react-icons/gi";
import {
  BsFillHouseDoorFill as AddressIcon,
  BsFillCalendarDateFill as StartDateIcon,
  BsFillCalendar2DateFill as EndDateIcon,
  BsFillPersonFill as PotitionIcon,
} from "react-icons/bs";
import { HiOfficeBuilding as InstitutionIcon } from "react-icons/hi";
import { closeAddShiftModal } from "../../../features/ui/uiSlice";
import { addShift } from "../../../features/shifts/shiftsSlice";
import { shift } from "../../../validations/ShiftValidation";
import { useEffect, useState } from "react";
import { shiftDescriptionExamples } from "../../../data/specialties"; 

const AddShift = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // defines which current step are we on
  const { addShiftStatus } = useSelector(
    (state) => state.shifts
  );
    
  //////////
  const [value, onChange] = useState(new Date());
  //////////
  useEffect(() => {
    setModalIsOpen(true);
  }, []);

  // To control form flow
  const prevStep = () => setCurrentStep(currentStep - 1);
  const nextStep = () => setCurrentStep(currentStep + 1);

  const totalStep = 4; // set here the number of steps

  // const {
  //   addShiftModal: { shiftId },
  // } = useSelector((state) => state.ui);

  // const { shifts } = useSelector(selectShifts);

  // Here we are checking if there is an existing shiftId into the UI Slice
  // of the Redux store which will indicate if we are going to create a new shift
  // or edit an existing one based on the shiftId.
  // const shouldEdit = false; // it is set to false in case of creating new shift

  let initialValues = shift.initialValues;
  // if (shouldEdit) {
  //   const selectedShift = shifts.find((shift) => shift.id === shiftId);
  //   initialValues = {
  //     ...selectedShift,
  //     start_date: new Date(selectedShift.start_date),
  //     end_date: new Date(selectedShift.end_date),
  //     shift_hourly_rate: +selectedShift.shift_hourly_rate,
  //   };
  // }

  const form = useForm({
    validate: yupResolver(shift.schema),
    initialValues,
  });

  const dispatch = useDispatch();

  const closeModalHandler = () => {
    setModalIsOpen(false);
    setTimeout(() => {
      dispatch(closeAddShiftModal());
      form.reset();
    }, 350);
  };

  const submitFormHandler = async (shift) => {
    const transformedShift = { ...shift };
    // Transform start_date and end_date according to the API's requirements
    transformedShift.start_date = dayjs(shift.start_date).format("YYYY-MM-DD");
    transformedShift.end_date = dayjs(shift.end_date).format("YYYY-MM-DD");

    try {
      const result = await dispatch(addShift(transformedShift)).unwrap();
      if (result.id) {
        closeModalHandler();
        showNotification({
          title: "Exito!",
          message: `Su turno fue creado
          exitosamente. En este momento Carendar 
          está buscando a los profesionales que hagan 
          el mejor match con su solicitud, pronto se le 
          contactará con propuestas`,
          color: "green",
          autoClose: 5000,
          icon: <SucceessIcon />,
        });
      }
    } catch (error) {
      showNotification({
        title: "Error!",
        message: "Something went wrong. Try again:(",
        color: "red",
        autoClose: 3000,
        icon: <ErrorIcon />,
      });
    }
  };

  return (
    <Modal
      opened={modalIsOpen}
      onClose={closeModalHandler}
      title="Añadir nuevo turno"
      transition="rotate-left"
      transitionDuration={350}
      centered
      closeButtonLabel="Close Add-Shift Modal"
      classNames={{
        modal: "!w-[900px]",
      }}
    >
      <div class="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
        <div class="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500 transition-all duration-2000 
                ease-out" style={{width: `${(currentStep/totalStep)*100}%`}}></div>
      </div>
      <form className="mt-8" onSubmit={form.onSubmit(submitFormHandler)}>
        {(()=>{
          switch(currentStep){
            case 1: return  <div className="flex flex-col" style={{height: "70vh"}}>
                <h3 className="pb-8">What are you looking for?</h3>
                <TextInput
                  name="position"
                  type="text"
                  placeholder="Tipo de profesional"
                  label="Profesional que busca"
                  aria-label="Position"
                  size="md"
                  className="mb-4"
                  icon={<PotitionIcon />}
                  {...form.getInputProps("position")}
                  // value={props.subService}
                />
                <div className="flex justify-around mt-auto">
                  <Button
                    onClick={nextStep}
                    variant="default"
                    className="!bg-primary-dark !text-white !w-full sm:!w-1/3 hover:!bg-primary-dark !rounded-[5px] !h-10 !border-none !mt-6"
                  >Next
                  </Button>
                </div>
              </div>
            case 2: return <div className="flex flex-col" style={{height: "70vh"}}>
              <h3 className="pb-8">Tell us more about the job.</h3>
              <TextInput
                name="title"
                type="text"
                placeholder="Usa un título atractivo y memorable"
                label="Título"
                aria-label="Title"
                size="md"
                className="mb-4"
                icon={<TitleIcon />}
                {...form.getInputProps("title")}
              />
              <TextInput
                name="description_short"
                type="text"
                placeholder="Breve descripción del turno"
                label="Breve descripción"
                aria-label="Short description"
                size="md"
                className="mb-4"
                icon={<ShortDescriptionIcon />}
                {...form.getInputProps("description_short")}
              />
              <Textarea
                name="description"
              //   placeholder="Una descripción completa y clara"
              placeholder={shiftDescriptionExamples[props.service]}
                label="Descripción"
                aria-label="Description"
                size="md"
                className="mb-4"
                autosize
                minRows={4}
                maxRows={8}
                icon={<DescriptionIcon />}
                {...form.getInputProps("description")}
              />
              <TextInput
                name="institution"
                type="text"
                placeholder="Nombre de la institución (o dejar escribir 'N/A' si no aplica)"
                label="Institución"
                aria-label="Institution"
                size="md"
                icon={<InstitutionIcon />}
                {...form.getInputProps("institution")}
                // value="N/A"  // fixed value for institution
              />
              <div className="flex justify-around mt-auto">
                  <Button
                    onClick={prevStep}
                    variant="default"
                    className="!bg-primary-dark !text-white !w-full sm:!w-1/3 hover:!bg-primary-dark !rounded-[5px] !h-10 !border-none !mt-6"
                  >Previous
                  </Button>
                  <Button
                    onClick={nextStep}
                    variant="default"
                    className="!bg-primary-dark !text-white !w-full sm:!w-1/3 hover:!bg-primary-dark !rounded-[5px] !h-10 !border-none !mt-6"
                  >Next
                  </Button>
                </div>
              </div>
            case 3: return <div className="flex flex-col" style={{height: "70vh"}}>
              <h3 className="pb-8">Let's confirm where you need help.</h3>
              <TextInput
                name="location_city"
                type="text"
                placeholder="¿En qué ciudad se llevará a cabo el turno?"
                label="Ubicación"
                aria-label="Location city"
                size="md"
                className="mb-4"
                icon={<CityIcon />}
                {...form.getInputProps("location_city")}
              />
              <TextInput
                name="location_region"
                type="text"
                placeholder=" ¿En qué región se efectuará el turno?"
                label="Región del turno"
                aria-label="Location region"
                size="md"
                className="mb-4"
                icon={<RegionIcon />}
                {...form.getInputProps("location_region")}
              />
              <TextInput
                name="location_address"
                type="text"
                placeholder="Escribir dirección completa de donde se realizará el turno"
                label="Dirección del turno"
                aria-label="Location address"
                size="md"
                className="mb-4"
                icon={<AddressIcon />}
                {...form.getInputProps("location_address")}
              />
               <div className="flex justify-around mt-auto">
                  <Button
                    onClick={prevStep}
                    variant="default"
                    className="!bg-primary-dark !text-white !w-full sm:!w-1/3 hover:!bg-primary-dark !rounded-[5px] !h-10 !border-none !mt-6"
                  >Previous
                  </Button>
                  <Button
                    onClick={nextStep}
                    variant="default"
                    className="!bg-primary-dark !text-white !w-full sm:!w-1/3 hover:!bg-primary-dark !rounded-[5px] !h-10 !border-none !mt-6"
                  >Next
                  </Button>
                </div>
              </div>
          case 4: return <div className="flex flex-col" style={{height: "70vh"}}>
        <h3 className="pb-8">When do you need the service?</h3>
        <div className="sm:flex">
          <DatePicker
            dropdownType="modal"
            name="start_date"
            placeholder="Fecha de inicio"
            label="Fecha de inicio"
            aria-label="Start date"
            inputFormat="YYYY-MM-DD"
            size="md"
            className="mb-4 w-full"
            value={value} 
            onChange={onChange}
            minDate={new Date()}
            icon={<StartDateIcon />}
            {...form.getInputProps("start_date")}
          />
          <DatePicker
            dropdownType="modal"
            name="end_date"
            placeholder="Fecha de término del turno"
            label="Fecha de término"
            aria-label="End date"
            inputFormat="YYYY-MM-DD"
            size="md"
            className="mb-4 w-full sm:ml-8"
            //value={value1}
            //onChange={onChange1}
            //minDate={dayjs(new Date()).startOf('month').add(value.getDate(), 'days').toDate()}
            minDate={new Date()}
            icon={<EndDateIcon />}
            {...form.getInputProps("end_date")}
          />
        </div>
        <div className="sm:flex">
          <NumberInput
            name="shifts_per_week"
            placeholder="Número de turnos por semana"
            label="Turnos por semana"
            aria-label="Shifts per week"
            size="md"
            min={1}
            hideControls
            className="mb-4 w-full"
            icon={<ShiftsIcon />}
            {...form.getInputProps("shifts_per_week")}
          />
          <NumberInput
            name="shift_length_in_hours"
            placeholder="¿Cuánto durará cada turno?"
            label="Duración del turno en horas"
            aria-label="Shift length in hours"
            size="md"
            min={1}
            hideControls
            className="mb-4 w-full sm:ml-8"
            icon={<TimeIcon />}
            {...form.getInputProps("shift_length_in_hours")}
          />
          <NumberInput
            name="shift_hourly_rate"
            placeholder="Tarifa horaria por turno"
            label="Valor del turno"
            aria-label="Shift hourly rate"
            size="md"
            hideControls
            className="mb-4 w-full sm:ml-8"
            icon={<MoneyIcon />}
            {...form.getInputProps("shift_hourly_rate")}
          />
        </div>
        <div className="flex justify-around mt-auto">
                  <Button
                    onClick={prevStep}
                    variant="default"
                    className="!bg-primary-dark !text-white !w-full sm:!w-1/3 hover:!bg-primary-dark !rounded-[5px] !h-10 !border-none !mt-6"
                  >Previous
                  </Button>
                  <Button
                    type="submit"
                    variant="default"
                    className="!bg-primary-dark !text-white !w-full sm:!w-1/3 hover:!bg-primary-dark !rounded-[5px] !h-10 !border-none !mt-6"
                  >
                    {addShiftStatus === "loading" ? (
                      <Loader />
                    ) : (
                      "Crear turno"
                    )}
                  </Button>
                </div>
        </div>
        }
              })()
          }
      </form>
    </Modal>
  );
};

export default AddShift;
