import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "../UI/Container";

const Services = () => {
  const [servicesAreExpanded, setServicesAreExpanded] = useState(false);
  const services = [
    {
      name: "consulta_medica",  
      title: "Consulta médica domiciliaria",
      imgSrc: "assets/images/services/medical-consultation.png",
    },
    {
      name: "madre_y_bebe",  
      title: "Cuidados de la madre y el bebé",
      imgSrc: "assets/images/services/mothers-care.png",
    },
    {
      name: "nutricion",  
      title: "Consulta de nutrición y dietética",
      imgSrc: "assets/images/services/nutrition-dietics.png",
    },
    {
      name: "paciente_critico",
      title: "Cuidados del paciente crítico",
      imgSrc: "assets/images/services/critical-patient-care.png",
    },
    {
      name: "terapia_fisica",  
      title: "Terapias físicas y músculo esqueléticas",
      imgSrc: "assets/images/services/physical-therapies.png",
    },
    {
      name: "adulto_mayor",  
      title: "Cuidados del adulto mayor",
      imgSrc: "assets/images/services/elderly-care.png",
    },
    {
      name: "salud_mental",  
      title: "Salud mental y consejería",
      imgSrc: "assets/images/services/mental-health.png",
    },
    {
      name: "inyecciones",  
      title: "Tomas de test, vacunación e inyecciones",
      imgSrc: "assets/images/services/injections.png",
    },
    {
      name: "medicina_alternativa",  
      title: "Medicina y terapias alternativas",
      imgSrc: "assets/images/services/therapies-alternatives.png",
    },
    {
      name: "mascotas",  
      title: "Salud y cuidado de mascotas",
      imgSrc: "assets/images/services/pets-care.png",
    },
    {
      name: "necesidades_especiales",  
      title: "Terapias para niños/jóvenes con necesidades especiales",
      imgSrc: "assets/images/services/youth-with-special-needs.png",
    },
    {
      name: "estetica",
      title: "Tratamientos faciales, corporales y estéticos",
      imgSrc: "assets/images/services/aesthetic.png",
    },
    {
      name: "teleconsulta",  
      title: "Teleconsulta",
      imgSrc: "assets/images/services/teleconsultation.png",
    },
  ];

  const content = services.map((service, idx) => (
    <li
      key={idx}
      className="w-[238px] rounded-[20px] bg-white shadow-lg overflow-hidden shrink-0"
    >
      <Link to={`/professionals/${service.name}`}>
        <div className="h-[231px]">
          <img src={service.imgSrc} alt={service.title} />
        </div>
        <div className="py-[30px] px-[8px]">
          <h4 className="text-base font-bold text-[#252B42] text-center mb-[10px]">
            {service.title}
          </h4>
          <button className="text-[#1973A3] border border-[#1973A3] px-[16px] py-[10px] rounded-[5px] block mx-auto overflow-hidden relative after:absolute after:bg-[#1973A3] after:inset-0 after:translate-y-full after:transition-transform hover:after:translate-y-0 group">
            <p className="relative z-10 transition-colors group-hover:text-white">
              Agendar servicio
            </p>
          </button>
        </div>
      </Link>
    </li>
  ));

  return (
    <section className="bg-white py-[112px]">
      <Container>
        <p className="text-sm text-[#23A6F0] font-bold mb-[10px]">
          Nuestros profesionales en un solo lugar
        </p>
        <h2 className="text-[40px] text-[#252B42] font-bold mb-[48px]">
        ¿Qué servicio profesional necesitas?
        </h2>
        <ul
          className={`flex flex-wrap gap-[30px] justify-center py-5 ${
            servicesAreExpanded ? "!max-h-[2080px]" : "max-h-[440px]"
          } overflow-hidden`}
        >
          {content}
        </ul>
        {!servicesAreExpanded && (
          <button
            className="bg-[#1973A3] px-[32px] py-[10px] text-sm font-bold text-white rounded-[5px] block mx-auto mt-[35px] transition-transform hover:scale-105"
            onClick={() => {
              setServicesAreExpanded(true);
            }}
          >
            Ver todos los servicios
          </button>
        )}
      </Container>
    </section>
  );
};

export default Services;
