import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Greeting from "../../../components/Private/Patient/Greeting";
import ShiftList from "../../../components/Private/Patient/ShiftList";
import AddShift from "../../../components/Private/Patient/AddShift";
import EditShift from "../../../components/Private/Patient/EditShift";
import ShiftDetails from "../../../components/Private/Patient/ShiftDetails";

import ShiftCategories from "../../../components/Private/Patient/ShiftCategories";

const PatientHome = () => {
  const {
    addShiftModalIsOpen ,
    editShiftModal: { isOpen },
  } = useSelector((state) => state.ui);

  return (
    <>
      <Greeting />
      <ShiftList />
      {addShiftModalIsOpen && <AddShift />}
      {isOpen && <EditShift />}
      <Routes>
        <Route path="shifts/:shiftId" element={<ShiftDetails />} />
      </Routes>
      <ShiftCategories/>
    </>
  );
};

export default PatientHome;
