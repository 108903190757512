import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "@mantine/core";
import { fetchAuthUser, getAuth } from "./features/auth/authSlice";

import PrivateWrapper from "./components/UI/Private/PrivateWrapper";
import AppWrapper from "./components/UI/Landing/AppWrapper";
import Home from "./pages/Landing/Home";
import Professionals from "./pages/Landing/Professionals";
import Institutions from "./pages/Landing/Institutions";
import AboutUs from "./pages/Landing/AboutUs";
import Auth from "./pages/Auth/Auth";
import PatientHome from "./pages/Private/Patient/PatientHome";
import PatientMyJobs from "./pages/Private/Patient/PatientMyJobs";
import ProfessionalHome from "./pages/Private/Professional/ProfessionalHome";
import RequireAuth from "./components/Private/RequireAuth/RequireAuth";
import ProfessionalMyJobs from "./pages/Private/Professional/ProfessionalMyJobs";
import ProposalApply from "./pages/Private/Professional/ProposalApply";
import EndContract from "./pages/Private/Patient/EndContract";
import EditProposal from "./pages/Private/Professional/EditProposal";
import ProfessionalProfile from "./pages/Private/Professional/ProfessionalProfile";
import PatientProfile from "./pages/Private/Patient/PatientProfile";
import PrivacyPolicy from "./pages/Landing/PrivacyPolicy";
import TermsOfService from "./pages/Landing/TermsOfService";
import SearchProfessionals from "./pages/Landing/SearchProfessionals";
import ExternalProfile from "./pages/Private/Professional/ExternalProfile";

function App() {
  let { isLoggedIn, user, status } = useSelector(getAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    // If the user is logged in(which means that a token found in browser's Local Storage)
    // we want to fetch all the info for this user before we show him the protected pages
    if (isLoggedIn && !user && status === "idle") {
      dispatch(fetchAuthUser());
    }
  }, [isLoggedIn, user, status, dispatch]);

  if (isLoggedIn && !user) {
    return (
      <div className="w-full h-screen bg-gray flex justify-center items-center">
        <Loader color="indigo" />
      </div>
    );
  }

  return (
    <Routes>
      {/* public routes */}
      <Route element={<RequireAuth notAuth={true} />}>
        <Route path="/" element={<AppWrapper />}>
          <Route index element={<Home />} />
          <Route path="profesionales" element={<Professionals />} />
          <Route path="instituciones" element={<Institutions />} />
          <Route path="nosotros" element={<AboutUs />} />
          <Route path="privacy_policy" element={<PrivacyPolicy />} />
          <Route path="terms_of_service" element={<TermsOfService />} />
          <Route
            path="professionals/:serviceName"
            element={<SearchProfessionals />}
          />
          <Route path="professional/:userEmail" element={<ExternalProfile />} />
        </Route>
        <Route path="auth/*" element={<Auth />} />
      </Route>

      {/* protected routes */}
      <Route element={<RequireAuth allowedRole="Professional" />}>
        <Route element={<PrivateWrapper />}>
          <Route path="professional/*" element={<ProfessionalHome />} />
          <Route
            path="professional/profile"
            element={<ProfessionalProfile />}
          />
          <Route
            path="professional/myjobs/*"
            element={<ProfessionalMyJobs />}
          />
          <Route
            path="professional/proposals/:proposalId"
            element={<EditProposal />}
          />
          <Route
            path="professional/proposals/:shiftId/apply"
            element={<ProposalApply />}
          />
        </Route>
      </Route>

      <Route element={<RequireAuth allowedRole="Patient" />}>
        <Route element={<PrivateWrapper />}>
          <Route path="patient/*" element={<PatientHome />} />
          <Route path="patient/profile" element={<PatientProfile />} />
          <Route path="patient/myjobs/*" element={<PatientMyJobs />} />
          <Route
            path="patient/feedbacks/contract/:contractId"
            element={<EndContract />}
          />
        </Route>
        <Route
            path="patient/view_professional/:professionalUsername"
            element={<ExternalProfile />}
        >
        </Route>
    </Route>
      {/* catch all route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
