import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Button } from "@mantine/core";
import Container from "../../components/UI/Container";
import { useParams } from "react-router-dom";
import { specialties, subSpecialties } from "../../data/specialties";

const SearchProfessionals = () => {
  
    const { serviceName } = useParams(); 
    
    const serviceDictionary = {
        "consulta_medica": [
            "Medicina General",
            "Medicina Interna",
            "Geriatria",
            "Pediatría",
            "Neurología",
            "Fisiatría",
            "Medicina del Dolor y Cuidados Paliativos",
            "Endocannabinogía",
            "Nutriología" ,
            "Psiquiatría",
            "Enfermedades Respiratorias",
            "Otros"
        ],

        "madre_y_bebe": [
            "Matrona",
            "Enfermero/a",
            "Cuidador/Chaperon/a/Shadow",
            "Tecnico en Enfermería (TENS)",
            "Nutricionista",
            "Nutriología ",
            "Enfermedades Respiratorias",
            "Kinesiologo/a",
            "Entrenador Físico",
            "Mesoterapeuta/Masajista",
            "Cosmetologo/a",
            "Terapeuta Floral y Plantas Medicinales",
            "Terapeuta Natural y Terapias Alternativas",
            "Baby Sitter",
            "Otros",
        ],     
        "nutricion": [
            "Nutricionista",
            "Psicologo/a",
            "Entrenador Físico",
            "Nutriología ",
            "Otros",
        ],

        "paciente_critico": [
            "Medicina General",
            "Medicina Interna",
            "Medicina del Dolor y Cuidados Paliativos",
            "Endocannabinogía",
            "Enfermero/a",
            "Tecnico en Enfermería (TENS)",
            "Paramédico",
            "Cuidador/Chaperon/a/Shadow",
            "Kinesiólogo/a",
            "Terapeuta Ocupacional",
            "Fonoaudiologo/a",
            "Psicologo/a",
            "Psiquiatra",
            "Otros",
        ],   

        "terapia_fisica": [
            "Fisiatra",
            "Kinesiologo/a",
            "Entrenador Físico",
            "Quiropáctico ",
            "Mesoterapeuta/Masajista",
            "Otros",
        ],   

        "adulto_mayor": [
            "Medicina General",
            "Medicina Interna",
            "Geriatria",
            "Neurología",
            "Fisiatría",
            "Neurología",
            "Psiquiatría",
            "Enfermero/a",
            "Tecnico en Enfermería (TENS)",
            "Cuidador/Chaperon/a/Shadow",
            "Kinesiólogo/a",
            "Terapeuta Ocupacional",
            "Fonoaudiologo/a",
            "Nutricionista",
            "Podólogo/a",
            "Otros",
        ],   
        
        "salud_mental": [
            "Neurología",
            "Psiquiatría",
            "Psicologo/a",
            "Consejero/a orientador/a familiar",
            "Otros",
        ],   

        "inyecciones": [
            "Enfermero/a",
            "Tecnico en Enfermería (TENS)",
            "Cuidador/Chaperon/a/Shadow",
            "Paramédico",
            "Otros",
        ],   
        
        "medicina_alternativa": [
            "Terapia Floral y Plantas Medicinales",
            "Terapeuta Natural y Terapias Alternativas",
            "Podólogo/a",
            "Quiropráctico ",
            "Mesoterapeuta/Masajista",
            "Otros",
        ],   

        "mascotas": [
            "Médico Veterinario ",
            "Cuidador de Mascotas",
            "Otros",
        ],     
        "necesidades_especiales": [
            "Neurología",
            "Psiquiatría",
            "Psicóloga/o",
            "Kinesiologo/a",
            "Terapeuta Ocupacional",
            "Fonoaudiologo/a",
            "Terapeuta Natural y Terapias Alternativas",
            "Terapia Floral y Plantas Medicinales",
            "Cuidador/Chaperon/a/Shadow",
            "Psicopedagogo/a",
            "Otros",
        ],   
        "estetica": [
            "Kinesiologo/a",
            "Nutricionista",
            "Nutriología ",
            "Quiropráctico ",
            "Entrenador Físico",
            "Mesoterapeuta/Masajista",
            "Cosmetologo/a",
            "Terapeuta Floral y Plantas Medicinales",
            "Terapeuta Natural y Terapias Alternativas",
            "Tecnico en Enfermería (TENS)",
            "Enfermero/a",
            "Otros",
        ],   
        "teleconsulta": [
            "Fonoaudiologo/a",
            "Kinesiologo/a",
            "Medico",
            "Psicologo/a",
            "Nutricionista",
            "Matrona",
            "Terapeuta Natural y Terapias Alternativas",
            "Terapeuta Ocupacional",
            "Entrenador Físico ",
            "Terapeuta Floral y Plantas Medicinales",
            "Veterinario/a",
            "Psicopedagogo/a",
            "Consejero/a orientador/a familiar",
            "Otros",
        ],   
    }

    const subspecialtyList = serviceDictionary[serviceName]
    console.log(serviceName, subspecialtyList)

  return (
    <section className="bg-light-gray pb-3">
      <Container>
        <h1 className="text-xl mb-4 text-center mt-8 text-primary-dark font-bold">
          <span className="text-primary-light text-base">Especialidades</span>{" "}
          
        </h1>
        <ul className="flex flex-col gap-4">
          
          {subspecialtyList.map((service) => 
          <li key={service.toString()}>
            <Link to="/patient">
              <div className="bg-white p-4 flex gap-8 rounded-lg transition-transform hover:scale-[1.03]">
                
                <div>
                  <h3 className="text-xl">{service}</h3>
                </div>
              </div>
            </Link>
          </li>
          )}
        </ul>
 
        <Link to="/">
            <Button
                variant="default"
                className="!bg-primary-dark !text-white !hover:bg-primary-dark !rounded-[5px] !h-10 !border-none"
            >
                Volver
            </Button>
        </Link>
      </Container>

     
    </section>
  );
};

export default SearchProfessionals;
