import { Outlet } from "react-router-dom";

import ScrollToTop from "../ScrollToTop";
import Header from "../../Landing/Header";
import Contact from "../../Landing/Contact";
import Footer from "../../Footer/Footer";

const AppWrapper = (props) => {
  return (
    <ScrollToTop>
      <Header />
      <main>
        <Outlet />
      </main>
      <Contact />
      <Footer />
    </ScrollToTop>
  );
};

export default AppWrapper;
