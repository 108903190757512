import axios from "axios";
import { BASE_URL } from '../../api/axiosConfig';

// const BASE_URL = "http://127.0.0.1:8000";
// const BASE_URL = "http://159.223.206.51";
//const BASE_URL = "https://alpha.api.carendar.app";

const register = async (userData) => {
  // For the register to be successfull are needed 3 requests on 3 different endpoints
  // 1. POST => /api/users/ -> create user in the database
  const usersEndpointResponse = await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: "/api/users/",
    data: {
      username: userData.username,
      password: userData.password,
    },
  });

  // 2. POST => /api-auth-token/ -> sign user in
  const authEndpointResponse = await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: "/api-auth-token/",
    data: {
      username: userData.username,
      password: userData.password,
    },
  });

  const { token } = authEndpointResponse.data;
  if (token) {
    localStorage.setItem("username", userData.username);
  }

  // 3. POST => /api/profiles/ -> create user's profile
  const profilesEndpointResponse = await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: "/api/profiles/",
    headers: { Authorization: `Token ${token}` },
    data: {
      user: usersEndpointResponse.data.id,
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      rut: userData.rut,
      phone_number: userData.telephone,
      role: userData.role,
      specialty: userData?.specialty,
      sub_specialty: userData?.subSpecialty,
      description: userData?.description,
    },
  });

  let responsesConcat = {};
  if (
    usersEndpointResponse.data &&
    profilesEndpointResponse.data &&
    authEndpointResponse.data
  ) {
    responsesConcat = {
      user: { ...usersEndpointResponse.data, ...profilesEndpointResponse.data },
      token,
    };

    localStorage.setItem("authToken", token);
  }

  return responsesConcat;
};

const login = async (userCredentials) => {
  // POST => /api-auth-token/ -> sign user in
  const authEndpointResponse = await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: "/api-auth-token/",
    data: {
      username: userCredentials.username,
      password: userCredentials.password,
    },
  });

  const { token } = authEndpointResponse.data;

  if (token) {
    localStorage.setItem("username", userCredentials.username);
  }

  // GET => /api/users/:username -> retrieve logged in user's info
  const usersEndpointResponse = await axios.get(
    `/api/users/${userCredentials.username}/`,
    {
      baseURL: BASE_URL,
    }
  );

  // GET => /api/profiles/:username -> retrieve logged in user's profile data
  const profilesEndpointResponse = await axios.get(
    usersEndpointResponse.data.links.profile,
    { baseURL: BASE_URL }
  );

  let responsesConcat = {};
  if (
    usersEndpointResponse.data &&
    profilesEndpointResponse.data &&
    authEndpointResponse.data
  ) {
    responsesConcat = {
      user: { ...usersEndpointResponse.data, ...profilesEndpointResponse.data },
      token,
    };

    localStorage.setItem("authToken", token);
  }

  return responsesConcat;
};

const googleLogin = async (userCredentials) => {
  const { email, username, token } = userCredentials;
  
  if (token) {
    localStorage.setItem("username", username);
    localStorage.setItem("authToken", token);
  }

  // GET => /api/users/:username -> retrieve logged in user's info
  const usersEndpointResponse = await axios.get(`/api/users/${username}`, {
    baseURL: BASE_URL,
  });
  
  // GET => /api/profiles/:username -> retrieve logged in user's profile data
  const profilesEndpointResponse = await axios.get(
    usersEndpointResponse.data.links.profile,
    { baseURL: BASE_URL }
  );
    
  
  let responsesConcat = {};
  if (usersEndpointResponse.data && profilesEndpointResponse.data) {
    responsesConcat = {
      user: { ...usersEndpointResponse.data, ...profilesEndpointResponse.data },
      token,
    };

    localStorage.setItem("authToken", token);
  }

  return responsesConcat;
};

const logout = async () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("username");
};

const authService = {
  register,
  login,
  googleLogin,
  logout,
};

export default authService;
