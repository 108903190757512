import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Indicator, Menu } from "@mantine/core";
import { BiLogOut as LogOutIcon } from "react-icons/bi";

import Container from "../../UI/Container";
import { logout, getAuth } from "../../../features/auth/authSlice";

const Header = () => {
  const { user } = useSelector(getAuth);

  const dispatch = useDispatch();

  return (
    <header className="py-5 bg-white">
      <Container>
        <div className="flex justify-between items-center text-sm font-bold">
          {/* Logo Container */}
          <div>
            <Link to={user?.role === "Patient" ? "/patient" : "/professional"}>
              <img
                src="/assets/images/logo.png"
                alt="Website Logo"
                className="w-[180px] max-w-full"
              />
            </Link>
          </div>

          {/* Navigation Container */}
          <nav>
            <ul className="flex text-secondary-text-color">
              <li>
                <Link
                  to={
                    user?.role === "Patient"
                      ? "/patient/myjobs"
                      : "/professional/myjobs"
                  }
                >
                  Mis trabajos
                </Link>
              </li>
            </ul>
          </nav>

          {/* Actions Container */}

          <Menu position="bottom" placement="end" withArrow transition="scale">
            <Menu.Target>
              <Indicator
                position="top-end"
                className="cursor-pointer"
                withBorder
                offset={5}
                size={12}
              >
                <Avatar radius="xl" src={user.image} />
              </Indicator>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item>
                <Link
                  to={
                    user?.role === "Patient"
                      ? "/patient/myjobs"
                      : "/professional/myjobs"
                  }
                >
                  Mis trabajos
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to={
                    user?.role === "Patient"
                      ? "/patient/profile"
                      : "/professional/profile"
                  }
                >
                  Perfil
                </Link>
              </Menu.Item>
              <Menu.Item
                icon={<LogOutIcon />}
                onClick={() => {
                  dispatch(logout());
                }}
              >
                Log out
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </Container>
    </header>
  );
};

export default Header;
