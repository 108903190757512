import axios from "axios";
import authHeader from "../../helpers/auth-header";
import { BASE_URL } from '../../api/axiosConfig';

//const BASE_URL = "https://alpha.api.carendar.app";
//const BASE_URL = "http://localhost:8000";

const getVerificationDocument = async () => {
  const response = await axios.get(`/api/documents/`, {
    baseURL: BASE_URL,
    headers: authHeader(),
  });
  return response.data;
};

const updateVerificationDocument = async (newDocuments) => {
  const response = await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: "/api/documents/",
    headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data',
    },
    data: newDocuments,
  });

  return response.data;
};

const verificationService = {
  getVerificationDocument,
  updateVerificationDocument
};

export default verificationService;
