import React, { useState } from "react";    
import { Link } from "react-router-dom";
import { Button } from "@mantine/core";
import Container from "../../UI/Container";
import { serviceDictionary } from "../../../data/specialties";
import AddShift from "./AddShift";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { openAddShiftModal } from "../../../features/ui/uiSlice";


const ShiftSubCategories = (props) => {
    const [subService, setSubService] = useState('');
    const dispatch = useDispatch();

    const {
        addShiftModalIsOpen,
      } = useSelector((state) => state.ui);

    const handleClick = (subServiceName) => () => {
        setSubService(subServiceName)
        dispatch(openAddShiftModal());
    }
    
    

    const subspecialtyList = serviceDictionary[props.serviceName]
    
  return (
    <section className="bg-light-gray pb-3">
      <Container>
        <h1 className="text-xl mb-4 text-center mt-8 text-primary-dark font-bold">
          <span className="text-primary-light text-base">Especialidades</span>{" "}
          
        </h1>
        <ul className="flex flex-col gap-4">
          
          {subspecialtyList.map((service) => 
          <li key={service.toString()}>
            <Link onClick={handleClick(service)}>
              
              <div className="bg-white p-4 flex gap-8 rounded-lg transition-transform hover:scale-[1.03]">
                
                <div>
                  <h3 className="text-xl">{service}</h3>
                </div>
              </div>
            </Link>
          </li>
          )}
          
        {addShiftModalIsOpen && <AddShift service={props.serviceName} subService={subService}/>}
        
        </ul>
 
        <Link to="/">
            <Button
                variant="default"
                className="!bg-primary-dark !text-white !hover:bg-primary-dark !rounded-[5px] !h-10 !border-none"
            >
                Volver
            </Button>
        </Link>
      </Container>

     
    </section>
  );
};

export default ShiftSubCategories;
