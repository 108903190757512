import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider } from "@mantine/core";

import ShiftItem from "./ShiftItem";
import Container from "../../UI/Container";
import {
  getShifts,
  selectAvailableShifts,
} from "../../../features/shifts/shiftsSlice";
import axios from "../../../api/axiosConfig";
import { BASE_URL } from '../../../api/axiosConfig';
import { TypographyStylesProvider } from '@mantine/core';
import VerificationDocuments from "./VerificationDocuments";

const ShiftList = () => {
  const dispatch = useDispatch();
  const { shifts, status, error } = useSelector(selectAvailableShifts);
  const [profile, getProfile] = useState("");
  const { username } = useSelector((state) => state.auth.user);

  // TODO: Get the professional profile and check if he is admin approved
  // = "https://alpha.api.carendar.app";
  //const BASE_URL = "http://localhost:8000/";
  //const BASE_URL = process.env.REACT_APP_BASE_URL;
  const getProfileByUsername = (username) => {
    if (username !== undefined) {
      axios
        .get("/api/profiles/" + username, { baseURL: BASE_URL })
        .then(function (response) {
          const profile = response.data;
          getProfile(profile);
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // nothing?
        });
    } else {
      console.log("username is undefined");
      const profile = {}; // TODO: This is a hack. Fix.
      getProfile(profile);
    }
  };

  useEffect(() => {
    dispatch(getShifts());
    getProfileByUsername(username);
  }, [dispatch, username]);

  // console.log(profile.admin_approved)

  let content;
  if (status === "loading") {
    content = <p>Loading...</p>;
  } else if (status === "succeeded") {
    if (profile.admin_approved) {
      content = (
        <ul>
          {shifts.length > 0 &&
            shifts.map((shift) => <ShiftItem key={shift.id} shift={shift} />)}
        </ul>
      );
    } else {
      content = (
        <VerificationDocuments/>
        // <>
        //  <Container>
        //         <TypographyStylesProvider>
        //             <p className="text-secondary-dark mb-1 font-bold">
        //                 Por favor mande sus credenciales profesionales a
        //                 <b> registro@carendar.app</b>.
        //             </p>
        //             <p className="text-secondary-dark mb-1 font-bold">
        //                 {" "}
        //                 Incluya la siguiente información:
        //             </p>
        //             <p className="text-secondary-dark mb-1">
        //                 {" "}
        //                 1. Currículum del profesional asignado o Breve descripción de trayectoria profesional.
        //             </p>
        //             <p className="text-secondary-dark mb-1">2. Certificado de Título (si corresponde)</p>
        //             <p className="text-secondary-dark mb-1">3. Nro Inscripción SIS​ (si corresponde)</p>
        //             <p className="text-secondary-dark mb-1">
        //                 4. Certificado de antecedentes actual - https://www.registrocivil.cl/ 
        //             </p>
        //             <p className="text-secondary-dark mb-1">
        //                 5. Copia cédula de identidad por ambos lados
        //             </p>
        //             <p>
        //                 6. Foto de perfil.
        //             </p>
        //             <p className="text-primary-dark mb-1 font-bold">
        //                 Una vez que sus credenciales hayan sido verificadas, tendra acceso a
        //                 la aplicacion completa.{" "}
        //             </p>
        //   </TypographyStylesProvider>
        // </Container>
        // </>
      );
    }
  } else if (status === "failed") {
    content = <p>{error}</p>;
  }

  return (
    <>
      <section>
        <Container>
          <div className="bg-white rounded-[5px]">
            <h3 className="text-lg text-primary-dark font-bold px-4 py-5">
              Trabajos que te pueden gustar
            </h3>
            <Divider className="border-primary-dark" />
            {content}
          </div>
        </Container>
      </section>
    </>
  );
};

export default ShiftList;
