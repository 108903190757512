import Hero from "../../components/Landing/Hero";
import Info from "../../components/Landing/Info";
// import institutionsHeroImg from "../../assets/images/institutions_hero.png";
// import { ReactComponent as Pago } from "../../assets/svg/pago.svg";
// import { ReactComponent as Professional } from "../../assets/svg/profesional.svg";
// import { ReactComponent as Savings } from "../../assets/svg/savings.svg";
import institutionsCalendarImg from "../../assets/images/calendar_institutions.png";
import institutionsTeamImg from "../../assets/images/team_institutions.png";
import institutionsFernadaImg from "../../assets/images/fernada_institutions.png";
import institutionsQualificationImg from "../../assets/images/qualification_institutions.png";
import homeHeroImg from "../../assets/images/home_hero.png";
import { ReactComponent as ValidatedIcon } from "../../assets/svg/validated.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/info.svg";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross.svg";

const Institutions = () => {
  return (
    <>
        <Hero
        title="Trabaja cuando y donde quieras"
        topLabel="Encuentra turnos diarios en tu área"
        text={[
          {
            body: "Ya seas un profesional de la salud que busca realizar turnos, un centro de atención médica o un particular con la necesidad de personal de forma inmediata o a largo plazo, Carendar es la solución que necesitas!",
            bolds: ["Carendar"],
          },
        ]}
        buttons={[
          { text: "Regístrate para trabajar" },
          { text: "Estoy buscando un profesional" },
        ]}
        img={homeHeroImg}
        cards={[
          {
            title: "100% online",
            text: "Publicaciones, turnos, match y pago totalmente online por medio de la plataforma.",
            icon: <ValidatedIcon />,
          },
          {
            title: "Información detallada",
            text: "Cuando lo desees puedes revisar información de las ofertas y profesionales disponibles.",
            icon: <InfoIcon />,
          },
          {
            title: "Gratuito",
            text: "Nuestro servicio es totalmente gratuito, ayudamos a conectar a instituciones y profesionales.",
            icon: <CrossIcon />,
          },
        ]}
      />
      {/* <Hero
        title="Encuentra profesionales de calidad"
        topLabel="Fácil y rápido"
        text={[
          {
            // body: "Carendar te ahorra tiempo y dinero al proporcionar profesionales de confianza “on demand”",
            body: "Carendar te ahorra tiempo y dinero al ayudarte a encontrar profesionales de forma facil, rápida y confiable para la atención de cuidado para ti y tu familia.",
            // Encuentra de forma fácil,  profesionales 
            bolds: ["Carendar"],
          },
        ]}
        buttons={[
          { text: "Regístrar mi institución" },
          { text: "Estoy buscando trabajo" },
        ]}
        img={institutionsHeroImg}
        cards={[
          {
            title: "Plataforma online",
            text: "Conectamos a Instituciones de salud, Centros de Adultos Mayores y particulares con nuestro staff.",
            icon: <Pago />,
          },
          {
            title: "Profesionales on demand",
            text: "Staff completo de profesionales de salud certificado y calificado “on demand”.",
            icon: <Professional />,
          },
          {
            title: "Ahorro de tiempo y dinero",
            text: "Con Carendar tu centro de salud puede agendar trabajos en minutos, ahorrando en agencias e intermediarios.",
            icon: <Savings />,
          },
        ]}
      /> */}
      <Info
        items={[
          {
            // title: "Publicar turnos ",
            title: "Publica lo que necesitas gratis",
            body: [
              {
                // text: "Sin importar si eres un hospital, un centro de salud, un centro de adultos mayores o una persona natural, te puedes registrar en Carendar y publicar ofertas de turnos por día, por hora o por un tiempo más prolongado de forma totalmente gratuita.",
                text: "Registrate en Carendar y publica si necesitas un enfermero, cuidador o un servicio de salud específico en tu hogar  u online por hora, día o tiempo más prolongado de forma totalmente gratuita.",
                bolds: [],
              },
            ],
            img: institutionsCalendarImg,
          },
          {
            title: "Match con profesionales",
            body: [
              {
                // text: "Una vez que el turno está publicado, inmediatamente hará “match” con el profesional de salud más calificado para el trabajo.",
                text: "Una vez publicado inmediatamente hará “match” con los profesionales más calificados para el trabajo.",
                bolds: [],
              },
            ],
            img: institutionsTeamImg,
          },
          {
            title: "Información detallada",
            body: [
              {
                // text: "Podrás revisar todos los antecedentes y credenciales del profesional y luego dar ok para que comience su trabajo!",
                text: "Podrás revisar todos los antecedentes y credenciales de los profesionales y luego elegir el que más se adecúe a tus preferencias para que comience su trabajo.",
                bolds: [],
              },
            ],
            img: institutionsFernadaImg,
          },
          {
            title: "Calificación",
            body: [
              {
                text: "Una vez terminado el trabajo podrás calificar al profesional",
                bolds: [],
              },
            ],
            img: institutionsQualificationImg,
          },
        ]}
        footer="Regístrate gratis a través de www.carendar.app"
      />
    </>
  );
};

export default Institutions;
