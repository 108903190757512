import * as yup from "yup";
//import { validate } from 'rut.js'


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const professionalProfile = {
  schema: yup.object().shape({
    first_name: yup
      .string()
      .required("First name is required")
      .max(75, "First name can't be more than 75 characters"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email().required("Email is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    rut: yup
      .string()
      .required("Rut is required")
      .max(20, "Rut can't have more than 20 characters"),
      //.test("Rut válido", "Rut invalido", 
        //async (value) => (await validacion(value))),
      //.test("Rut válido","Rut invalido",(value) => validate(value)),
    specialty: yup
      .string()
      .required("Specialty is required")
      .max(75, "Specialty can't be more than 75 characters"),
    sub_specialty: yup
      .string()
      .required("Sub specialty is required")
      .max(75, "Sub specialty can't be more than 75 characters"),
    description: yup
      .string()
      .required("Description is required")
      .max(300, "Description can't be more than 300 characters"),
  }),
};



export const patientProfile = {
  schema: yup.object().shape({
    first_name: yup
      .string()
      .required("First name is required")
      .max(75, "First name can't be more than 75 characters"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email().required("Email is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    rut: yup
      .string()
      .required("Rut is required")
      .max(20, "Rut can't have more than 20 characters"),
      //.test("Rut válido","Rut invalido",(value) => validate(value)),
      //.test("Rut válido","Rut invalido",(value) => false),

    }),
};
