import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm, yupResolver } from "@mantine/form";
import {
  Divider,
  NumberInput,
  Textarea,
  Button,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  BsFillClockFill as ClockIcon,
  BsCalendar3Week as DateIcon,
  BsCurrencyDollar as MoneyIcon,
} from "react-icons/bs";
import { GiSandsOfTime as TimeIcon } from "react-icons/gi";
import { FaHospitalAlt as InstitutionIcon } from "react-icons/fa";
import { proposal as proposalValidation } from "../../../validations/ProposalValidation";
import Container from "../../../components/UI/Container";
import {
  getSingleProposal,
  updateProposal,
  withdrawProposal,
  selectProposalById,
} from "../../../features/proposals/proposalsSlice";

const EditProposal = () => {
  const { proposalId } = useParams();
  const [isEdit, setIsEdit] = useState(false);

  const {
    proposal,
    getSingleProposalStatus: status,
    getSingleProposalError: error,
  } = useSelector((state) => selectProposalById(state, proposalId));

  const {
    updateProposalStatus,
    updateProposalError,
    withdrawProposalStatus,
    withdrawProposalError,
  } = useSelector((state) => state.proposals);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Extend dayjs to support <diff> function for calculating the
  // difference between 2 dates
  dayjs.extend(localizedFormat);

  useEffect(() => {
    dispatch(getSingleProposal(proposalId));
  }, [dispatch, proposalId]);

  const form = useForm({
    schema: yupResolver(proposalValidation.schema),
    initialValues: proposalValidation.initialValues,
  });

  useEffect(() => {
    if (status === "succeeded" && proposal) {
      form.setFieldValue(
        "proposed_hourly_rate",
        +proposal.proposed_hourly_rate
      );
      form.setFieldValue("message", proposal.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, proposal]);

  if (status === "loading" || status === "idle") {
    return (
      <Container>
        <h1 className="text-2xl font-semibold mb-8 px-8">Proposal details</h1>
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light min-h-[350px] flex items-center justify-center">
          <Loader />
        </section>
      </Container>
    );
  }

  if (status === "failed" || !proposal) {
    return (
      <Container>
        <h1 className="text-2xl font-semibold mb-8 px-8">Proposal details</h1>
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light min-h-[350px] flex flex-col items-center justify-center text-center p-4">
          <p className="text-sm font-semibold text-red-500 mb-3">
            Error: {error}
          </p>
          <p className="mb-6">
            There is not a shift available for you here. Go back to find a new
            job!
          </p>
          <Link to="/professional">
            <Button className="bg-primary-dark">Find a job!</Button>
          </Link>
        </section>
      </Container>
    );
  }

  const { shift_dict: shift } = proposal;

  const shiftsTimePerWeek = shift.shift_length_in_hours + shift.shifts_per_week;

  const startDate = dayjs(shift.start_date);
  const endDate = dayjs(shift.end_date);
  const durationInDays = endDate.diff(startDate, "day");
  let durationText = "< 1 month";
  if (durationInDays >= 30) {
    durationText = "> 1 month";
  }
  const dateShiftCreated = dayjs(shift.created).format("ll");
  const hourlyRate = Number(shift.shift_hourly_rate);

  const proposalActionLoading =
    updateProposalStatus === "loading" || withdrawProposalStatus === "loading";

  const proposalIsActive = proposal.status === 1;

  const updateProposalHandler = async (values) => {
    const proposalData = {
      ...proposal,
      ...values,
    };
    const result = await dispatch(updateProposal(proposalData)).unwrap();
    if (result.id) navigate("/professional/myjobs");
  };

  const withdrawProposalHandler = async () => {
    const result = await dispatch(withdrawProposal(proposal)).unwrap();
    if (result.id) navigate("/professional/myjobs");
  };

  return (
    <section>
      <LoadingOverlay visible={proposalActionLoading} />
      <Container>
        <h1 className="text-2xl font-semibold mb-8 px-8">
          Detalles de la propuesta
        </h1>

        {/* Shift details */}
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light">
          <h2 className="text-xl font-semibold px-8 py-4">
            Detalles del turno
          </h2>
          <Divider color="#20A2C9" />
          <div className="flex justify-between px-8 py-4 min-h-[250px]">
            <div className="w-[70%]">
              <h3 className="text-xl font-semibold">{shift.title}</h3>
              <div className="flex items-center my-5 text-sm">
                <p className="bg-gray-200 rounded-[5000px] px-2 mr-4">
                  {shift.position}
                </p>
                <p className="text-gray-500">{`Creado en ${dateShiftCreated}`}</p>
              </div>
              <p className="mb-5">{shift.description}</p>
              <Link
                to={`/professional/details/${shift.id}`}
                className="text-primary-dark hover:underline"
              >
                Ver turno
              </Link>
            </div>
            <div className="border-l w-[20%] border-l-[#20A2C9] pl-4 text-sm">
              <div className="flex items-start mb-5">
                <InstitutionIcon className="mr-3" />
                <div>
                  <p className="font-semibold">{shift.institution}</p>
                  <p className="text-gray-500">Institucion</p>
                </div>
              </div>
              <div className="flex items-start mb-5">
                <ClockIcon className="mr-3" />
                <div>
                  <p className="font-semibold">${hourlyRate}</p>
                  <p className="text-gray-500">Tasa por hora</p>
                </div>
              </div>
              <div className="flex items-start mb-5">
                <TimeIcon className="mr-3" />
                <div>
                  <p className="font-semibold">{shiftsTimePerWeek} hrs</p>
                  <p className="text-gray-500">Por semana</p>
                </div>
              </div>
              <div className="flex items-start">
                <DateIcon className="mr-3" />
                <div>
                  <p className="font-semibold">{durationText}</p>
                  <p className="text-gray-500">Duracion</p>
                </div>
              </div>
            </div>
          </div>
          <Divider color="#20A2C9" />
          <div className="px-8 py-4">
            <h4 className="font-semibold mb-3">Los terminos de tu propuesta</h4>
            {isEdit && (
              <div className="flex items-start">
                <div className="text-sm">
                  <label htmlFor="shift_hourly_rate" className="font-semibold">
                    Tasa por hora
                  </label>
                  <p className="text-gray-500">
                    Total de la propuesta que vera el cliente
                  </p>
                </div>
                <div className="ml-8">
                  <NumberInput
                    name="shift_hourly_rate"
                    id="shift_hourly_rate"
                    type="text"
                    placeholder="Tarifa horaria por turno"
                    aria-label="Shift hourly rate"
                    size="md"
                    min={1}
                    hideControls
                    className="mb-4 w-full sm:ml-8"
                    icon={<MoneyIcon />}
                    {...form.getInputProps("proposed_hourly_rate")}
                  />
                </div>
              </div>
            )}
            {!isEdit && (
              <div className="">
                <p className="font-semibold">Tasa por hora</p>
                <p className="mb-2">
                  Total de la propuesta que vera el cliente
                </p>
                <p>${Number(proposal.proposed_hourly_rate)}/hr</p>
              </div>
            )}
          </div>
        </section>

        {/* Cover letter */}
        {isEdit && (
          <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light">
            <h2 className="text-xl font-semibold mb-3 px-8 pt-4">
              Carta de presentacion
            </h2>
            <div className="px-8 pb-7">
              <Textarea
                name="message"
                size="md"
                placeholder=""
                autosize
                minRows={6}
                {...form.getInputProps("message")}
              />
            </div>
          </section>
        )}
        {!isEdit && (
          <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light min-h-[200px]">
            <h2 className="text-xl font-semibold px-8 py-4">
              Carta de presentacion
            </h2>
            <Divider color="#20A2C9" />
            <div className="px-8 py-3">
              <p>{proposal.message}</p>
            </div>
          </section>
        )}

        {/* Action Buttons */}
        {proposalIsActive && (
          <section className="bg-white rounded-[5px] shadow-lg border border-primary-light">
            <div className="px-8 py-4">
              {!isEdit && (
                <>
                  <Button
                    className="!bg-primary-dark !rounded-[5000px]"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  >
                    Editar Terminos
                  </Button>
                  <Button
                    className="!text-primary-dark !bg-transparent !border !border-primary-dark !rounded-full hover:!bg-gray-50 !ml-5"
                    onClick={withdrawProposalHandler}
                  >
                    Retirar Propuesta
                  </Button>
                </>
              )}
              {isEdit && (
                <>
                  <Button
                    className="!bg-primary-dark !rounded-[5000px]"
                    onClick={form.onSubmit(updateProposalHandler)}
                  >
                    Enviar
                  </Button>
                  <Button
                    className="!text-primary-dark !bg-transparent !border !border-primary-dark !rounded-full hover:!bg-gray-50 !ml-5"
                    onClick={() => setIsEdit(false)}
                  >
                    Cancelar
                  </Button>
                </>
              )}
            </div>
          </section>
        )}
      </Container>
    </section>
  );
};

export default EditProposal;
