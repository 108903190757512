import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from '../../../api/axiosConfig';
import { TextInput, Button, Divider } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IoMdDoneAll as SucceessIcon } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
/////////////////////////////////////////////////////////
//Page that send the request to the email we want reset//
/////////////////////////////////////////////////////////

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            await axios.request({
                baseURL: BASE_URL,
                method: "post",
                url: "/api/password_reset_request/",
                data: {
                email: email,
                },
            });
            
            
            showNotification({
              title: "Listo!",
              message: `¡El correo se ha envíado correctamente!`,
              color: "green",
              autoClose: 6000,
              icon: <SucceessIcon />,
            });
            
        }catch(error){//Redirect
            console.log(error);
        }
        navigate("/auth/password_reset");
    };

  return (
    <div className="bg-light-gray min-h-screen">
      <form className="mt-16 mb-12" onSubmit={handleSubmit}>
        <TextInput
          type="email"
          name="email"
          placeholder="xxxxx@xxxx.xx"
          aria-label="email"
          required
          size="md"
          classNames={{
            input:
              "border border-[#E6E6E6] border-b-0 rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
          }}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Button
          type="submit"
          className="bg-primary-dark text-white w-full hover:bg-primary-dark rounded-[5px] h-10 border-none mt-6 mb-10"
        >
          Recuperar contraseña
        </Button>
      </form>
      <Divider className="border-primary-dark mb-10" />
      <Divider
          color="black"
          className="w-11/12 mx-auto"
          classNames={{ label: "sm:text-lg" }}
          label={
            "¿Recordaste tu contraseña?"
          }
          labelPosition="center"
        />
      <div className="flex justify-center mt-8">
        <Link to={"login"} className="w-full max-w-sm">
          <Button className="bg-light-blue !text-primary-dark w-full hover:bg-light-blue rounded-[5px] h-10 border-none">
            {"Iniciar sesión"}
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;