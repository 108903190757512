import Hero from "../../components/Landing/Hero";
import Info from "../../components/Landing/Info";
// import homeHeroImg from "../../assets/images/home_hero.png";
// import { ReactComponent as ValidatedIcon } from "../../assets/svg/validated.svg";
// import { ReactComponent as InfoIcon } from "../../assets/svg/info.svg";
// import { ReactComponent as CrossIcon } from "../../assets/svg/cross.svg";
import homeInfoDoctorImg from "../../assets/images/doctor_home.png";
import Services from "../../components/Landing/Services";
import institutionsHeroImg from "../../assets/images/institutions_hero.png";
import { ReactComponent as Pago } from "../../assets/svg/pago.svg";
import { ReactComponent as Professional } from "../../assets/svg/profesional.svg";
import { ReactComponent as Savings } from "../../assets/svg/savings.svg";

const Home = () => {
  return (
    <>
        <Hero
        title="Encuentra profesionales de calidad"
        topLabel="Fácil y rápido"
        text={[
          {
            // body: "Carendar te ahorra tiempo y dinero al proporcionar profesionales de confianza “on demand”",
            body: "Carendar te ahorra tiempo y dinero al ayudarte a encontrar profesionales de forma facil, rápida y confiable para la atención de cuidado para ti y tu familia.",
            // Encuentra de forma fácil,  profesionales 
            bolds: ["Carendar"],
          },
        ]}
        buttons={[
          { text: "Regístrar mi institución", link: "/auth/patient-signup" },
          { text: "Estoy buscando trabajo", link: "/auth/professional-signup" },
        ]}
        img={institutionsHeroImg}
        cards={[
          {
            title: "Plataforma online",
            // text: "Conectamos a Instituciones de salud, Centros de Adultos Mayores y particulares con nuestro staff.",
            text: "Te conectamos con los mejores profesionales de la salud y cuidadores en un solo lugar.",
            icon: <Pago />,
          },
          {
            title: "Profesionales on demand",
            // text: "Staff completo de profesionales de salud certificado y calificado “on demand”.",
            text: "Un staff completo de profesionales inscritos certificados y calificados para entregarte sus servicios en tu domicilio para cuando lo necesites.",
            icon: <Professional />,
          },
          {
            title: "Ahorro de tiempo y dinero",
            // text: "Con Carendar tu centro de salud puede agendar trabajos en minutos, ahorrando en agencias e intermediarios.",
            text: "Con Carendar puedes solicitar profesionales en minutos, ahorrando en agencias e intermediarios.",
            icon: <Savings />,
          },
        ]}
      />
      {/* <Hero
        title="Trabaja cuando y donde quieras"
        topLabel="Encuentra turnos diarios en tu área"
        text={[
          {
            body: "Ya seas un profesional de la salud que busca realizar turnos, un centro de atención médica o un particular con la necesidad de personal de forma inmediata o a largo plazo, Carendar es la solución que necesitas!",
            bolds: ["Carendar"],
          },
        ]}
        buttons={[
          { text: "Regístrate para trabajar" },
          { text: "Estoy buscando un profesional" },
        ]}
        img={homeHeroImg}
        cards={[
          {
            title: "100% online",
            text: "Publicaciones, turnos, match y pago totalmente online por medio de la plataforma.",
            icon: <ValidatedIcon />,
          },
          {
            title: "Información detallada",
            text: "Cuando lo desees puedes revisar información de las ofertas y profesionales disponibles.",
            icon: <InfoIcon />,
          },
          {
            title: "Gratuito",
            text: "Nuestro servicio es totalmente gratuito, ayudamos a conectar a instituciones y profesionales.",
            icon: <CrossIcon />,
          },
        ]}
      /> */}
      <Services />
      <Info
        items={[
          {
            title: "¿Quieres saber más?",
            body: [
              {
                text: "Te invitamos a conocer nuestra plataforma y los beneficios que puedes obtener.",
                bolds: [""],
              },
            ],
            links: [
              { text: "Soy profesional", to: "/profesionales" },
              { text: "Soy institución", to: "/instituciones" },
            ],
            img: homeInfoDoctorImg,
          },
        ]}
      />
    </>
  );
};

export default Home;
