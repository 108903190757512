import Hero from "../../components/Landing/Hero";
import Info from "../../components/Landing/Info";
import aboutusHeroImg from "../../assets/images/aboutus_hero.png";
import aboutusDoctorImg from "../../assets/images/simple_doctor_about.png";

const AboutUs = () => {
  return (
    <>
      <Hero
        title="Carendar"
        text={
          [
            {
              body: "La plataforma online que conecta a Instituciones de salud, Centros de Adultos Mayores y particulares con un staff completo de profesionales de salud certificado y calificado “on demand”",
              bolds: ["Carendar"],
            },
            {
              body: "Carendar es una empresa que trabaja con instituciones médicas, hospitales y pacientes para crear una fuerza laboral más flexible y eficiente. Ayudamos a nuestros clientes a transformar su fuerza laboral reduciendo los costos, cubriendo turnos rápidamente y creando un grupo confiable de profesionales a través de la tecnología.",
              bolds: [],
            },
          ]
          // body: "La plataforma online que conecta a Instituciones de salud, Centros de Adultos Mayores y particulares con un staff completo de profesionales de salud certificado y calificado “on demand”",
          // bolds: ["Carendar"],
        }
        img={aboutusHeroImg}
      />
      <Info
        items={[
          {
            title: "Nuestra misión",
            body: [
              {
                text: "Es conectar tus necesidades con profesionales calificados de forma simple y rápida. A través de nuestra plataforma ofrecemos eficiencia, flexibilidad y oportunidades de crecimiento.",
                bolds: [""],
              },
            ],
            links: [
              { text: "Soy profesional", to: "/profesionales" },
              { text: "Soy institución", to: "/instituciones" },
            ],
            img: aboutusDoctorImg,
          },
        ]}
      />
    </>
  );
};

export default AboutUs;
