import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../UI/Container";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import ShiftSubCategories from "./ShiftSubCategories";
import axios from "axios";
import { BASE_URL } from "../../../api/axiosConfig";

const ShiftCategories = () => {
  const [servicesAreExpanded, setServicesAreExpanded] = useState(false);
  const [professionalAreExpanded, setProfessionalAreExpanded] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [filterParam, setFilterParam] = useState("");
  const [modalName, setModalName] = useState("");
  const [availProfessionals, setAvailProfessionals] = useState([]);

  const pat = availProfessionals?.map(specialty=>{
    return specialty.specialty;

  });
//   console.log('pat', pat)

  const handleClick = (serviceName) => () => {
    setModalName(serviceName);
    open();
  };
  

  const [specialty,setSpecialty] = useState([]);
  const services = [
    {
      name: "consulta_medica",
      title: "Consulta médica domiciliaria",
      imgSrc: "assets/images/services/medical-consultation.png",
    },
    {
      name: "madre_y_bebe",
      title: "Cuidados de la madre y el bebé",
      imgSrc: "assets/images/services/mothers-care.png",
    },
    {
      name: "nutricion",
      title: "Consulta de nutrición y dietética",
      imgSrc: "assets/images/services/nutrition-dietics.png",
    },
    {
      name: "paciente_critico",
      title: "Cuidados del paciente crítico",
      imgSrc: "assets/images/services/critical-patient-care.png",
    },
    {
      name: "terapia_fisica",
      title: "Terapias físicas y músculo esqueléticas",
      imgSrc: "assets/images/services/physical-therapies.png",
    },
    {
      name: "adulto_mayor",
      title: "Cuidados del adulto mayor",
      imgSrc: "assets/images/services/elderly-care.png",
    },
    {
      name: "salud_mental",
      title: "Salud mental y consejería",
      imgSrc: "assets/images/services/mental-health.png",
    },
    {
      name: "inyecciones",
      title: "Tomas de test, vacunación e inyecciones",
      imgSrc: "assets/images/services/injections.png",
    },
    {
      name: "medicina_alternativa",
      title: "Medicina y terapias alternativas",
      imgSrc: "assets/images/services/therapies-alternatives.png",
    },
    {
      name: "mascotas",
      title: "Salud y cuidado de mascotas",
      imgSrc: "assets/images/services/pets-care.png",
    },
    {
      name: "necesidades_especiales",
      title: "Terapias para niños/jóvenes con necesidades especiales",
      imgSrc: "assets/images/services/youth-with-special-needs.png",
    },
    {
      name: "estetica",
      title: "Tratamientos faciales, corporales y estéticos",
      imgSrc: "assets/images/services/aesthetic.png",
    },
    {
      name: "teleconsulta",
      title: "Teleconsulta",
      imgSrc: "assets/images/services/teleconsultation.png",
    },
  ];

  const content = services.map((service, idx) => (
    <li
      key={idx}
      className="w-[138px] md:w-[238px] rounded-[20px] bg-white shadow-lg overflow-hidden shrink-0"
    >
      <Link onClick={handleClick(service.name)}>
        <div className="h-[131px] md:h-[231px]">
          <img src={service.imgSrc} alt={service.title} />
        </div>
        <div className="py-[30px] px-[8px]">
          <h4 className="text-base font-bold text-[#252B42] text-center mb-[10px]">
            {service.title}
          </h4>
          <button className="text-[#1973A3] border border-[#1973A3] px-[16px] py-[10px] rounded-[5px] block mx-auto overflow-hidden relative after:absolute after:bg-[#1973A3] after:inset-0 after:translate-y-full after:transition-transform hover:after:translate-y-0 group">
            <p className="relative z-10 transition-colors group-hover:text-white">
              Agendar servicio
            </p>
          </button>
        </div>
      </Link>
    </li>
  ));


  function extractLastWordBetweenSlash(inputString) {
    // Remove trailing slashes to avoid empty entries
    const trimmedString = inputString.replace(/\/+$/, '');
  
    // Split the string by slashes and get the last element
    const words = trimmedString.split('/');
    const lastWord = words[words.length - 1];
  
    return lastWord;
  }

  const newValues = availProfessionals.filter(function (pro){
    if(filterParam ===''){
      return availProfessionals
    }
    else {
    return pro.specialty === filterParam;
    }
  }).map((professional, idx)=>(
    <li
    key={idx}
    className="w-[138px] md:w-[238px] rounded-[20px] bg-white shadow-lg overflow-hidden shrink-0 avail_profess_list"
  >
    
    
    <Link to={`/patient/view_professional/${extractLastWordBetweenSlash(professional.links.self)}/`}>
        
      <div className="h-[131px] md:h-[231px]">
        <img
          src={professional.image}
          alt={professional.first_name}
          className="min-w-[238px] max-w-[238px] min-h-[238px] max-h-[238px] object-cover"
        />
      </div>
      <div className="py-[30px] px-[8px]">
        <h4 className="text-base font-bold text-primary-dark text-center mb-[10px]">
          {professional.first_name} {professional.last_name}
        </h4>

        <p className="text-center">{professional.specialty}</p>

      </div>
      </Link>
  </li>
  ))
 
  const UniiSpecialty = [...new Set(pat)];
  useEffect(() => {
    axios
      .get("/api/professionals/", { baseURL: BASE_URL })
      .then(function (response) {
        setAvailProfessionals(response.data);
        // console.log("professionals",professionals);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);
  return (
    <>
      {/* {console.log("UniiSpecialty", specialty)} */}
      <section className="bg-white py-[112px]">
        <Container>
          <p className="text-sm text-[#23A6F0] font-bold mb-[10px]">
            Nuestros profesionales en un solo lugar
          </p>
          <h2 className="text-[20px] md:text-[40px] text-[#252B42] font-bold mb-[48px]">
            ¿Qué servicio profesional necesitas?
          </h2>
          <ul
            className={`flex flex-wrap gap-[30px] justify-center py-5 ${
              servicesAreExpanded ? "!max-h-[2080px]" : "max-h-[440px]"
            } overflow-hidden`}
          >
            {content}

            <Modal opened={opened} onClose={close} size="auto">
              {/* Modal content */}
              <ShiftSubCategories serviceName={modalName} />
            </Modal>
          </ul>
          {!servicesAreExpanded && (
            <button
              className="bg-[#1973A3] px-[32px] py-[10px] text-sm font-bold text-white rounded-[5px] block mx-auto mt-[35px] transition-transform hover:scale-105"
              onClick={() => {
                setServicesAreExpanded(true);
              }}
            >
              Ver todos los servicios
            </button>
          )}
        </Container>
      </section>
      <section>
        <Container>
          <h2 className="text-[20px] md:text-[40px] text-[#252B42] font-bold my-[48px]">
            Profesionales Disponibles
          </h2>
          <div>
            <select
              onChange={(e) => {
                setFilterParam(e.target.value);
              }}
              className="custom-select"
              aria-label="Filter Countries By Region"
            >
              <option value="">Filtrar por especialidad</option>
              {UniiSpecialty.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            <span className="focus"></span>
          </div>
          <ul
            className={`flex flex-wrap gap-[30px] justify-center py-5 ${
              professionalAreExpanded ? "!max-h-full" : "max-h-[385px]"
            } overflow-hidden`}
          >
            {newValues}
          </ul>
          {/* <Modal opened={opened} onClose={close} size="auto"> */}
              {/* Modal content */}
              {/* <ShiftSubCategories serviceName={professionalModalName} /> */}
            {/* </Modal> */}
          {!professionalAreExpanded && (
            <button
              className="bg-[#1973A3] px-[32px] py-[10px] text-sm font-bold text-white rounded-[5px] block mx-auto mt-[35px] transition-transform hover:scale-105"
              onClick={() => {
                setProfessionalAreExpanded(true);
              }}
            >
              Ver todos los profesionales
            </button>
          )}
        </Container>
      </section>
    </>
  );
};

export default ShiftCategories;
