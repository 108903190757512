import axios from "axios";
import authHeader from "../../helpers/auth-header";
import { BASE_URL } from '../../api/axiosConfig';

//const BASE_URL = "https://alpha.api.carendar.app";
//const BASE_URL = "http://localhost:8000";


const getContracts = async () => {
  const response = await axios.get("/api/contracts", {
    baseURL: BASE_URL,
    headers: authHeader(),
  });
  return response.data;
};

const getSingleContract = async (contractId) => {
  const response = await axios.get(`/api/contracts/${contractId}`, {
    baseURL: BASE_URL,
    headers: authHeader(),
  });

  return response.data;
};

const endContract = async (contractData) => {
  const { contract, rate } = contractData;
  const endContractResponse = await axios.request({
    baseURL: BASE_URL,
    method: "put",
    url: `/api/contracts/${contract.id}/`,
    headers: authHeader(),
    data: {
      ...contract,
      status: 3, // Status 3 - archived/rejected
    },
  });

  const profilesEndpointResponse = await axios.get(
    `/api/profiles/${contract.contract_assigned_username}/`,
    { baseURL: BASE_URL }
  );

  const { id: professionalProfileId } = profilesEndpointResponse.data;

  await axios.request({
    baseURL: BASE_URL,
    method: "post",
    url: `api/ratings/`,
    headers: authHeader(),
    data: {
      user: contract.contract_owner,
      profile: professionalProfileId,
      contract: contract.id,
      rate: rate.number,
      comment: rate.comment,
    },
  });

  return endContractResponse.data;
};

const contractsService = {
  getContracts,
  getSingleContract,
  endContract,
};
export default contractsService;
