import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import shiftsReducer from "../features/shifts/shiftsSlice";
import proposalsReducer from "../features/proposals/proposalsSlice";
import contractsReducer from "../features/contracts/contractsSlice";
import ratingsReducer from "../features/ratings/ratingsSlice";
import uiReducer from "../features/ui/uiSlice";
import verificationReducer from "../features/verification/verificationSlice";

export const store = configureStore({
  // reducer: {
  //   auth: authReducer,
  //   shifts: shiftsReducer,
  // },
  reducer: {
    auth: authReducer,
    shifts: shiftsReducer,
    proposals: proposalsReducer,
    contracts: contractsReducer,
    ratings: ratingsReducer,
    ui: uiReducer,
    verification: verificationReducer
  },
});
