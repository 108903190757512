import { Routes, Route } from "react-router-dom";
import ProposalList from "../../../components/Private/Patient/ProposalList";
import ContractList from "../../../components/Private/Patient/ContractList";
import ContractDetails from "../../../components/Private/Patient/ContractDetails";
import ProposalDetails from "../../../components/Private/Patient/ProposalDetails";
import ProfessionalProfile from "../Professional/ProfessionalProfile";

const PatientMyJobs = () => {
 
    return (
    <>
      <ProposalList />
      <ContractList />
      <Routes>
        <Route
          path="/contracts/details/:contractId"
          element={<ContractDetails />}
        />
        <Route
          path="/proposals/details/:proposalId"
          element={<ProposalDetails />}
        />
      </Routes>
    </>
  );
};

export default PatientMyJobs;
