import React from "react";
import { Button, Divider } from "@mantine/core";
import { Link } from "react-router-dom";
////////////////////////////////////////////////////
//Page that says we have sent an email to the user//
////////////////////////////////////////////////////


const PasswordReset = () => {
  return (
    <div className="mt-16 mb-12 text-center bg-light-gray min-h-screen">
      <p className="mt-4 mb-10"> Si tu correo es correcto revisa tu bandeja de entrada para recuperar tu contraseña.
      <br/>
      Si no te aparece revisa tu carpeta de spam.</p>
      <Divider className="border-primary-dark mb-10" />
      <Divider
          color="black"
          className="w-11/12 mx-auto"
          classNames={{ label: "sm:text-lg" }}
          label={
            "¿Recordaste tu contraseña?"
          }
          labelPosition="center"
        />
      <div className="flex justify-center mt-8">
        <Link to={"login"} className="w-full max-w-sm">
          <Button className="bg-light-blue !text-primary-dark w-full hover:bg-light-blue rounded-[5px] h-10 border-none">
            {"Iniciar sesión"}
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default PasswordReset;