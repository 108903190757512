import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineRight as RightIcon } from "react-icons/ai";

import Container from "../UI/Container";

const Info = ({ items, footer }) => {
  const itemsToDisplay = items.map((item) => {
    const { title, body, links, img } = item;
    const paragraphs = body.map((paragraph) => {
      return paragraph.text.split(" ").map((word) => {
        return { text: word, isBold: paragraph.bolds.includes(word) };
      });
    });
    return {
      title,
      paragraphs,
      links,
      img,
    };
  });

  return (
    <section className="bg-light-blue py-28 font-bold">
      <Container>
        {itemsToDisplay &&
          itemsToDisplay.length > 0 &&
          itemsToDisplay.map((item, idx) => (
            <div
              key={idx}
              className="group flex justify-between items-center mt-[90px] first-of-type:mt-0"
            >
              <div className="max-w-[515px]">
                {/* Decoration */}
                <div className="w-[94px] h-[7px] bg-primary-light mb-[35px]"></div>

                {/* Title */}
                {item.title && (
                  <h2 className="text-[40px] leading-none text-primary-text-color mb-[35px]">
                    {item.title}
                  </h2>
                )}

                {/* Text content */}
                {item.paragraphs &&
                  item.paragraphs.length > 0 &&
                  item.paragraphs.map((paragraph, idx) => (
                    <p
                      key={idx}
                      className="text-2xl leading-snug font-medium text-secondary-text-color mb-[35px] last-of-type:mb-0"
                    >
                      {paragraph &&
                        paragraph.length > 0 &&
                        paragraph.map((word, idx) => (
                          <span
                            key={idx}
                            className={word.isBold ? "font-bold" : ""}
                          >
                            {word.text}{" "}
                          </span>
                        ))}
                    </p>
                  ))}

                {item.links &&
                  item.links.length > 0 &&
                  item.links.map((link, idx) => (
                    <div key={idx}>
                      <Link
                        key={idx}
                        to={link.to}
                        className="w-fit text-sm text-primary-dark mt-[35px] flex items-center"
                      >
                        {link.text} <RightIcon className="ml-[10px]" />
                      </Link>
                    </div>
                  ))}
              </div>

              {/* Image */}
              <div className="group-even:-order-1">
                {item.img && (
                  <img src={item.img} alt="" width="444px" height="369px" />
                )}
              </div>
            </div>
          ))}

        {/* Footer - Last text */}
        {footer && (
          <p className="text-2xl text-center font-bold text-primary-text-color mt-[90px]">
            {footer}
          </p>
        )}
      </Container>
    </section>
  );
};

export default Info;
