import axios from "axios";
import authHeader from "../../helpers/auth-header";
import { BASE_URL } from '../../api/axiosConfig';

// const BASE_URL = "http://localhost:8000";
// const BASE_URL = "http://159.223.206.51";
//const BASE_URL = "https://alpha.api.carendar.app";

const fetchAuthUser = async () => {
  const username = localStorage.getItem("username");

  // GET => /api/users/:username -> retrieve logged in user's info
  const usersEndpointResponse = await axios.get(`/api/users/${username}/`, {
    baseURL: BASE_URL,
  });

  // GET => /api/profiles/:username -> retrieve logged in user's profile data
  const profilesEndpointResponse = await axios.get(
    usersEndpointResponse.data.links.profile,
    { baseURL: BASE_URL }
  );

  let responsesConcat = {};
  if (usersEndpointResponse.data && profilesEndpointResponse.data) {
    responsesConcat = {
      ...usersEndpointResponse.data,
      ...profilesEndpointResponse.data,
    };
  }

  return responsesConcat;
};

const updateProfileImg = async (userProfile) => {
  const profilesEndpointResponse = await axios.request({
    baseURL: BASE_URL,
    method: "put",
    headers: { "Content-Type": "multipart/form-data", ...authHeader() },
    url: `/api/profiles/${userProfile.username}/`,
    data: userProfile,
  });

  return profilesEndpointResponse.data;
};

const updateProfile = async (userProfile) => {
  delete userProfile.image;

  const profilesEndpointResponse = await axios.request({
    baseURL: BASE_URL,
    method: "put",
    headers: { "Content-Type": "application/json", ...authHeader() },
    url: `/api/profiles/${userProfile.username}/`,
    data: userProfile,
  });

  return profilesEndpointResponse.data;
};

const deleteUser = async (userProfile) => {
  const profilesEndpointResponse = await axios.request({
    baseURL: BASE_URL,
    method: "delete",
    headers: { "Content-Type": "multipart/form-data", ...authHeader() },
    url: `/api/users/${userProfile.username}/`,
    data: userProfile,
  });
  return profilesEndpointResponse.data;
};

const userService = {
  fetchAuthUser,
  updateProfileImg,
  updateProfile,
  deleteUser,
};

export default userService;
