import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shiftsService from "./shiftsService";

const initialState = {
  shifts: [],
  status: "idle", // "idle" | "loading" | "succeeded" | "failed"
  error: null,
  getSingleShiftStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  getSingleShiftError: null,
  addShiftStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  addShiftError: null,
  updateShiftStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  updateShiftError: null,
  deleteShiftStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  deleteShiftError: null,
};

export const getShifts = createAsyncThunk(
  "shifts/getShifts",
  async (_, thunkAPI) => {
    try {
      return await shiftsService.getShifts();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const getSingleShift = createAsyncThunk(
  "shifts/getSingleShift",
  async (shiftId, thunkAPI) => {
    try {
      return await shiftsService.getSingleShift(shiftId);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const getPatientShifts = createAsyncThunk(
  "shifts/getPatientShifts",
  async (_, thunkAPI) => {
    try {
      return await shiftsService.getPatientShifts();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const addShift = createAsyncThunk(
  "shifts/addShift",
  async (shift, thunkAPI) => {
    try {
      return await shiftsService.addShift(shift);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const updateShift = createAsyncThunk(
  "shifts/updateShift",
  async (updatedShift, thunkAPI) => {
    try {
      return await shiftsService.updateShift(updatedShift);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const deleteShift = createAsyncThunk(
  "shifts/deleteShift",
  async (shift, thunkAPI) => {
    try {
      return await shiftsService.deleteShift(shift);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const shiftsSlice = createSlice({
  name: "shifts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getShifts
    builder.addCase(getShifts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getShifts.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.shifts = action.payload;
    });
    builder.addCase(getShifts.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    // getSingleShift
    builder.addCase(getSingleShift.pending, (state) => {
      state.getSingleShiftStatus = "loading";
    });
    builder.addCase(getSingleShift.fulfilled, (state, action) => {
      if (state.shifts.length < 1) {
        state.shifts.push(action.payload);
      } else {
        state.shifts = state.shifts.map((shift) => {
          if (shift.id !== action.payload.id) return shift;
          return action.payload;
        });
      }
      state.getSingleShiftStatus = "succeeded";
    });
    builder.addCase(getSingleShift.rejected, (state, action) => {
      state.getSingleShiftError = action.payload;
      state.getSingleShiftStatus = "failed";
    });

    // getPatientShifts
    builder.addCase(getPatientShifts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getPatientShifts.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.shifts = action.payload;
    });
    builder.addCase(getPatientShifts.rejected, (state) => {
      state.status = "failed";
    });

    // addShift
    builder.addCase(addShift.pending, (state) => {
      state.addShiftStatus = "loading";
    });
    builder.addCase(addShift.fulfilled, (state, action) => {
      state.addShiftStatus = "succeeded";
      state.shifts.push(action.payload);
    });
    builder.addCase(addShift.rejected, (state, action) => {
      state.addShiftStatus = "failed";
      // state.error = action.payload;
    });

    // updateShift
    builder.addCase(updateShift.pending, (state) => {
      state.updateShiftStatus = "loading";
    });
    builder.addCase(updateShift.fulfilled, (state, action) => {
      state.shifts = state.shifts.map((shift) => {
        if (shift.id !== action.payload.id) return shift;
        return action.payload;
      });
      state.updateShiftStatus = "succeeded";
    });
    builder.addCase(updateShift.rejected, (state) => {
      state.updateShiftStatus = "failed";
    });

    // deleteShift
    builder.addCase(deleteShift.pending, (state) => {
      state.deleteShiftStatus = "loading";
    });
    builder.addCase(deleteShift.fulfilled, (state, action) => {
      state.shifts = state.shifts.filter(
        (shift) => shift.id !== action.payload
      );
      state.updateShiftStatus = "succeeded";
    });
    builder.addCase(deleteShift.rejected, (state) => {
      state.deleteShiftStatus = "failed";
    });
  },
});

// Custom Selectors
export const selectShifts = (state) => {
  return {
    ...state.shifts,
    // Return all shifts with prop status !== 6(deleted status)
    shifts: state.shifts.shifts.filter((shift) => shift.status !== 6),
  };
};

export const selectAvailableShifts = (state) => {
  return {
    ...state.shifts,
    // Return all shifts with prop status !== 6(deleted status) and !== 3(taken status)
    shifts: state.shifts.shifts.filter(
      (shift) => shift.status !== 3 && shift.status !== 6
    ),
  };
};

export const selectShiftById = (state, shiftId) => {
  const shift = state.shifts.shifts.find(
    (shift) => shift.id === Number(shiftId)
  );
  return {
    shift,
    getSingleShiftStatus: state.shifts.getSingleShiftStatus,
    getSingleShiftError: state.shifts.getSingleShiftError,
  };
};

export default shiftsSlice.reducer;
