import { Button, Divider } from "@mantine/core";
import { AiFillEdit as EditIcon } from "react-icons/ai";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../../../api/axiosConfig';

const ContractItem = ({ contract }) => {
  const [profile, getProfile] = useState("");
  const username = contract.contract_assigned_username;

  useEffect(() => {
    getProfileByUsername(username);
  }, [username]);

  //const BASE_URL = "http://localhost:8000/";
  //const BASE_URL = "https://alpha.api.carendar.app";
  const getProfileByUsername = (username) => {
    axios
      .get("/api/profiles/" + username, { baseURL: BASE_URL })
      .then(function (response) {
        const profile = response.data;
        getProfile(profile);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // nothing?
      });
  };

  const navigate = useNavigate();
  return (
    <>
      <li
        className="px-4 py-5 hover:bg-blue-50 cursor-pointer flex justify-between items-center"
        onClick={() => {
          navigate(`contracts/details/${contract.id}/`);
        }}
      >
        <div>
          <h3 className="font-bold mb-6">
            {profile.first_name} {profile.last_name}
            {profile.average_rating && (
              <p>{profile.average_rating.toFixed(1)} / 5 estrellas </p>
            )}
          </h3>
          <h3 className="font-bold mb-6">Turno: {contract.shift_title}</h3>
          <p>${contract.hourly_rate} / hr </p>
          <p>
            <b>Fechas:</b> {contract.start_date} - {contract.end_date}
          </p>
          <p>
            {" "}
            {contract.shifts_per_week} turnos por semana,{" "}
            {contract.shift_length_in_hours} hrs por turno.
          </p>
          <p>
            Estatus: <b>{contract.status_display}</b>
          </p>
        </div>
        <div className="ml-4 sm:flex">
          <Button className="mb-2 sm:mb-0 bg-primary-dark hover:bg-primary-dark sm:mr-4 block">
            Ver Contrato <EditIcon />
          </Button>
        </div>
      </li>
      <Divider />
    </>
  );
};

export default ContractItem;
