import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Drawer,
  Button,
  UnstyledButton,
  Skeleton,
  Loader,
} from "@mantine/core";
import { ImArrowLeft2 } from "react-icons/im";
import { BsFillClockFill as ClockIcon } from "react-icons/bs";
// import useAxios from "../../../hooks/useAxios";
import axios from "../../../api/axiosConfig";
import authHeader from "../../../helpers/auth-header";
import { BASE_URL } from '../../../api/axiosConfig';

const ProposalDetails = () => {
  const { proposalId } = useParams();
  const [drawerIsOpen, setDrawerIsOpen] = useState(null);
  const [proposal, getProposal] = useState('');
  const navigate = useNavigate();

 // Another API call attempt
 useEffect( () => {
  
  getProposalById(proposalId);
}, [proposalId]);
//const BASE_URL = "http://localhost:8000/";
//const BASE_URL = "https://alpha.api.carendar.app";
const getProposalById = (proposalId) => {
  axios.get("/api/proposals/" + proposalId, {
    baseURL: BASE_URL,
    headers: authHeader(),
  })
  .then( function (response) {
    const proposal = response.data;
    // console.log(proposal)
    getProposal(proposal);
  })
  .catch( function (error) {
    console.log(error)
  })
  .then( function () {
    // nothing?
  });
}
 ///////
const loading = false; 
const error = false;  

  useEffect(() => {
    if (proposalId) {
      setDrawerIsOpen(true);
    }
  }, [proposalId]);

  useEffect(() => {
    let timer;
    //  Redirect when drawer's close animation finishes
    if (drawerIsOpen === false)
      timer = setTimeout(() => {
        navigate(-1);
      }, 400);

    return () => clearTimeout(timer);
  }, [drawerIsOpen, navigate]);

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  if (error) return <h2>Something went wrong!</h2>;

  return (
    <Drawer
      opened={drawerIsOpen}
      position="right"
      onClose={closeDrawerHandler}
      transitionDuration={400}
      classNames={{
        drawer: "bg-[#AEE3EC] w-[1025px] overflow-y-auto",
        closeButton: "border border-black",
      }}
      withCloseButton={false}
      aria-labelledby="drawer-title"
      aria-describedby="drawer-body"
    >
      <div className="text-primary-text-color">
        <div className="bg-blue-100 p-8 border">
          <UnstyledButton
            className="flex focus:outline-none"
            onClick={closeDrawerHandler}
          >
            <ImArrowLeft2 className="text-lg hover:text-primary-light cursor-pointer" />
          </UnstyledButton>
        </div>

        <div id="drawer-body" className="md:flex m-9">
          <div className="border border-blue-300 bg-blue-100 rounded-t-md md:rounded-md md:rounded-r-none md:w-9/12">
            {/* Title */}
            <div className="border-b border-blue-300 py-5 px-6">
              {loading && <Skeleton height={28} />}
              {!loading && proposal.id && (
                <h1 id="drawer-title" className="text-xl font-bold">
                  <p>{proposal.shift_title}</p>
                </h1>
              )}
            </div>
            {/* Genera Info */}
            <div className="border-b border-blue-300 py-5 px-6 text-sm">
              {loading && <Skeleton height={20} className="mb-1" />}
              {!loading && proposal.id && (
                <div>
                  <p className="text-primary-dark mb-1 font-bold">
                    Shift id: {proposal.shift}</p>
                  <p className="text-primary-dark mb-1 font-bold">
                    Professional: {proposal.owner}</p>
                </div>
              )}
              {loading && <Skeleton height={20} className="mb-3" />}
              {!loading && proposal.id && (
                <p className="mb-3">
                  Message: {proposal.message}
                </p>
              )}
              {loading && <Skeleton height={20} />}
              {!loading && proposal.id && <p>Created: {proposal.created}</p>}
            </div>
            {/* Proposal description */}
            <div className="border-b border-blue-300 py-5 px-6 text-sm">
              <p className="">{proposal.description}</p>
            </div>
            {/* Proposal Details */}
            <div className="py-5 px-6 text-sm sm:flex justify-between">
              {loading && (
                <>
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                </>
              )}
              {!loading && proposal.id && (
                <>
                  
                  <div>
                    <p className="flex items-center justify-center font-bold mb-2">
                      <ClockIcon className="mr-1 text-xl" />
                      Proposal Salary
                    </p>
                    <ul className="flex flex-col items-center">
                      <li>
                        <span className="text-primary-dark font-medium">
                          Hourly:
                        </span>{" "}
                        ${proposal.proposed_hourly_rate}
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Right Side */}
          <div className="border border-blue-300 bg-blue-100 border-t-0 rounded-b-md md:border-t md:border-l-0 md:rounded-md md:rounded-l-none md:w-3/12">
            <div className="border-b border-blue-300 py-4 px-6">
              {loading && <Loader size="sm" className="mx-auto" />}
              {!loading && proposal.id && (
                <>
                  <Button
                    variant="default"
                    classNames={{
                      default:
                        "bg-primary-dark text-white w-full  hover:bg-primary-dark rounded-full h-10 border-none mb-3",
                    }}
                  >
                    Edit proposal
                  </Button>
                  <Button
                    variant="default"
                    classNames={{
                      default:
                        "bg-primary-dark text-white w-full  hover:bg-primary-dark rounded-full h-10 border-none mb-3",
                    }}
                  >
                    Reject proposal
                  </Button>
                  <p className="text-sm text-center">
                    Get in touch with {proposal.owner} for more details
                  </p>
                </>
              )}
            </div>
            <div className="py-4 px-6">
              <h2 className="text-sm font-bold mb-2">Payment details</h2>
              <p className="text-sm">
                Here we can put some informationa about the proposal's payment details.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ProposalDetails;
