import { Divider } from "@mantine/core";
import { useNavigate, Link } from "react-router-dom";

const ProposalItem = ({ proposal }) => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   getProfileByUsername(username);
  // }, [username]);

  // const BASE_URL = "https://alpha.api.carendar.app";
  // const getProfileByUsername = (username) => {
  //   axios
  //     .get("/api/profiles/" + username, { baseURL: BASE_URL })
  //     .then(function (response) {
  //       const profile = response.data;
  //       getProfile(profile);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     })
  //     .then(function () {
  //       // nothing?
  //     });
  // };

  return (
    <>
      <li
        className="px-4 py-5 hover:bg-blue-50 cursor-pointer flex justify-between items-center group"
        onClick={() => {
          navigate(`proposals/details/${proposal.id}`);
        }}
      >
        <div className="text-sm flex items-center justify-between w-full">
          <p>
            <span className="font-semibold text-primary-dark">
            <Link to={`/patient/view_professional/${proposal.owner_username}`}>{proposal.owner_username}</Link>
            </span>{" "}
            te mando una propuesta para el turno {" "}
            <span className="font-semibold text-primary-light">
              {proposal.shift_title}
            </span>{" "}
            .
          </p>
          <p className="hidden sm:block opacity-0  translate-y-5 group-hover:opacity-100 group-hover:translate-y-0 transition-all">
            Click para ver el mensaje
          </p>
          <p
            className={`inline-block ${
              proposal.status === 1
                ? "bg-yellow-500"
                : proposal.status === 3
                ? "bg-red-500"
                : proposal.status === 2
                ? "bg-green-500"
                : ""
            } px-3 py-1 rounded-[5000px]`}
          >
            {proposal.status_display}
          </p>
        </div>
      </li>
      <Divider />
    </>
  );
};

export default ProposalItem;
