import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm, yupResolver } from "@mantine/form";
import {
  Divider,
  Popover,
  NumberInput,
  Textarea,
  Button,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  BsFillClockFill as ClockIcon,
  BsCalendar3Week as DateIcon,
  BsCurrencyDollar as MoneyIcon,
} from "react-icons/bs";
import { GiSandsOfTime as TimeIcon } from "react-icons/gi";
import { FaHospitalAlt as InstitutionIcon } from "react-icons/fa";
import { AiFillQuestionCircle as QuestionIcon } from "react-icons/ai";
import { proposal } from "../../../validations/ProposalValidation";
import Container from "../../../components/UI/Container";

import {
  getSingleShift,
  selectShiftById,
} from "../../../features/shifts/shiftsSlice";
import { createProposal } from "../../../features/proposals/proposalsSlice";
import { getAuth } from "../../../features/auth/authSlice";

const ProposalApply = () => {
  const { shiftId } = useParams();
  const { user } = useSelector(getAuth);
  const [budgetPopoverIsOpened, setbudgetPopoverIsOpened] = useState(false);

  const {
    shift,
    getSingleShiftStatus: status,
    getSingleShiftError: error,
  } = useSelector((state) => selectShiftById(state, shiftId));

  const { createProposalStatus } = useSelector((state) => state.proposals);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Extend dayjs to support <diff> function for calculating the
  // difference between 2 dates
  dayjs.extend(localizedFormat);

  useEffect(() => {
    dispatch(getSingleShift(shiftId));
  }, [dispatch, shiftId]);

  const form = useForm({
    schema: yupResolver(proposal.schema),
    initialValues: {
      message: "",
      proposed_hourly_rate: shift ? +shift.shift_hourly_rate : "",
    },
  });

  useEffect(() => {
    if (status === "succeeded" && shift) {
      form.setFieldValue("proposed_hourly_rate", +shift.shift_hourly_rate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, shift]);

  if (status === "loading" || status === "idle") {
    return (
      <Container>
        <h1 className="text-2xl font-semibold mb-8">Crear una propuesta</h1>
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light min-h-[350px] flex items-center justify-center">
          <Loader />
        </section>
      </Container>
    );
  }

  if (status === "failed" || !shift) {
    return (
      <Container>
        <h1 className="text-2xl font-semibold mb-8">Crear una propuesta</h1>
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light min-h-[350px] flex flex-col items-center justify-center text-center p-4">
          <p className="text-sm font-semibold text-red-500 mb-3">
            Error: {error}
          </p>
          <p className="mb-6">
            There is not a shift available for you here. Go back to find a new
            job!
          </p>
          <Link to="/professional">
            <Button className="bg-primary-dark">Find a job!</Button>
          </Link>
        </section>
      </Container>
    );
  }

  const shiftsTimePerWeek = shift.shift_length_in_hours * shift.shifts_per_week;

  const startDate = dayjs(shift.start_date);
  const endDate = dayjs(shift.end_date);//TODO: endDate > startDate
  const durationInDays = endDate.diff(startDate, "day");
  let durationText = "< 1 month";
  if (durationInDays >= 30) {
    durationText = "> 1 month";
  }
  const dateShiftCreated = dayjs(shift.created).format("ll");
  const hourlyRate = Number(shift.shift_hourly_rate);

  const createProposalLoading = createProposalStatus === "loading";

  const submitFormHandler = async (values) => {
    const proposalData = {
      owner: user.user, 
      shift: shiftId,
      ...values,
    };
    const result = await dispatch(createProposal(proposalData)).unwrap();
    if (result.id) {
      navigate("/professional");
    }
  };

  return (
    <section>
      <LoadingOverlay visible={createProposalLoading} />
      <Container>
        <h1 className="text-2xl font-semibold mb-8">Crear una propuesta</h1>

        {/* Shift details */}
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light">
          <h2 className="text-xl font-semibold px-8 py-4">
            Detalles del turno
          </h2>
          <Divider color="#20A2C9" />
          <div className="flex justify-between px-8 py-4 min-h-[250px]">
            <div className="w-[70%]">
              <h3 className="text-xl font-semibold">{shift.title}</h3>
              <div className="flex items-center my-5 text-sm">
                <p className="bg-gray-200 rounded-[5000px] px-2 mr-4">
                  {shift.position}
                </p>
                <p className="text-gray-500">{`Creado en ${dateShiftCreated}`}</p>
              </div>
              <p className="mb-5">{shift.description}</p>
              <Link
                to={`/professional/details/${shiftId}`}
                className="text-primary-dark hover:underline"
              >
                Ver detalles del turno
              </Link>
            </div>
            <div className="border-l w-[20%] border-l-[#20A2C9] pl-4 text-sm">
              <div className="flex items-start mb-5">
                <InstitutionIcon className="mr-3" />
                <div>
                  <p className="font-semibold">{shift.institution}</p>
                  <p className="text-gray-500">Institucion</p>
                </div>
              </div>
              <div className="flex items-start mb-5">
                <ClockIcon className="mr-3" />
                <div>
                  <p className="font-semibold">${hourlyRate}</p>
                  <p className="text-gray-500">Tasa por hora</p>
                </div>
              </div>
              <div className="flex items-start mb-5">
                <TimeIcon className="mr-3" />
                <div>
                  <p className="font-semibold">{shiftsTimePerWeek} hrs</p>
                  <p className="text-gray-500">Por semana</p>
                </div>
              </div>
              <div className="flex items-start">
                <DateIcon className="mr-3" />
                <div>
                  <p className="font-semibold">{durationText}</p>
                  <p className="text-gray-500">Duracion</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Terms */}
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light">
          <h2 className="text-xl font-semibold px-8 py-4">Terminos</h2>
          <Divider color="#20A2C9" />
          <div className="px-8 py-4">
            <h3 className="text-lg font-semibold mb-5">
              Con qué tarifa propone postular al turno?
            </h3>
            <div className="flex items-center justify-center mb-5">
              <p className="text-sm">
                Presupuesto del cliente: ${hourlyRate}/hr
              </p>
              <Popover
                opened={budgetPopoverIsOpened}
                onClose={() => setbudgetPopoverIsOpened(false)}
                withArrow
                width={260}
                position="top"
                transition="slide-down"
              >
                <Popover.Target>
                  <button>
                    <QuestionIcon
                      className="text-lg ml-1 text-primary-light cursor-pointer"
                      onMouseEnter={() => setbudgetPopoverIsOpened(true)}
                      onMouseLeave={() => setbudgetPopoverIsOpened(false)}
                    />
                  </button>
                </Popover.Target>
                <Popover.Dropdown>
                  <p className="text-sm">
                    Este es el presupuesto por hora que el paciente especifico
                    durante la creacion de este turno. Considerelo al establecer
                    su propuesta.
                  </p>
                </Popover.Dropdown>
              </Popover>
            </div>
            <div className="flex items-start">
              <div className="text-sm">
                <label htmlFor="shift_hourly_rate" className="font-semibold">
                  Tasa por hora
                </label>
                <p className="text-gray-500">
                  Cantidad total que el cliente vera en su propuesta
                </p>
              </div>
              <div className="ml-8">
                <NumberInput
                  name="shift_hourly_rate"
                  id="shift_hourly_rate"
                  type="text"
                  placeholder="Tarifa horaria por turno"
                  aria-label="Shift hourly rate"
                  size="md"
                  min={1}
                  hideControls
                  className="mb-4 w-full sm:ml-8"
                  icon={<MoneyIcon />}
                  {...form.getInputProps("proposed_hourly_rate")}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Cover letter */}
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light">
          <h2 className="text-xl font-semibold mb-3 px-8 pt-4">
            Carta de presentacion
          </h2>
          <div className="px-8 pb-7">
            <Textarea
              name="message"
              size="md"
              placeholder=""
              autosize
              minRows={6}
              {...form.getInputProps("message")}
            />
          </div>
          <Divider color="#20A2C9" />

          <div className="px-8 py-4">
            <Button
              className="bg-primary-dark !rounded-[5000px]"
              onClick={form.onSubmit(submitFormHandler)}
            >
              Enviar propuesta
            </Button>
            <Link to="/professional">
              <Button className="!text-primary-dark !bg-transparent hover:!bg-transparent hover:!underline">
                Cancelar
              </Button>
            </Link>
          </div>
        </section>
      </Container>
    </section>
  );
};

export default ProposalApply;
