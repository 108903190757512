import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Indicator } from "@mantine/core";
import { Link } from "react-router-dom";
import Container from "../../../components/UI/Container";
import { getAuth, updateProfilePic } from "../../../features/auth/authSlice";
import { MdEdit as EditIcon, MdVerified as VerifiedIcon } from "react-icons/md";
import AddProfileImg from "../../../components/Private/Professional/AddProfileImg";
import {
  openAddProfileImgModal,
  openEditProfileModal,
  getUI,
} from "../../../features/ui/uiSlice";
import EditProfile from "../../../components/Private/Professional/EditProfile";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { deleteUser } from "../../../features/auth/authSlice";

const ProfessionalProfile = () => {
  const { user } = useSelector(getAuth);
  const { addProfileImgModalIsOpen, editProfileModalIsOpen } =
    useSelector(getUI);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateProfilePicHandler = async (newProfilePic) => {
    const updatedProfile = { ...user, image: newProfilePic };
    dispatch(updateProfilePic(updatedProfile));
  };

  const submitDeleteUser = async (user) => {
    try {
      dispatch(deleteUser(user));
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAccount = () => {
    confirmAlert({
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => submitDeleteUser(user)
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ],
      overlayClassName: "overlay-custom-class-name"
    });
  };

  return (
    <>
      {addProfileImgModalIsOpen && (
        <AddProfileImg
          imgSrc={user.image}
          onProfilePicUpdate={updateProfilePicHandler}
        />
      )}
      {editProfileModalIsOpen && <EditProfile />}
      <section>
        <Container>
          <div className="bg-white rounded-[5px] border border-primary-light shadow-lg">
            <div className="flex justify-between items-center p-6 border-b border-primary-light">
              <div className="flex">
                <div className="relative">
                  <button
                    className="absolute -top-1 -left-1 z-10 rounded-full p-1 border border-primary-dark bg-white hover:bg-gray-100"
                    onClick={() => dispatch(openAddProfileImgModal())}
                  >
                    <EditIcon />
                  </button>
                  <Indicator
                    color="green"
                    position="bottom-end"
                    withBorder
                    size={15}
                    offset={8}
                  >
                    <Avatar size="70px" radius="500px" src={user.image} />
                  </Indicator>
                </div>
                <h1 className="ml-8 text-xl font-semibold">{`${user.first_name} ${user.last_name}`}</h1>
              </div>
              <div>
                <Link
                  to="/"
                  className="border border-primary-dark px-6 py-2 rounded-full text-primary-dark font-semibold text-sm hover:bg-gray-50"
                >
                  See public View
                </Link>
              </div>
            </div>

            <div className="flex min-h-[300px]">
              <div className="border-r border-primary-light p-6">
                {/* Verifications */}
                <div className="mb-6">
                  <h4 className="font-bold mb-2">Verifications</h4>
                  <ul className="text-sm">
                    <li className="mb-1">
                      <p className="font-semibold flex items-center">
                        Phone Number:
                        <span className="font-normal ml-1">
                          {user.phone_number}
                        </span>
                        <span className="ml-1">
                          <VerifiedIcon className="text-blue-700 text-lg" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold flex items-center ">
                        Email:
                        <span className="font-normal ml-1">{user.email}</span>
                        <span className="ml-1">
                          <VerifiedIcon className="text-blue-700 text-lg" />
                        </span>
                      </p>
                    </li>
                    <li className="mt-1">
                      <p className="font-semibold flex items-center">
                        Rut:
                        <span className="font-normal ml-1">
                          {user.rut}
                        </span>
                        <span className="ml-1">
                          <VerifiedIcon className="text-blue-700 text-lg" />
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                {/* Education */}
                <div className="mb-6">
                  <h4 className="font-bold mb-2">Education</h4>
                  <ul className="text-sm">
                    <li className="mb-1">
                      <p className="font-semibold flex items-center">
                        Education info(coming soon)
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="p-6 flex-grow">
                <div className="flex items-center justify-between w-full mb-3">
                  <h2 className="text-xl font-bold text-primary-dark">
                    {user.specialty}{" "}
                    <span className="font-normal text-base text-primary-light">
                      {user.sub_specialty}
                    </span>
                  </h2>
                  <button
                    className="border border-primary-dark p-1 rounded-full hover:bg-gray-50"
                    onClick={() => dispatch(openEditProfileModal())}
                  >
                    <EditIcon className="text-xl" />
                  </button>
                </div>
                <p>{user.description}</p>
              </div>
            </div>
            <div className="flex p-6 border-t border-primary-light flex-col">
              <h4 className="font-bold mb-8">Verifications</h4>
              <div className="flex">
                <Link
                  to="/"
                  className="border border-primary-dark px-6 py-2 rounded-full text-primary-dark font-semibold text-sm hover:bg-gray-50 mr-40"
                >
                  Cambiar contraseña
                </Link>
                
                <button className="border border-red-700 px-6 py-2 rounded-full text-red-700 font-semibold text-sm hover:bg-gray-50" onClick={deleteAccount}>Eliminar Cuenta</button>

              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ProfessionalProfile;
