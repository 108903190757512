import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ratingsService from "./ratingsService";

const initialState = {
  addRatingStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  addRatingError: null,
};

export const addRating = createAsyncThunk(
  "ratings/addRating",
  async (rating, thunkAPI) => {
    try {
      return await ratingsService.addRating(rating);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const ratingsSlice = createSlice({
  name: "ratings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // addRating
    builder.addCase(addRating.pending, (state) => {
      state.addRatingStatus = "loading";
    });
    builder.addCase(addRating.fulfilled, (state) => {
      state.addRatingStatus = "succeeded";
    });
    builder.addCase(addRating.rejected, (state, action) => {
      state.addRatingError = action.payload;
      state.addRatingStatus = "failed";
    });
  },
});

export default ratingsSlice.reducer;
