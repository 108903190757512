import { useNavigate } from "react-router-dom";
import { Divider } from "@mantine/core";

const ShiftItem = ({ shift }) => {
  const navigate = useNavigate();

  return (
    <>
      <li
        className="px-4 py-5 hover:bg-blue-100 cursor-pointer"
        onClick={() => {
          navigate(`details/${shift.id}`);
        }}
      >
        <h3 className="font-bold mb-6">{shift.title}</h3>
        <p>{shift.description}</p>
      </li>
      <Divider />
    </>
  );
};

export default ShiftItem;
