import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import userService from "./userService";

// Get user from localStorage
const authToken = localStorage.getItem("authToken");

// Register user
export const register = createAsyncThunk(
  "api-auth/register",
  async (user, thunkAPI) => {
    try {
      return await authService.register(user);
    } catch (error) {
      const errorMessage =
        error?.response?.data || error?.message || error.toString();
        // console.log(errorMessage);
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Login user
export const login = createAsyncThunk(
  "api-auth/login",
  async (userCredentials, thunkAPI) => {
    try {
      return await authService.login(userCredentials);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

//Google Login user
export const googleLogin = createAsyncThunk(
  "api-auth/googleLogin",
  async (userCredentials, thunkAPI) => {
    // userCredentials => { email: email, username: user_username, token: token }
    try {
      return await authService.googleLogin(userCredentials);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Logout user
export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  await authService.logout();
});

// Get logged in user's data
export const fetchAuthUser = createAsyncThunk(
  "api-auth/getUserData",
  async (_, thunkAPI) => {
    try {
      return await userService.fetchAuthUser();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Change profile pic
export const updateProfilePic = createAsyncThunk(
  "api-auth/updateProfilePic",
  async (updatedProfile, thunkAPI) => {
    try {
      return await userService.updateProfileImg(updatedProfile);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Change profile
export const updateProfile = createAsyncThunk(
  "api-auth/updateProfile",
  async (updatedProfile, thunkAPI) => {
    try {
      return await userService.updateProfile(updatedProfile);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Delete user
export const deleteUser = createAsyncThunk(
  "api-auth/deleteUser",
  async (user, thunkAPI) => {
    try {
      return await userService.deleteUser(user);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  user: null,
  token: authToken ?? null,
  isLoggedIn: !!authToken,
  status: "idle", // "idle" | "loading" | "succeeded" | "failed"
  error: null,
  updateProfilePicStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  updateProfilePicError: null,
  updateProfileStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  updateProfileError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetUpdateProfilePicStatus(state) {
      state.updateProfilePicStatus = "idle";
    },
    resetUpdateProfileStatus(state) {
      state.updateProfileStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isLoggedIn = true;
      state.status = "succeeded";
    });
    builder.addCase(register.rejected, (state, action) => {
      state.token = null;
      state.user = null;
      state.isLoggedIn = false;
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(login.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isLoggedIn = true;
      state.status = "succeeded";
    });
    builder.addCase(login.rejected, (state, action) => {
      state.token = null;
      state.user = null;
      state.isLoggedIn = false;
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.token = null;
      state.status = "idle";
    });
    builder.addCase(fetchAuthUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAuthUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchAuthUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(updateProfilePic.pending, (state) => {
      state.updateProfilePicStatus = "loading";
    });
    builder.addCase(updateProfilePic.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.updateProfilePicStatus = "succeeded";
    });
    builder.addCase(updateProfilePic.rejected, (state, action) => {
      state.updateProfilePicStatus = "failed";
      state.updateProfilePicError = action.payload;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.updateProfileStatus = "loading";
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.updateProfileStatus = "succeeded";
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.updateProfileError = action.payload;
      state.updateProfileStatus = "failed";
    });
    builder.addCase(googleLogin.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(googleLogin.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isLoggedIn = true;
      state.status = "succeeded";
    });
    builder.addCase(googleLogin.rejected, (state, action) => {
      state.token = null;
      state.user = null;
      state.isLoggedIn = false;
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.status = 'loading';
    })
    builder.addCase(deleteUser.fulfilled, (state) => {//check it again
      state.user = null;
      state.isLoggedIn = false;
      state.token = null;
      state.status = "succeeded";
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export const getAuth = (state) => state.auth;

export const { resetUpdateProfilePicStatus, resetUpdateProfileStatus } =
  authSlice.actions;
export default authSlice.reducer;
