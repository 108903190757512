import { Routes, Route } from "react-router-dom";

import ShiftList from "../../../components/Private/Professional/ShiftList";
import ShiftDetails from "../../../components/Private/Professional/ShiftDetails";

const ProfessionalHome = () => {
  return (
    <>
      <ShiftList />
      <Routes>
        <Route path="details/:shiftId" element={<ShiftDetails />} />
      </Routes>
    </>
  );
};

export default ProfessionalHome;
