import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contractsService from "./contractsService";

const initialState = {
  contracts: [],
  status: "idle", // "idle" | "loading" | "succeeded" | "failed"
  error: null,
  getSingleContractStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  getSingleContractError: null,
  endContractStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  endContractError: null,
};

export const getContracts = createAsyncThunk(
  "contracts/getContracts",
  async (_, thunkAPI) => {
    try {
      return await contractsService.getContracts();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const getSingleContract = createAsyncThunk(
  "contracts/getSingleContract",
  async (contractId, thunkAPI) => {
    try {
      return await contractsService.getSingleContract(contractId);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const endContract = createAsyncThunk(
  "contracts/endContract",
  async (contractData, thunkAPI) => {
    try {
      return await contractsService.endContract(contractData);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {},
  // getContracts
  extraReducers: (builder) => {
    builder.addCase(getContracts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getContracts.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.contracts = action.payload;
    });
    builder.addCase(getContracts.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    // getSingleContract
    builder.addCase(getSingleContract.pending, (state) => {
      state.getSingleContractStatus = "loading";
    });
    builder.addCase(getSingleContract.fulfilled, (state, action) => {
      if (state.contracts.length < 1) {
        state.contracts.push(action.payload);
      } else {
        state.contracts = state.contracts.map((contract) => {
          if (contract.id !== +action.payload.id) return contract;
          return action.payload;
        });
      }
      state.getSingleContractStatus = "succeeded";
    });
    builder.addCase(getSingleContract.rejected, (state, action) => {
      state.getSingleContractError = action.payload;
      state.getSingleContractStatus = "failed";
    });

    // endContract
    builder.addCase(endContract.pending, (state) => {
      state.endContractStatus = "loading";
    });
    builder.addCase(endContract.fulfilled, (state, action) => {
      const contractIdx = state.contracts.findIndex(
        (contract) => contract.id === action.payload.id
      );
      state.contracts[contractIdx] = action.payload;
      state.endContractStatus = "succeeded";
    });
    builder.addCase(endContract.rejected, (state, action) => {
      state.endContractError = action.payload;
      state.endContractStatus = "failed";
    });
  },
});

export const selectContracts = (state) => state.contracts;
export const selectPatientContracts = (state) => {
  const PatientContracts = state.contracts.contracts;

  return {
    contracts: PatientContracts,
    status: state.contracts.status,
    error: state.contracts.error,
  };
};

export const selectUnfinishedPatientContracts = (state) => {
  // Return all the "Ongoing contracts"(status code 1)
  const unfinishedContracts = state.contracts.contracts.filter(
    (contract) => contract.status === 1
  );

  return {
    contracts: unfinishedContracts,
    status: state.contracts.status,
    error: state.contracts.error,
  };
};

export const selectContractById = (state, contractId) => {
  const contract = state.contracts.contracts.find(
    (contract) => contract.id === +contractId
  );
  return {
    contract,
    getSingleContractStatus: state.contracts.getSingleContractStatus,
    getSingleContractError: state.contracts.getSingleContractError,
  };
};

export default contractsSlice.reducer;
