import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Button,
  UnstyledButton,
  Skeleton,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { ImArrowLeft2 } from "react-icons/im";
import { BsFillClockFill as ClockIcon } from "react-icons/bs";
import { AiOutlineMessage as MessageIcon } from "react-icons/ai";
import { FaArrowsAltH as ArrowIcon } from "react-icons/fa";
import {
  getSingleProposal,
  rejectProposal,
  selectProposalById,
} from "../../../features/proposals/proposalsSlice";

import axios from "axios";

const ProposalDetails = () => {
  const apiURL = "https://alpha.api.carendar.app";
  const { proposalId } = useParams();
  const [drawerIsOpen, setDrawerIsOpen] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    proposal,
    getSingleProposalStatus: proposalStatus,
    getSingleProposalError: proposalError,
  } = useSelector((state) => selectProposalById(state, proposalId));

  const {
    rejectProposalStatus: rejectStatus,
    rejectProposalError: rejectError,
  } = useSelector((state) => state.proposals);

  useEffect(() => {
    if (proposalId) {
      setDrawerIsOpen(true);
    }
    dispatch(getSingleProposal(proposalId));
  }, [proposalId, dispatch]);

  useEffect(() => {
    let timer;
    //  Redirect when drawer's close animation finishes
    if (drawerIsOpen === false)
      timer = setTimeout(() => {
        navigate("/patient/myjobs");
      }, 400);

    return () => clearTimeout(timer);
  }, [drawerIsOpen, navigate]);

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const rejectProposalHandler = async () => {
    const result = await dispatch(rejectProposal(proposal)).unwrap();
    if (result.id) {
      closeDrawerHandler();
    }
  };

  const handlePayButton = async () => {
    const paymentCreateResponse = await axios.request({
      baseURL: apiURL,
      method: "GET",
      url: `/api/subscribe/`,
      params: {
        commerceOrder: Math.floor(Math.random() * (2000 - 1100 + 1)) + 1100,
        // amount: "1490",
        amount: "350",
        email: "aramis.entreri@gmail.com",
        urlConfirmation:
          apiURL + `/api/proposal_payment_confirmation/${proposalId}/`, //perhaps here it goes the api that records the payment?
        urlReturn: apiURL + "/api/payment_result/",
      },
    });
    //console.log(paymentCreateResponse);
    window.location.replace(paymentCreateResponse.data.redirect_URL);

    // navigate(paymentCreateResponse.data.redirect_URL)
  };

  const openModal = () =>
    openConfirmModal({
      title: "Por favor confirme su acción",
      children: (
        <h5 size="sm">
          {/* Para aceptar esta propuesta y contratar a este profesional debe pagar $1490 pesos */}
          Durante este periodo de marcha blanca, para aceptar esta propuesta y contratar a este profesional debe pagar $350 pesos (en el futuro 
          el precio normal será $1490 pesos).  
        </h5>
      ),
      labels: { confirm: "Confirmar", cancel: "Cancelar" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handlePayButton(),
      confirmProps: { style: { background: "rgb(25 115 163)" } },
      zIndex: 10000,
    });

  const loading = proposalStatus === "loading";

  if (proposalError && proposalStatus === "failed")
    return <h2>{proposalError}</h2>;
  if (rejectError && rejectStatus === "failed") return <h2>{rejectError}</h2>;

  return (
    <Drawer
      opened={drawerIsOpen}
      position="right"
      onClose={closeDrawerHandler}
      transitionDuration={400}
      classNames={{
        drawer: "!bg-[#AEE3EC] !w-[1025px] !overflow-y-auto",
        closeButton: "!border !border-black",
      }}
      withCloseButton={false}
      aria-labelledby="drawer-title"
      aria-describedby="drawer-body"
    >
      <LoadingOverlay visible={rejectStatus === "loading"} />
      <div className="text-primary-text-color">
        <div className="bg-blue-100 p-8 border">
          <UnstyledButton
            className="flex focus:outline-none"
            onClick={closeDrawerHandler}
          >
            <ImArrowLeft2 className="text-lg hover:text-primary-light cursor-pointer" />
          </UnstyledButton>
        </div>

        <div id="drawer-body" className="md:flex m-9">
          <div className="border border-blue-300 bg-blue-100 rounded-t-md md:rounded-md md:rounded-r-none md:w-9/12">
            {/* Title */}
            <div className="border-b border-blue-300 py-5 px-6">
              {loading && <Skeleton height={28} />}
              {!loading && proposal?.id && (
                <h1 id="drawer-title" className="text-xl font-bold">
                  <p>
                    Turno:{" "}
                    <span className="text-primary-light">
                      {proposal.shift_title}
                    </span>{" "}
                    / Profesional:{" "}
                    <span className="text-primary-dark">
                      <Link to={`/patient/view_professional/${proposal.owner_username}`}>{proposal.owner_username}</Link>
                    </span>
                  </p>
                </h1>
              )}
            </div>
            {/* Genera Info */}
            <div className="py-5 px-6 text-sm">
              {loading && <Skeleton height={20} className="mb-5" />}
              {!loading && proposal?.id && (
                <div>
                  <p className="text-primary-dark mb-5 font-bold">
                    Estado de la Propuesta:{" "}
                    <span className="bg-primary-dark text-white px-2 py-1 rounded-[5000px] text-sm">
                      {proposal.status_display}
                    </span>
                  </p>
                </div>
              )}
              {loading && <Skeleton height={20} className="mb-5" />}
              {!loading && proposal?.id && (
                <div className="flex items-center mb-5">
                  <MessageIcon className="text-xl mr-1" />
                  <p>
                    Mensaje: <span className="italic">{proposal.message}</span>
                  </p>
                </div>
              )}
              {loading && <Skeleton height={20} />}
              {!loading && proposal?.id && (
                <div className="flex items-center justify-between sm:justify-start">
                  <div className="flex w-fit flex-col items-center sm:mr-8">
                    <p className="flex items-center font-bold mb-2">
                      <ClockIcon className="mr-1 text-xl" />
                      Tasa propuesta
                    </p>
                    <ul className="flex flex-col">
                      <li>
                        <span className="text-primary-dark font-medium">
                          por hora:
                        </span>{" "}
                        ${Number(proposal.proposed_hourly_rate)}
                      </li>
                    </ul>
                  </div>
                  <ArrowIcon className="sm:mr-8" />
                  <div className="flex w-fit flex-col items-center">
                    <p className="flex items-center font-bold mb-2">
                      <ClockIcon className="mr-1 text-xl" />
                      Tasa del turno
                    </p>
                    <ul className="flex flex-col">
                      <li>
                        <span className="text-primary-dark font-medium">
                          por hora:
                        </span>{" "}
                        $
                        {Number(proposal.shift_dict.shift_hourly_rate)}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Right Side */}
          <div className="border border-blue-300 bg-blue-100 border-t-0 rounded-b-md md:border-t md:border-l-0 md:rounded-md md:rounded-l-none md:w-3/12">
            <div className="border-b border-blue-300 py-4 px-6">
              {loading && <Loader size="sm" className="mx-auto" />}
              {!loading && proposal?.id && (
                <>
                  <Button
                    variant="default"
                    className="!bg-primary-dark !text-white !w-full  !hover:bg-primary-dark !rounded-full !h-10 !border-none !mb-3"
                    onClick={openModal}
                  >
                    Aceptar propuesta
                  </Button>
                  <Button
                    variant="default"
                    className="!bg-red-500 !text-white !w-full !hover:bg-red-500 !rounded-full !h-10 !border-none !mb-3"
                    onClick={rejectProposalHandler}
                  >
                    Rechazar propuesta
                  </Button>
                </>
              )}
            </div>
            <div className="py-4 px-6">
              <h2 className="text-sm font-bold mb-2">Detalles de pago</h2>
              <p className="text-sm">En construccion.</p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ProposalDetails;
