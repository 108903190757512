import { Divider, Loader, ScrollArea } from "@mantine/core";
import {
  getProposals,
  selectPatientProposals,
} from "../../../features/proposals/proposalsSlice";
import Container from "../../UI/Container";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProposalItem from "./ProposalItem";

const ProposalList = () => {
  // Type 1 is when a shift is on pending state
  // This is the default for people coming to this page
  const [shiftTypeFilter, setShiftTypeFilter] = useState(1);
  const { proposals, status, error } = useSelector(selectPatientProposals);
  const dispatch = useDispatch();

  const filteredProposals = proposals.filter(
    (proposal) => proposal.status === shiftTypeFilter
  );

  useEffect(() => {
    dispatch(getProposals());
  }, [dispatch]);

  const changeFilterHandler = (type) => {
    setShiftTypeFilter(type);
  };

  let content;
  if (status === "loading") {
    content = (
      <div className="flex items-center justify-center h-[410px]">
        <Loader />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <ul>
        {filteredProposals.length > 0 &&
          filteredProposals.map((proposal) => (
            <ProposalItem key={proposal.id} proposal={proposal} />
          ))}
      </ul>
    );
  } else if (status === "failed") {
    content = <p>{error}</p>;
  }

  return (
    <section className="mb-10">
      <Container>
        <div className="bg-white rounded-[5px]">
          <header className="md:flex md:items-center">
            <h3 className="text-lg text-primary-dark font-bold px-4 py-5">
              Tus Propuestas
            </h3>
            <div className="flex items-center gap-4 text-sm">
              <button
                className={`px-3 py-1 rounded-[5000px] border border-yellow-500 ${
                  shiftTypeFilter === 1 ? "bg-yellow-500" : ""
                }`}
                onClick={changeFilterHandler.bind(null, 1)}
              >
                Pending
              </button>
              <button
                className={`px-3 py-1 rounded-[5000px] border border-green-500 ${
                  shiftTypeFilter === 2 ? "bg-green-500" : ""
                }`}
                onClick={changeFilterHandler.bind(null, 2)}
              >
                Accepted
              </button>
              <button
                className={`px-3 py-1 rounded-[5000px] border border-red-500 ${
                  shiftTypeFilter === 3 ? "bg-red-500" : ""
                }`}
                onClick={changeFilterHandler.bind(null, 3)}
              >
                Rejected
              </button>
            </div>
          </header>
          <Divider className="border-primary-dark" />
          <ScrollArea style={{ height: 410 }} type="auto">
            {content}
          </ScrollArea>
        </div>
      </Container>
    </section>
  );
};

export default ProposalList;
