import * as yup from "yup";

export const proposal = {
  schema: yup.object().shape({
    proposed_hourly_rate: yup
      .number()
      .required("Se requiere la duración del turno")
      .nullable(true)
      .positive(),
    message: yup
      .string()
      .min(5, "La carta de presentación debe tener al menos 5 caracteres")
      .required("La carta de presentación es un campo obligatorio")
      .max(
        1000,
        "La carta de presentación debe tener un máximo de 200 caracteres"
      ),
  }),
  initialValues: {
    proposed_hourly_rate: null,
    message: "",
  },
};
