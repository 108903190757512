import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Rating from "react-rating";
import {
  Divider,
  Textarea,
  Button,
  Loader,
  LoadingOverlay,
  Popover,
} from "@mantine/core";
import {
  AiFillStar as FullStarIcon,
  AiOutlineStar as EmptyStartIcon,
} from "react-icons/ai";
import Container from "../../../components/UI/Container";
import {
  endContract,
  getSingleContract,
  selectContractById,
} from "../../../features/contracts/contractsSlice";

const EndContract = () => {
  const { contractId } = useParams();
  const [rate, setRate] = useState(0);
  const [feedbackPopoverIsOpened, setFeedbackPopoverIsOpened] = useState(false);
  const textareaInputRef = useRef();

  const { contract, getSingleContractStatus, getSingleContractError } =
    useSelector((state) => selectContractById(state, contractId));

  const { endContractStatus } = useSelector((state) => state.contracts);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleContract(contractId));
  }, [dispatch, contractId]);

  if (
    getSingleContractStatus === "loading" ||
    getSingleContractStatus === "idle"
  ) {
    return (
      <Container>
        <h1 className="text-2xl font-semibold mb-8">Submit a proposal</h1>
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light min-h-[350px] flex items-center justify-center">
          <Loader />
        </section>
      </Container>
    );
  }

  if (getSingleContractStatus === "failed" || !contract) {
    return (
      <Container>
        <h1 className="text-2xl font-semibold mb-8">Submit a proposal</h1>
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light min-h-[350px] flex flex-col items-center justify-center text-center p-4">
          <p className="text-sm font-semibold text-red-500 mb-3">
            Error: {getSingleContractError}
          </p>
          <p className="mb-6">
            There is not a shift available for you here. Go back to find a new
            job!
          </p>
          <Link to="/professional">
            <Button className="bg-primary-dark">Find a job!</Button>
          </Link>
        </section>
      </Container>
    );
  }

  const endContractLoading = endContractStatus === "loading";

  const rateChangeHandler = (rate) => {
    setRate(rate);
  };

  const submitFormHandler = async () => {
    const comment = textareaInputRef.current.value;
    const endContractData = {
      contract,
      rate: { number: rate, comment },
    };

    const result = await dispatch(endContract(endContractData)).unwrap();
    if (result.id) {
      navigate("/patient/myjobs");
    }
  };

  return (
    <section>
      <LoadingOverlay visible={endContractLoading} />
      <Container>
        <h1 className="text-2xl font-bold mb-8 ml-8">Terminar contrato</h1>

        {/* Contract details */}
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light">
          <div className="px-8 py-4">
            <h3 className="text-base font-semibold mb-1">Profesional</h3>
            <p className="mb-6">{contract.contract_owner_username}</p>
            <h3 className="text-base font-semibold mb-1">Titulo del contrato</h3>
            <p>{contract.shift_dict.title}</p>
          </div>
        </section>

        {/* Terms */}
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light">
          <div className="px-8 py-4">
            <h2 className="text-xl font-semibold mb-3">Feedback publico</h2>
            <p className="text-sm">
              Esta evaluacion sera compartida en el perfil publico del profesional asignado.
            </p>
          </div>
          <Divider color="#20A2C9" />
          <div className="px-8 py-4">
            <p className="text-sm font-semibold mb-3">
              Feedback al profesional
            </p>
            <div>
              <Rating
                initialRating={rate}
                emptySymbol={
                  <EmptyStartIcon className="text-2xl text-primary-light mr-2" />
                }
                fullSymbol={
                  <FullStarIcon className="text-2xl text-primary-dark mr-2" />
                }
                onClick={rateChangeHandler}
              />
            </div>
            <p className="text-lg font-semibold mt-4 mb-8">
              Puntaje total: {rate}
            </p>
            <p className="font-semibold text-sm mb-3">
              Comparte tu experiencia trabajando con este profesional con la comunidad
               de Carendar
            </p>
            <Textarea
              name="review"
              placeholder="Tus comentarios seran compartidos publicamente"
              aria-label="Comment text area"
              autosize
              minRows={5}
              className="w-5/6"
              ref={textareaInputRef}
            />
            <div className="text-sm mt-8">
              Ve un {" "}
              <Popover
                opened={feedbackPopoverIsOpened}
                onClose={() => {
                  setFeedbackPopoverIsOpened(false);
                }}
                target={
                  <span
                    className="text-primary-dark hover:underline cursor-pointer"
                    onClick={() => setFeedbackPopoverIsOpened(true)}
                  >
                    {" "}
                    ejemplo de feedback apropiado
                  </span>
                }
                withArrow
                transition="slide-up"
                position="bottom"
                width={380}
                radius={5}
              >
                <p className="bg-primary-light text-white p-1 rounded-[5px]">
                  Disfrute mucho trabajando en este proyecto. Todas mis expectativas y
                  requerimientos fueron comunicados de antemano y el cliente fue muy comunicativo 
                  y responsable con todas mis solicitudes de clarificacion. Me gusto trabajar con ella
                  y espero tener la oportunidad de trabajar con ella nuevamente.
                </p>
              </Popover>
            </div>
          </div>
        </section>

        {/* Cover letter */}
        <section className="bg-white rounded-[5px] mb-10 shadow-lg border border-primary-light">
          <div className="px-8 py-4">
            <Button
              className="bg-primary-dark rounded-[5000px]"
              onClick={submitFormHandler}
            >
              Terminar Contrato
            </Button>
            <Link to="/patient/myjobs">
              <Button className="text-primary-dark bg-transparent hover:bg-transparent hover:underline">
                Cancelar
              </Button>
            </Link>
          </div>
        </section>
      </Container>
    </section>
  );
};

export default EndContract;
