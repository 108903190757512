import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Drawer,
  Button,
  UnstyledButton,
  Skeleton,
  Loader,
} from "@mantine/core";
import { ImArrowLeft2 } from "react-icons/im";
import { MdLocationCity as CityIcon } from "react-icons/md";
import { BsFillCalendarDateFill as DateIcon } from "react-icons/bs";
import { BsFillClockFill as ClockIcon } from "react-icons/bs";
import axios from "../../../api/axiosConfig";
import authHeader from "../../../helpers/auth-header";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { BASE_URL } from '../../../api/axiosConfig';


const ContractDetails = () => {
  const { contractId } = useParams();
  const [drawerIsOpen, setDrawerIsOpen] = useState(null);
  const [contract, getContract] = useState("");
  const [profile, getProfile] = useState("");

  const navigate = useNavigate();

  const username = contract.contract_assigned_username;
  // TODO: This variable will be undefined until the getContractById is called.
  // so there is always a bad api call when opening this modal.

  useEffect(() => {
    getContractById(contractId);
    getProfileByUsername(username);
  }, [contractId, username]);

  //const BASE_URL = "http://localhost:8000/";
  //const BASE_URL = "https://alpha.api.carendar.app";
  const getContractById = (contractId) => {
    axios
      .get("/api/contracts/" + contractId, {
        baseURL: BASE_URL,
        headers: authHeader(),
      })
      .then(function (response) {
        const contract = response.data;
        getContract(contract);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // nothing?
      });
  };

  const getProfileByUsername = (username) => {
    if (username !== undefined) {
      axios
        .get("/api/profiles/" + username, { baseURL: BASE_URL })
        .then(function (response) {
          const profile = response.data;
          getProfile(profile);
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          // nothing?
        });
    } else {
      // console.log("username is undefined");
      const profile = {}; // TODO: This is a hack. Fix.
      getProfile(profile);
    }
  };

  const loading = false;
  const error = false;

  useEffect(() => {
    if (contractId) {
      setDrawerIsOpen(true);
    }
  }, [contractId]);

  useEffect(() => {
    let timer;
    //  Redirect when drawer's close animation finishes
    if (drawerIsOpen === false)
      timer = setTimeout(() => {
        navigate("/patient/myjobs");
      }, 400);

    return () => clearTimeout(timer);
  }, [drawerIsOpen, navigate]);

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  if (error) return <h2>Something went wrong!</h2>;
  // Extend dayjs to support <diff> function for calculating the
  // difference between 2 dates
  dayjs.extend(localizedFormat);

  const dateContractCreated = dayjs(contract.created).format("ll");
  
  return (
    <Drawer
      opened={drawerIsOpen}
      position="right"
      onClose={closeDrawerHandler}
      transitionDuration={400}
      classNames={{
        drawer: "bg-[#AEE3EC] w-[1025px] overflow-y-auto",
        closeButton: "border border-black",
      }}
      withCloseButton={false}
      aria-labelledby="drawer-title"
      aria-describedby="drawer-body"
    >
      <div className="text-primary-text-color">
        <div className="bg-blue-100 p-8 border">
          <UnstyledButton
            className="flex focus:outline-none"
            onClick={closeDrawerHandler}
          >
            <ImArrowLeft2 className="text-lg hover:text-primary-light cursor-pointer" />
          </UnstyledButton>
        </div>
        <div id="drawer-body" className="md:flex m-9">
          <div className="border border-blue-300 bg-blue-100 rounded-t-md md:rounded-md md:rounded-r-none md:w-9/12">
            {/* Title */}
            <div className="border-b border-blue-300 py-5 px-6">
              {loading && <Skeleton height={28} />}
              {!loading && contract.id && (
                <h1 id="drawer-title" className="text-xl font-bold">
                  <p>{contract.shift_title} </p>
                </h1>
              )}
            </div>
            {/* Genera Info */}
            <div className="border-b border-blue-300 py-5 px-6 text-sm">
              {loading && <Skeleton height={20} className="mb-1" />}
              {!loading && contract.id && (
                <div>
                  <p className="text-primary-dark mb-1 font-bold">
                    Dueño del turno: {contract.contract_owner_username}
                  </p>
                  {profile.average_rating && (
                    <p className="text-primary-dark mb-1 font-bold">
                      Profesional asignado: {profile.first_name}{" "}
                      {profile.last_name} ( {profile.average_rating.toFixed(1)}{" "}
                      / 5 stars )
                    </p>
                  )}

                  <p>
                    Especialidad: {profile.specialty}, {profile.sub_specialty}
                  </p>
                  <p className="text-primary-dark mb-1 font-bold">
                    Detalles de contacto:{" "}
                  </p>
                  <p className="font-bold">Tel: {profile.phone_number}</p>
                  <p className="font-bold">Email: {profile.email}</p>
                  <p>Propuesta numero {contract.proposal}</p>
                </div>
              )}
              {loading && <Skeleton height={20} className="mb-3" />}
              
              {loading && <Skeleton height={20} />}
              {!loading && contract.id && <p>{`Creado en ${dateContractCreated}`}</p>}
            </div>
            {/* Contract Status */}
            <div className="border-b border-blue-300 py-5 px-6 text-sm">
              <p className="text-primary-dark mb-1 font-bold">
                Estatus: {contract.status_display}
              </p>
            </div>
            {/* Contract Details */}
            <div className="py-5 px-6 text-sm sm:flex justify-between">
              {loading && (
                <>
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                </>
              )}
              {!loading && contract.id && (
                <>
                  <div className="mb-6 md:mb-0">
                    <p className="flex items-center justify-center font-bold mb-2">
                      <CityIcon className="mr-1 text-xl" />
                      Ubicacion
                    </p>
                    <ul className="flex flex-col items-center">
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Direccion:
                        </span>{" "}
                        {contract.location_address}
                      </li>
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Ciudad:
                        </span>{" "}
                        {contract.location_city}
                      </li>
                      <li>
                        <span className="text-primary-dark font-medium">
                          Region:
                        </span>{" "}
                        {contract.location_region}
                      </li>
                    </ul>
                  </div>
                  <div className="mb-6 md:mb-0">
                    <p className="flex items-center justify-center font-bold mb-2">
                      <DateIcon className="mr-1 text-base" />
                      Duracion del contrato
                    </p>
                    <ul className="flex flex-col items-center">
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Fecha inicio:
                        </span>{" "}
                        {contract.start_date}
                      </li>
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Fecha termino:
                        </span>{" "}
                        {contract.end_date}
                      </li>
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Duracion del turno:
                        </span>{" "}
                        {contract.shift_length_in_hours} hours
                      </li>
                      <li>
                        <span className="text-primary-dark font-medium">
                          Turnos por semana:
                        </span>{" "}
                        {contract.shifts_per_week}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="flex items-center justify-center font-bold mb-2">
                      <ClockIcon className="mr-1 text-xl" />
                      Salario
                    </p>
                    <ul className="flex flex-col items-center">
                      <li>
                        <span className="text-primary-dark font-medium">
                          por hora:
                        </span>{" "}
                        ${contract.hourly_rate}
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Right Side */}
          <div className="border border-blue-300 bg-blue-100 border-t-0 rounded-b-md md:border-t md:border-l-0 md:rounded-md md:rounded-l-none md:w-3/12">
            <div className="border-b border-blue-300 py-4 px-6">
              {loading && <Loader size="sm" className="mx-auto" />}
              {!loading && contract.id && (
                <>
                  <Link to={`/patient/feedbacks/contract/${contractId}`}>
                    <Button
                      variant="default"
                      classNames={{
                        default:
                          "bg-primary-dark text-white w-full  hover:bg-primary-dark rounded-full h-10 border-none mb-3",
                      }}
                    >
                      Terminar contrato
                    </Button>
                  </Link>
                </>
              )}
              <p className="text-sm text-center">
                Va ser redirigido a dar una evaluacion del profesional asignado
              </p>
            </div>
            <div className="py-4 px-6">
              <h2 className="text-sm font-bold mb-2">Historial de pago</h2>
              <p className="text-sm">
                En construccion
              </p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ContractDetails;
