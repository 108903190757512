import { useSelector, useDispatch } from "react-redux";
import { openAddShiftModal } from "../../../features/ui/uiSlice";
import Container from "../../UI/Container";
import { Link } from "react-router-dom";
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';
import Tutorial from "./Tutorial";

const Greeting = ({ onBtnClick }) => {
  const { first_name, last_name } = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const displayedName = `${first_name} ${last_name.charAt(0)}.`;

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <section className="mb-8">
      <Container>
        <div className="md:flex md:justify-between bg-white px-4 py-5 rounded-[5px]">
          <h2 className="flex justify-center text-lg mb-5 md:mb-0 ">{`Hola ${displayedName}`}</h2>
          <Button
            variant="default"
            className="ml-[5.5em] md:ml-0 !bg-primary-dark !text-white !hover:bg-primary-dark !rounded-[5px] !h-10 !border-none"
            onClick={() => {
              dispatch(openAddShiftModal());
            }}
          >
            Añadir nuevo turno
          </Button>
        </div>
        <div className="ml-[4em] md:ml-0">
            <Link
            onClick={open}
            >
                Necesitas ayuda para empezar?
            </Link>
            <Modal opened={opened} onClose={close} title="Tutorial" size="auto">
            {/* Modal content */}
            <Tutorial />
            </Modal>
        </div>
        
      </Container>
    </section>
  );
};

export default Greeting;
