import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import verificationService from "./verificationService";

const initialState = {
  verificationDocuments: {},  
  status: "idle",
  error: null,
  getVerificationDocumentStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  getVerificationDocumentError: null,
  updateVerificationDocumentStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  updateVerificationDocumentError: null,
};

export const getVerificationDocument = createAsyncThunk(
    "verification/getVerificationDocument",
    async (_, thunkAPI) => {
      try {
        return await verificationService.getVerificationDocument();
      } catch (error) {
        const errorMessage =
          error?.response?.data?.message || error?.message || error.toString();
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  );

export const updateVerificationDocument = createAsyncThunk(
"verification/updateVerificationDocument",
async (updatedVerificationDocument, thunkAPI) => {
    try {
    return await verificationService.updateVerificationDocument(updatedVerificationDocument);
    } catch (error) {
    const errorMessage =
        error?.response?.data?.message || error?.message || error.toString();
    return thunkAPI.rejectWithValue(errorMessage);
    }
}
);

const verificationSlice = createSlice({
name: "verification",
initialState,
reducers: {},
extraReducers: (builder) => {
    // getVerificationDocument
    builder.addCase(getVerificationDocument.pending, (state) => {
    state.status = "loading";
    });
    builder.addCase(getVerificationDocument.fulfilled, (state, action) => {
    state.status = "succeeded";
    state.verificationDocuments = action.payload;
    });
    builder.addCase(getVerificationDocument.rejected, (state, action) => {
    state.status = "failed";
    state.error = action.payload;
    });

    // updateVerificationDocument
    builder.addCase(updateVerificationDocument.pending, (state) => {
    state.updateVerificationDocumentStatus = "loading";
    });
    builder.addCase(updateVerificationDocument.fulfilled, (state, action) => {
    state.verificationDocuments = action.payload;
    state.updateVerificationDocumentStatus = "succeeded";
    });
    builder.addCase(updateVerificationDocument.rejected, (state) => {
    state.updateVerificationDocumentStatus = "failed";
    });
},
});

export const getDocument = (state) => state.verification;

export default verificationSlice.reducer;
