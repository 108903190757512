import { Divider, Loader, ScrollArea } from "@mantine/core";
import Container from "../../UI/Container";
import {
  getContracts,
  selectUnfinishedPatientContracts,
} from "../../../features/contracts/contractsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContractItem from "./ContractItem";

const ContractList = () => {
  const { contracts, status, error } = useSelector(
    selectUnfinishedPatientContracts
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContracts());
  }, [dispatch]);

  let content;
  if (status === "loading") {
    content = (
      <div className="flex items-center justify-center h-[410px]">
        <Loader />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <ul>
        {contracts.length > 0 &&
          contracts.map((contract) => (
            <ContractItem
              key={contract.id}
              contract={contract}
              shift_title="hello"
            />
          ))}
      </ul>
    );
  } else if (status === "failed") {
    content = <p>{error}</p>;
  }

  return (
    <section>
      <Container>
        <div className="bg-white rounded-[5px]">
          <h3 className="text-lg text-primary-dark font-bold px-4 py-5">
            Tus Contratos
          </h3>
          <Divider className="border-primary-dark" />
          <ScrollArea style={{ height: 410 }} type="auto">
            {content}
          </ScrollArea>
        </div>
      </Container>
    </section>
  );
};

export default ContractList;
