import { useState, useEffect } from "react";
import { Modal, Avatar } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { closeAddProfileImgModal } from "../../../features/ui/uiSlice";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import {
  getAuth,
  resetUpdateProfilePicStatus,
} from "../../../features/auth/authSlice";

const AddProfileImg = ({ imgSrc, onProfilePicUpdate }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);

  const { updateProfilePicStatus, updateProfilePicError } =
    useSelector(getAuth);

  const dispatch = useDispatch();

  const closeModalHandler = () => {
    setModalIsOpen(false);
    dispatch(resetUpdateProfilePicStatus());
    setTimeout(() => {
      dispatch(closeAddProfileImgModal());
    }, 350);
  };

  useEffect(() => {
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedImg) {
      setImgPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImg);
    setImgPreview(objectUrl);
    //console.log(objectUrl);

    // free memory whenever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImg]);

  const selectImgHandler = (event) => {
    // Get ONLY ONE file from the uploaded files(the first one)
    const file = event.target.files[0];

    // Uploaded file checks
    const { type } = file;
    if (type !== "image/jpeg" && type !== "image/png" && type !== "image/jpg") {
      return;
    }

    setSelectedImg(file);
  };

  useEffect(() => {
    if (updateProfilePicStatus === "succeeded") {
      closeModalHandler();
    }
  }, [updateProfilePicStatus]);

  const updateProfilePicHandler = () => {
    if (!selectedImg) return;
    onProfilePicUpdate(selectedImg);
  };

  return (
    <Modal
      opened={modalIsOpen}
      onClose={closeModalHandler}
      transition="rotate-left"
      transitionDuration={350}
      withCloseButton={false}
      centered
      classNames={{
        modal: "!w-[700px] !p-0",
        title: "!text-xl",
      }}
    >
      <header className="flex justify-between items-center py-4 px-8">
        <p className="text-lg font-semibold">Edit photo</p>
        <CloseIcon
          className="text-xl text-gray-600 cursor-pointer"
          onClick={closeModalHandler}
        />
      </header>
      <section className="border-t border-primary-dark py-4 px-8 flex justify-between items-center gap-12">
        <div>
          <Avatar size="275px" radius="5000px" src={imgPreview || imgSrc} />
        </div>
        <div>
          <p className="text-lg font-semibold mb-9">
            Show to other people the best version of yourself!
          </p>
          <div className="flex justify-between items-end mb-5">
            <Avatar size="105px" radius="5000px" src={imgPreview || imgSrc} />
            <Avatar size="60px" radius="5000px" src={imgPreview || imgSrc} />
            <Avatar size="40px" radius="5000px" src={imgPreview || imgSrc} />
            <Avatar size="22px" radius="5000px" src={imgPreview || imgSrc} />
          </div>
          <p className="text-sm font-semibold text-primary-light">
            Must be an actual photo of you.
          </p>
          <p className="text-sm">
            Logos, clip-art, group photos, and digitally-altered images are not
            allowed.
          </p>
        </div>
      </section>
      <footer
        className={`flex items-center ${
          updateProfilePicStatus === "idle" ? "justify-end" : "justify-center"
        } gap-4 py-4 px-8 border-t border-primary-dark`}
      >
        {updateProfilePicStatus === "idle" && (
          <>
            <div>
              <label
                htmlFor="photo"
                className="text-primary-dark font-semibold text-sm hover:underline decoration-primary-dark"
              >
                Change Image
              </label>
              <input
                type="file"
                id="photo"
                name="photo"
                className="hidden"
                onChange={selectImgHandler}
              />
            </div>
            <button
              className="text-white bg-primary-dark px-4 py-2 rounded-full text-sm font-semibold"
              onClick={updateProfilePicHandler}
            >
              Save photo
            </button>
          </>
        )}
        {updateProfilePicStatus === "loading" && (
          <p className="text-center text-sm text-gray-700">
            Uploading your image... Don't close your browser
          </p>
        )}
      </footer>
    </Modal>
  );
};

export default AddProfileImg;
