import React from "react";

const Container = ({ maxWidth, children }) => {
  return (
    <div
      className={`${
        maxWidth ? maxWidth : "max-w-[1050px]"
      } mx-auto px-6 md:px-0`}
    >
      {children}
    </div>
  );
};

export default Container;
