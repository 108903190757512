import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Divider } from "@mantine/core";

import Container from "../../components/UI/Container";
import Login from "../../components/Auth/Login/Login";
import ChooseSignUp from "../../components/Auth/SignUp/ChooseSignUp";
import PatientSignup from "../../components/Auth/SignUp/PatientSignup";
import ProfessionalSignup from "../../components/Auth/SignUp/ProfessionalSignup";
import ForgotPassword from "../../components/Auth/ForgotPassword/ForgotPassword";
import RestorePassword from "../../components/Auth/ForgotPassword/RestorePassword";
import PasswordReset from "../../components/Auth/ForgotPassword/PasswordReset";

const Auth = () => {
  const location = useLocation();

  // Find if we are in the login or signup page
  const urlLastSegment = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  const isLogin = urlLastSegment === "login";

  let pageTitle = "";
  // => /api-auth/signup
  if (urlLastSegment === "signup")
    pageTitle = "Únase como paciente o profesional";
  //   => /api-auth/login
  else if (urlLastSegment === "login")
    pageTitle = "Iniciar sesión en su cuenta";
  //   => /api-auth/patient-signup
  else if (urlLastSegment === "patient-signup")
    pageTitle = "Regístrate para contratar a un profesional";
  //   => /api-auth/professional-signup
  else if (urlLastSegment === "professional-signup")
    pageTitle = "Regístrate y encuentra tu primer trabajo";
  //   => /api-auth/forgot-password
  else if (urlLastSegment === "forgot-password")
    pageTitle = "Ingresa tu correo electrónico para recuperar tu contraseña"
  //   => /api-auth/password_reset
    else if (urlLastSegment === "password_reset")
    pageTitle = "Recupera tu contraseña"
  //   => /api-auth/reset-password/token...
  else pageTitle = "Crea una nueva contraseña";

  return (
    <div className="bg-light-gray min-h-screen py-8">
      <Container maxWidth="max-w-2xl">
        <header className="mb-8">
          {/* Logo */}
          <div className="flex justify-center">
            <Link to="/">
              <img
                src="/assets/images/logo.png"
                alt="Website Logo"
                className="w-[180px] max-w-full"
              />
            </Link>
          </div>
        </header>
        <h1 className="text-lg text-center text-secondary-text-color">
          {pageTitle}
        </h1>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<ChooseSignUp />} />
          <Route path="patient-signup" element={<PatientSignup />} />
          <Route path="professional-signup" element={<ProfessionalSignup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="password_reset" element={ <PasswordReset/> }/>
          <Route path="reset-password/:token" element={<RestorePassword />} />
          <Route path="*" element={<Navigate replace to="login" />} />
        </Routes>
        <Divider className="border-primary-dark mb-10" />
        <Divider
          color="black"
          className="w-11/12 mx-auto"
          classNames={{ label: "sm:text-lg" }}
          label={
            isLogin
              ? "¿No tienes una cuenta Carendar?"
              : "¿Ya tienes una cuenta?"
          }
          labelPosition="center"
        />
        <div className="flex justify-center mt-8">
          <Link to={isLogin ? "signup" : "login"} className="w-full max-w-sm">
            <Button className="bg-light-blue !text-primary-dark w-full hover:bg-light-blue rounded-[5px] h-10 border-none">
              {isLogin ? "Regístrate" : "Iniciar sesión"}
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Auth;
