import React from "react";
import { NavLink, Link } from "react-router-dom";
import { BiRightArrowAlt as RightArrowIcon } from "react-icons/bi";

import Container from "../UI/Container";

const CustomNavLink = ({ to, children }) => {
  const linkStyles =
    "text-[1em] md:text-base relative py-1 before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-[2px] before:bg-primary-light before:transition-all before:hover:w-full";

  return (
    <NavLink
      to={to}
      className={(navData) =>
        navData.isActive
          ? `text-primary-light ${linkStyles} before:hover:w-0 before:transition-none`
          : linkStyles
      }
    >
      {children}
    </NavLink>
  );
};

const Header = () => {
  return (
    <header className="py-5 bg-light-gray">
      <Container>
        <div className="md:flex justify-between items-center text-sm font-bold">
          {/* Logo Container */}
          <div className="mb-6 md:mb-0 flex justify-center md:block">
            <NavLink to="/">
              <img
                src="/assets/images/logo.png"
                alt="Website Logo"
                className="w-[180px] max-w-full"
              />
            </NavLink>
          </div>

          {/* Navigation Container */}
          <nav>
            <ul className="flex justify-between text-secondary-text-color">
              <li>
                <CustomNavLink to="/profesionales">Quiero Trabajar</CustomNavLink>
              </li>
              <li className="ml-8">
                <CustomNavLink to="/instituciones">
                ¿Cómo Funciona?
                </CustomNavLink>
              </li>
              <li className="ml-8">
                <CustomNavLink to="/nosotros">Nosotros</CustomNavLink>
              </li>
            </ul>
          </nav>

          {/* Actions Container */}
          <div className="mt-10 mb-10 md:mt-0 md:mb-0">
            <ul className="flex items-center justify-center">
              <li className="text-primary-dark">
                <Link
                  to="/auth/login"
                  className="border border-transparent px-5 py-[10px] rounded-[5px] hover:border-primary-dark transition-colors"
                >
                  Ingresa
                </Link>
              </li>
              <li className="ml-4">
                <Link
                  to="/auth/signup"
                  className="text-white uppercase border border-primary-dark bg-primary-dark flex items-center px-5 py-[10px] rounded-[5px] hover:bg-light-gray hover:text-primary-dark transition-colors"
                >
                  regístrate <RightArrowIcon className="text-lg ml-3" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
