export const specialties = [
  "Enfermero/a",
  "Fonoaudiologo/a",
  "Kinesiologo/a",
  "Medico",
  "Psicologo/a",
  "Nutricionista",
  "Matrona",
  "Dentista",
  "Técnico Odontología",
  "Tecnico Laboratorista Dental",
  "Tecnico en Enfermería (TENS)",
  "Cuidador/Chaperon/a/Shadow",
  "Terapeuta Natural y Terapias Alternativas",
  "Tecnólogo Médico",
  "Terapeuta Ocupacional",
  "Masoterapeuta/Masajista",
  "Entrenador Físico",
  "Terapeuta Floral y Plantas Medicinales",
  "Baby Sitter",
  "Cuidador de Mascotas",
  "Técnico de Laboratorio",
  "Técnico en Nutrición",
  "Podologo/a",
  "Técnico en Radiología y Radioterapia",
  "Paramédico",
  "Quiropráctico ",
  "Veterinario/a",
  "Cosmetologo/a",
  "Auxiliar de Farmacia",
  "Bioquimico/a",
  "Psicopedagogo/a",
  "Químico Farmacéutico",
  "Técnico en Farmacia",
];

export const subSpecialties = {
  "Enfermero/a": [
    "Enfermería Geriátrica y Gerontología",
    "Enfermería Pediátrica",
    "Enfermería Curación Avanzada de Heridas ",
    "Enfermería Cuidados Médico-Quirúrgicos",
    "Enfermería Neonatal",
    "Enfermería Cuidados Oncológicos",
    "Enfermería Cuidados Paliativos",
    "Enfermería Cuidados Intensivos del Adulto",
    "Enfermería Cuidados Intensivos del Niño",
    "Enfermería Dialisis",
    "Enfermería Familiar",
    "Enfermería de la Salud Mental",
    "Enfermería Ginecológica y Obstétrica",
  ],
  "Fonoaudiologo/a": [
    "Trastornos del Lenguage y Habla Niños",
    "Trastornos del Lenguage y Habla Adultos",
    "Trastornos de la Voz",
    "Transtornos en la Deglución",
    "Neurorehabilitación",
    "Trastornos de la Comunicación",
    "Estimulación Cognitiva",
    "Transtornos de la Audición",
  ],
  "Kinesiologo/a": [
    "Kinesiología Respiratoria Infantil",
    "Kinesiología Respiratoria Adulto",
    "Masoterapia",
    "Kinesiología Neurológica",
    "Kinesiología Quemados y Cirugía Plástica Reconstructiva",
    "Kinesiología Embarazo y Post-Parto",
    "Kinesiología Pelviperineal",
    "Rehabilitación Traumatológica y Musculoesquelética",
    "Kinesiología Geriatrica y Geriontológica",
    "Kinesiología Cardiologíca y Cirgugía Cardiovascular",
    "Kinesiología Intensiva",
    "Rehabilitación Vascular",
    "Kinesiología Oncológica",
  ],
  "Medico": [
    "Medicina General",
    "Medicina Interna",
    "Geriatria",
    "Pediatría",
    "Neurología",
    "Fisiatra",
    "Medicina del Dolor y Cuidados Paliativos",
  ],
  "Psicologo/a": [
    "Psicología Deportiva",
    "Psicología InfantoJuvenil",
    "Psicología Adulto",
    "Psicología de Parejas",
    "Psicología Familiar",
    "Neuropsicología",
    "Psicología Gerentológica",
    "Sexología",
    "Psicología de la Salud",
    "Psicología Laboral",
  ],
  "Nutricionista": [
    "Nutrición Deportiva",
    "Nutrición Infantil y Adolescente",
    "Nutrición Embarazo y Post-Parto",
    "Nutrición Enfermedades Crónicas y/o Metabólicas",
    "Nutrición Vegana y/o Vegetariana",
    "Nutrición Alergias e Intolerancias Alimentarias",
    "Nutrición Control/Pérdida de Peso",
  ],
  "Matrona": [
    "Atención del Recién Nacido en el Post-Parto",
    "Patologías del Embarazo",
    "Atención Ginecológica",
    "Adaptación a la Lactancia Materna",
    "Urgencias Obstétricas Durante el Embarazo",
    "Cuidados Durante el Embarazo",
  ],
  "Dentista": [
    "Odontopediatra",
    "Endodoncia",
    "Implantología Buco Maxilofacial",
    "Ortodoncia",
    "Patología Oral",
    "Peridoncia",
    "Rehabilitación Oral",
    "Trastornos Temporomandibulares y Dolor Orofacial",
  ],
  "Técnico Odontología": [],
  "Tecnico Laboratorista Dental": [],
  "Tecnico en Enfermería (TENS)": [
    "Cuidado del Adulto Mayor",
    "Cuidados y Hospitalización en Domicilio",
    "Cuidados Oncológicos",
    "Diálisis",
    "Manejo Integral de Heridas",
    "Instrumentación Quirúrgica",
    "Toma de Muestras de Laboratorio",
    "Manejo de Inyecciones",
    "Toma de Test Nasales/Orales/Nasofaringeos",
    "Vacunación",
  ],
  "Cuidador/Chaperon/a/Shadow": [
    "Adulto Mayor",
    "Alcoholismo",
    "Uso de Drogas",
    "Trastornos Alimenticios",
    "Salud Mental",
    "Cuidado del Trastorno del Espectro Autista",
  ],
  "Terapeuta Natural y Terapias Alternativas": [
    "Iridología",
    "Fitoterapia",
    "Relajación",
    "Masajes",
    "Geoterapia",
    "Digitopuntura",
    "Medicina Herbaria",
    "Ondas Magnéticas",
    "Hipnosis",
    "Meditación",
    "Quiropraxia",
    "Biomagnetismo",
    "Reiki",
    "Flores de Bach",
    "Biorretroalimentación",
    "Reflexología",
    "Tai Chi",
    "Homeopatía",
    "Acupuntura",
    "Cuencos Tibetanos",
    "Chi Kung (Qi gong)",
  ],
  "Tecnólogo Médico": [
    "Otorrinolaringología",
    "Morfofisiopatología y Citodiagnóstico",
    "Oftalmología",
    "Radiología y Física Médica",
    "Laboratorio Clínico, Hematología y Banco de Sangre",
  ],
  "Terapeuta Ocupacional": [
    "Adulto Mayor",
    "Neonatal y Pediátrica",
    "Rehabilitación Integral",
    "Salud Mental ",
    "Cuidados Paliativos",
    "Arte Terapia",
    "Trastornos Neuromotores",
    "Integración Sensorial",
    "Trastornos del Neurodesarrollo",
  ],
  "Masoterapeuta/Masajista": [
    "Relajación",
    "Piedras Calientes",
    "Ventosas",
    "Drenaje Linfático",
    "Reflexología",
    "Deportivo",
    "Reductor",
    "Tailandés",
    "Shiatsu",
    "Exfoliación Corporal",
    "Facial ",
    "Sueco",
    "Pre-Natal",
    "Descontracturante",
    "Reafirmante",
    "Maderoterapia",
  ],
  "Entrenador Físico": [
    "Gimnasia en Embarazo y Post-Parto",
    "Yoga Terapéutico",
    "Ejercicio Deportivo",
    "Pérdida de Peso",
    "Fitness",
  ],
  "Terapeuta Floral y Plantas Medicinales": [
    "Flores de Bach",
    "Fitoterapia",
    "Medicina Herbaria",
  ],
  "Baby Sitter": ["Bebes", "Infantes", "Niños", "Jóvenes"],
  "Cuidador de Mascotas": ["Gatos", "Perros", "Hurones", "Pájaros"],
  "Técnico de Laboratorio": ["Clinico", "Banco de Sangre", "Imagenología"],
  "Técnico en Nutrición": [],
  "Podologo/a": [],
  "Técnico en Radiología y Radioterapia": [],
  "Paramédico": [],
  "Quiropráctico ": [],
  "Veterinario/a": [],
  "Cosmetologo/a": [],
  "Auxiliar de Farmacia": [],
  "Bioquimico/a": [],
  "Psicopedagogo/a": [],
  "Químico Farmacéutico": [],
  "Técnico en Farmacia": [],
};

export const serviceDictionary = {
    "consulta_medica": [
        "Medicina General",
        "Medicina Interna",
        "Geriatria",
        "Pediatría",
        "Neurología",
        "Fisiatría",
        "Medicina del Dolor y Cuidados Paliativos",
        "Endocannabinogía",
        "Nutriología" ,
        "Psiquiatría",
        "Enfermedades Respiratorias",
        "Otros"
    ],

    "madre_y_bebe": [
        "Matrona",
        "Enfermero/a",
        "Cuidador/Chaperon/a/Shadow",
        "Tecnico en Enfermería (TENS)",
        "Nutricionista",
        "Nutriología ",
        "Enfermedades Respiratorias",
        "Kinesiologo/a",
        "Entrenador Físico",
        "Mesoterapeuta/Masajista",
        "Cosmetologo/a",
        "Terapeuta Floral y Plantas Medicinales",
        "Terapeuta Natural y Terapias Alternativas",
        "Baby Sitter",
        "Otros",
    ],     
    "nutricion": [
        "Nutricionista",
        "Psicologo/a",
        "Entrenador Físico",
        "Nutriología ",
        "Otros",
    ],

    "paciente_critico": [
        "Medicina General",
        "Medicina Interna",
        "Medicina del Dolor y Cuidados Paliativos",
        "Endocannabinogía",
        "Enfermero/a",
        "Tecnico en Enfermería (TENS)",
        "Paramédico",
        "Cuidador/Chaperon/a/Shadow",
        "Kinesiólogo/a",
        "Terapeuta Ocupacional",
        "Fonoaudiologo/a",
        "Psicologo/a",
        "Psiquiatra",
        "Otros",
    ],   

    "terapia_fisica": [
        "Fisiatra",
        "Kinesiologo/a",
        "Entrenador Físico",
        "Quiropáctico ",
        "Mesoterapeuta/Masajista",
        "Otros",
    ],   

    "adulto_mayor": [
        "Medicina General",
        "Medicina Interna",
        "Geriatria",
        "Neurología",
        "Fisiatría",
        "Neurología",
        "Psiquiatría",
        "Enfermero/a",
        "Tecnico en Enfermería (TENS)",
        "Cuidador/Chaperon/a/Shadow",
        "Kinesiólogo/a",
        "Terapeuta Ocupacional",
        "Fonoaudiologo/a",
        "Nutricionista",
        "Podólogo/a",
        "Otros",
    ],   
    
    "salud_mental": [
        "Neurología",
        "Psiquiatría",
        "Psicologo/a",
        "Consejero/a orientador/a familiar",
        "Otros",
    ],   

    "inyecciones": [
        "Enfermero/a",
        "Tecnico en Enfermería (TENS)",
        "Cuidador/Chaperon/a/Shadow",
        "Paramédico",
        "Otros",
    ],   
    
    "medicina_alternativa": [
        "Terapia Floral y Plantas Medicinales",
        "Terapeuta Natural y Terapias Alternativas",
        "Podólogo/a",
        "Quiropráctico ",
        "Mesoterapeuta/Masajista",
        "Otros",
    ],   

    "mascotas": [
        "Médico Veterinario ",
        "Cuidador de Mascotas",
        "Otros",
    ],     
    "necesidades_especiales": [
        "Neurología",
        "Psiquiatría",
        "Psicóloga/o",
        "Kinesiologo/a",
        "Terapeuta Ocupacional",
        "Fonoaudiologo/a",
        "Terapeuta Natural y Terapias Alternativas",
        "Terapia Floral y Plantas Medicinales",
        "Cuidador/Chaperon/a/Shadow",
        "Psicopedagogo/a",
        "Otros",
    ],   
    "estetica": [
        "Kinesiologo/a",
        "Nutricionista",
        "Nutriología ",
        "Quiropráctico ",
        "Entrenador Físico",
        "Mesoterapeuta/Masajista",
        "Cosmetologo/a",
        "Terapeuta Floral y Plantas Medicinales",
        "Terapeuta Natural y Terapias Alternativas",
        "Tecnico en Enfermería (TENS)",
        "Enfermero/a",
        "Otros",
    ],   
    "teleconsulta": [
        "Fonoaudiologo/a",
        "Kinesiologo/a",
        "Medico",
        "Psicologo/a",
        "Nutricionista",
        "Matrona",
        "Terapeuta Natural y Terapias Alternativas",
        "Terapeuta Ocupacional",
        "Entrenador Físico ",
        "Terapeuta Floral y Plantas Medicinales",
        "Veterinario/a",
        "Psicopedagogo/a",
        "Consejero/a orientador/a familiar",
        "Otros",
    ],   
}

export const shiftDescriptionExamples = {
    "madre_y_bebe": "p. ej. Necesito Baby Sitter para mis dos hijos, un niño de 4 años y una niña de 7 años para 3 horas en la noche. Ideal que ayude a que los niños se acuesten y acompañarlos mientras están dormidos.",
    "adulto_mayor": "p. ej. Busco profesional especialista en cuidado de adulto mayor para persona de 85 años postrado para que haga el turno de noche de lunes a viernes y que nos ayude a darle de comer, darle los remedios, baño, etc durante el horario de 20 hrs a 8:00 am.",   
    "mascotas": "p. ej. Busco paseador de perro confiable y que sea bueno y quiera mucho a los animales para pasear a mi perrito Beagle 5 veces por semana, de lunes a viernes,  idealmente en las tardes. Paseos de aproximadamente una hora.",
    "inyecciones": "p. ej. Busco Enfermera en mi domicilio para poner medicamento intramuscular a paciente mujer de 48 años.",
    "salud_mental": "p. ej. Necesito una terapia psicológica para hacer tratamiento de pareja. Somos una pareja con 18 años de matrimonio que estamos con algunos problemas en nuestra relación y necesitamos terapia ya sea presencial o vía online, ojalá con bastante experiencia en crisis de parejas.",
}