import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextInput, Button, Select } from "@mantine/core";
import { register } from "../../../features/auth/authSlice";
import { specialties, subSpecialties } from "../../../data/specialties";
import { validate } from 'rut.js'
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { BiError as ErrorIcon } from "react-icons/bi";

const ProfessionalSignup = () => {
  const [formData, setFormData] = useState({
    role: "Professional",
    username: "",
    password: "",
    confirmPassowrd: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    rut: "",
    specialty: "",
    subSpecialty: "",
    description: "",
  });

  const [specialty, setSpecialty] = useState(null);
  const [subSpecialty, setSubSpecialty] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hasRutErrors, setRutErrors] = useState(false);
  const [hasUsernameErrors, setUsernameErrors] = useState(false);

  const inputChangeHandler = (event) => {
    if (event.target.name === "rut") validate(event.target.value) ? setRutErrors(false): setRutErrors(true);
    if (event.target.name === "username") {
        
        const regex = /^[A-Za-z0-9@.+_-]+$/;
        if (!regex.test(event.target.value)) {
          setUsernameErrors(true)
        } else {
            setUsernameErrors(false)
        }
        }

    setFormData((prevValue) => ({
      ...prevValue,
      [event.target.name]: event.target.value,
    }));
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    //true (there is an error)
    if (hasRutErrors) return
    if (hasUsernameErrors) return;

    const userData = {
      ...formData,
      specialty: specialty,
      subSpecialty: subSpecialty,
    };
    try {
      await dispatch(register(userData)).unwrap();
      navigate("/professional");
    } catch (error) {
      console.log(error);
      console.log(error);
      if (error.username){
        showNotification({
          title: "Error!",
          message: error.username[0],
          color: "red",
          autoClose: 3000,
          icon: <ErrorIcon />,
        });
      }
    }
  };

  const handleGoogleSignup = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (tokenResponse) => {
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      const { email } = userInfo;

      setFormData((prevState) => ({
        ...prevState,
        email: email,
      }));
    },
  });

  return (
    <form className="mt-16 mb-12" onSubmit={submitFormHandler}>
      <TextInput
        type="text"
        name="username"
        placeholder="Nombre de usuario"
        aria-label="Username"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] border-b-0 rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
        }}
        error={hasUsernameErrors && "Este valor puede contener solo letras, números y caracteres @ . + - _"}
        value={formData.username}
        onChange={inputChangeHandler}
      />
      <TextInput
        type="password"
        name="password"
        placeholder="Tu contraseña"
        aria-label="Password"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] border-b-0 rounded-t-none rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.password}
        onChange={inputChangeHandler}
      />
      <TextInput
        type="password"
        name="confirmPassowrd"
        placeholder="confirmar Contraseña"
        aria-label="Confirm Password"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] border-b-0 rounded-t-none rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.confirmPassowrd}
        onChange={inputChangeHandler}
      />
      <TextInput
        type="text"
        name="firstName"
        placeholder="primer nombre"
        aria-label="First Name"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] border-b-0 rounded-t-none rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.firstName}
        onChange={inputChangeHandler}
      />
      <TextInput
        type="text"
        name="lastName"
        placeholder="apellido"
        aria-label="Last Name"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] border-b-0 rounded-t-none rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.lastName}
        onChange={inputChangeHandler}
      />
      <TextInput
        type="email"
        name="email"
        placeholder="Tu email"
        aria-label="Email"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] border-b-0 rounded-t-none rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.email}
        onChange={inputChangeHandler}
      />
      <TextInput
        type="tel"
        name="telephone"
        placeholder="Número de teléfono"
        aria-label="Telephone"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] rounded-t-none rounded-[5px] rounded-b-none focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.telephone}
        onChange={inputChangeHandler}
      />
      <TextInput
        type="text"
        name="rut"
        placeholder="Rut (Ej: 11.111.111-1)"
        aria-label="rut"
        required
        size="md"
        error={hasRutErrors && "Ingrese un rut válido"}
        classNames={{
          input:
            "border border-[#E6E6E6] rounded-t-none rounded-[5px] focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.rut}
        onChange={inputChangeHandler}
      />
      <div className="flex w-full my-3">
        <Select
          searchable
          placeholder="Tu especialidad"
          className="w-full"
          allowDeselect
          onChange={(value) => {
            setSubSpecialty(null);
            setSpecialty(value);
          }}
          data={specialties}
        />
        <Select
          searchable
          placeholder="Tu subespecialidad"
          className="w-full ml-2"
          disabled={specialty ? false : true}
          allowDeselect
          onChange={setSubSpecialty}
          value={subSpecialty}
          data={specialty ? subSpecialties[specialty] : []}
        />
      </div>
      <TextInput
        type="text"
        name="description"
        placeholder="Descripción personal o biografía"
        aria-label="Description"
        required
        size="md"
        classNames={{
          input:
            "border border-[#E6E6E6] rounded-t-none rounded-[5px] focus:border-[#E6E6E6] text-sm",
        }}
        value={formData.description}
        onChange={inputChangeHandler}
      />
      <Button
        type="submit"
        className="bg-primary-dark text-white w-full hover:bg-primary-dark rounded-[5px] h-10 border-none mt-6 mb-4"
      >
        Crea tu cuenta
      </Button>
      <Button
        className="bg-primary-light text-white w-full hover:bg-primary-dark rounded-[5px] h-10 border-none"
        onClick={() => handleGoogleSignup()}
      >
        Sign up with Google 🚀{" "}
      </Button>
    </form>
  );
};

export default ProfessionalSignup;
