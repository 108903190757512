import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Drawer,
  Button,
  UnstyledButton,
  Skeleton,
  Loader,
} from "@mantine/core";
import { ImArrowLeft2 } from "react-icons/im";
import { MdLocationCity as CityIcon } from "react-icons/md";
import { BsFillCalendarDateFill as DateIcon } from "react-icons/bs";
import { BsFillClockFill as ClockIcon } from "react-icons/bs";
import useAxios from "../../../hooks/useAxios";
import axios from "../../../api/axiosConfig";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const ShiftDetails = () => {
  const { shiftId } = useParams();
  const [drawerIsOpen, setDrawerIsOpen] = useState(null);
  const [shift, loading, error, axiosFetch] = useAxios();

  const navigate = useNavigate();

  useEffect(() => {
    const getShift = async () => {
      await axiosFetch({
        axiosInstance: axios,
        method: "GET",
        url: `/api/all_shifts/${shiftId}`,
      });
    };

    getShift();
  }, [axiosFetch, shiftId]);

  useEffect(() => {
    if (shiftId) {
      setDrawerIsOpen(true);
    }
  }, [shiftId]);

  useEffect(() => {
    let timer;
    //  Redirect when drawer's close animation finishes
    if (drawerIsOpen === false)
      timer = setTimeout(() => {
        navigate(-1);
      }, 400);

    return () => clearTimeout(timer);
  }, [drawerIsOpen, navigate]);

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  if (error) return <h2>Something went wrong!</h2>;
  // Extend dayjs to support <diff> function for calculating the
  // difference between 2 dates
  dayjs.extend(localizedFormat);
  const dateShiftCreated = dayjs(shift.created).format("ll");

  return (
    <Drawer
      opened={drawerIsOpen}
      position="right"
      onClose={closeDrawerHandler}
      transitionDuration={400}
      classNames={{
        drawer: "!bg-[#AEE3EC] overflow-y-auto",
        closeButton: "border border-black",
      }}
      size={1025}
      withCloseButton={false}
      aria-labelledby="drawer-title"
      aria-describedby="drawer-body"
    >
      <div className="text-primary-text-color">
        <div className="bg-blue-100 p-8 border">
          <UnstyledButton
            className="flex focus:outline-none"
            onClick={closeDrawerHandler}
          >
            <ImArrowLeft2 className="text-lg hover:text-primary-light cursor-pointer" />
          </UnstyledButton>
        </div>

        <div id="drawer-body" className="md:flex m-9">
          <div className="border border-blue-300 bg-blue-100 rounded-t-md md:rounded-md md:rounded-r-none md:w-9/12">
            {/* Title */}
            <div className="border-b border-blue-300 py-5 px-6">
              {loading && <Skeleton height={28} />}
              {!loading && shift.id && (
                <h1 id="drawer-title" className="text-xl font-bold">
                  {shift.title}
                </h1>
              )}
            </div>
            {/* Genera Info */}
            <div className="border-b border-blue-300 py-5 px-6 text-sm">
              {loading && <Skeleton height={20} className="mb-1" />}
              {!loading && shift.id && (
                <p className="text-primary-dark mb-1 font-bold">
                  {shift.position}
                </p>
              )}
              {loading && <Skeleton height={20} className="mb-3" />}
              {!loading && shift.id && (
                <p className="mb-3">
                  Breve descripcion: {shift.description_short}
                </p>
              )}
              {loading && <Skeleton height={20} />}
              {!loading && shift.id && <p>{`Creado en ${dateShiftCreated}`}</p>}
            </div>
            {/* Shift description */}
            <div className="border-b border-blue-300 py-5 px-6 text-sm">
              <p className="">{shift.description}</p>
            </div>
            {/* Shift Details */}
            <div className="py-5 px-6 text-sm sm:flex justify-between">
              {loading && (
                <>
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                  <Skeleton height={120} className="mb-6 md:w-1/4 md:mb-0" />
                </>
              )}
              {!loading && shift.id && (
                <>
                  <div className="mb-6 md:mb-0">
                    <p className="flex items-center justify-center font-bold mb-2">
                      <CityIcon className="mr-1 text-xl" />
                      Ubicacion
                    </p>
                    <ul className="flex flex-col items-center">
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Direccion:
                        </span>{" "}
                        {shift.location_address}
                      </li>
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Ciudad:
                        </span>{" "}
                        {shift.location_city}
                      </li>
                      <li>
                        <span className="text-primary-dark font-medium">
                          Region:
                        </span>{" "}
                        {shift.location_region}
                      </li>
                    </ul>
                  </div>
                  <div className="mb-6 md:mb-0">
                    <p className="flex items-center justify-center font-bold mb-2">
                      <DateIcon className="mr-1 text-base" />
                      Duracion del turno
                    </p>
                    <ul className="flex flex-col items-center">
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Fecha de inicio:
                        </span>{" "}
                        {shift.start_date}
                      </li>
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Fecha de termino:
                        </span>{" "}
                        {shift.end_date}
                      </li>
                      <li className="mb-1">
                        <span className="text-primary-dark font-medium">
                          Duracion:
                        </span>{" "}
                        {shift.shift_length_in_hours} hours
                      </li>
                      <li>
                        <span className="text-primary-dark font-medium">
                          Turnos por semana:
                        </span>{" "}
                        {shift.shifts_per_week}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="flex items-center justify-center font-bold mb-2">
                      <ClockIcon className="mr-1 text-xl" />
                      Salario
                    </p>
                    <ul className="flex flex-col items-center">
                      <li>
                        <span className="text-primary-dark font-medium">
                          Por hora:
                        </span>{" "}
                        ${shift.shift_hourly_rate}
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Right Side */}
          <div className="border border-blue-300 bg-blue-100 border-t-0 rounded-b-md md:border-t md:border-l-0 md:rounded-md md:rounded-l-none md:w-3/12">
            <div className="border-b border-blue-300 py-4 px-6">
              {loading && <Loader size="sm" className="mx-auto" />}
              {!loading && shift.id && (
                <>
                  <Button
                    variant="default"
                    classNames={{
                      root: "!bg-primary-dark !text-white !w-full  !hover:bg-primary-dark !rounded-full !h-10 !border-none !mb-3",
                    }}
                    onClick={() => {
                      navigate(`/professional/proposals/${shiftId}/apply`);
                    }}
                  >
                    Crear propuesta
                  </Button>
                  <p className="text-sm text-center">
                    Contacta al usuario {shift.owner} para mas detalles
                  </p>
                </>
              )}
            </div>
            <div className="py-4 px-6">
              <h2 className="text-sm font-bold mb-2">Acerca del paciente</h2>
              <p className="text-sm"></p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ShiftDetails;
