import { Avatar, Indicator } from "@mantine/core";
import { Link, useParams, useNavigate } from "react-router-dom";
import Container from "../../../components/UI/Container";
import { MdVerified as VerifiedIcon } from "react-icons/md";
import axios from "axios";
import { BASE_URL } from '../../../api/axiosConfig';
import { useEffect, useState } from "react";


const ExternalProfile = () => {
    const { professionalUsername } = useParams();
    const [professionalData, setprofessionalData] = useState();
    const navigate = useNavigate();
	
    // 1. Get the professional profile from the username o email
    const getUserData = async (username) => {

        let responsesConcat = {};
        
        // GET => /api/users/:username -> retrieve logged in user's info
        const usersEndpointResponse = await axios.get(`/api/users/${username}/`, {
        baseURL: BASE_URL,
        });  
            // GET => /api/profiles/:username -> retrieve logged in user's profile data
        const profilesEndpointResponse = await axios.get(
            usersEndpointResponse.data.links.profile,
            { baseURL: BASE_URL }
        );
                
        if (usersEndpointResponse.data && profilesEndpointResponse.data) {
            responsesConcat = {
                ...usersEndpointResponse.data,
                ...profilesEndpointResponse.data,
            };
        }    
        return responsesConcat    
    };
    
    useEffect(() => {
        getUserData(professionalUsername).then((res) => {
            setprofessionalData(res)    
        })
    }, [professionalUsername])

    
    // Add a button to return to the previous proposals page.
    const goBack = () => {
		navigate(-1);
    }

  return (
    <>
    {professionalData && (
      <section className="py-8">
        <Container>
          <div className="bg-white rounded-[5px] border border-primary-light shadow-lg">
            <div className="flex justify-between items-center p-6 border-b border-primary-light">
              <div className="flex">
                <div className="relative">
                  <Indicator
                    color="green"
                    position="bottom-end"
                    withBorder
                    size={15}
                    offset={8}
                  >
                    <Avatar size="70px" radius="500px" src={professionalData.image}/>
                  </Indicator>
                </div>
                <h1 className="ml-8 text-xl font-semibold">{professionalData.first_name} {professionalData.last_name}</h1>
              </div>
            </div>

            <div className="flex min-h-[300px]">
              <div className="border-r border-primary-light p-6">
                {/* Verifications */}
                <div className="mb-6">
                  <h4 className="font-bold mb-2">Información de Contácto</h4>
                  <ul className="text-sm">
                    <li className="mb-1">
                      <p className="font-semibold flex items-center">
                        Phone Number:
                        <span className="font-normal ml-1">
                          {/* {user.phone_number} */}
                          ---------
                        </span>
                        <span className="ml-1">
                          <VerifiedIcon className="text-blue-700 text-lg" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold flex items-center ">
                        Email:
                        <span className="font-normal ml-1">-------</span>
                        <span className="ml-1">
                          <VerifiedIcon className="text-blue-700 text-lg" />
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                {/* Education */}
                <div className="mb-6">
                  <h4 className="font-bold mb-2">Educación</h4>
                  <ul className="text-sm">
                    <li className="mb-1">
                      <p className="font-semibold flex items-center">
                        (En construcción)
                      </p>
                    </li>
                  </ul>
                </div>
                
              </div>
              <div className="p-6 flex-grow">
                <div className="flex items-center justify-between w-full mb-3">
                  <h2 className="text-xl font-bold text-primary-dark">
                    {professionalData.specialty}{" "}
                    <span className="font-normal text-base text-primary-light">
                      {professionalData.sub_specialty}
                    </span>
                  </h2>
                </div>
                <p>{professionalData.description}</p>
              </div>
            </div>
          </div>
        </Container>
      </section>      
    )}  
        <Container>
        <div>
            <Link
                to="/"
                onClick={goBack}
                className="border border-primary-dark px-6 py-2 rounded-full text-primary-dark font-semibold text-sm hover:bg-gray-50"
            >
               Volver
            </Link>
        </div>
        </Container>
    </>
  );
};

export default ExternalProfile;
