import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mantine/core";

import Container from "../../UI/Container";
import {
  selectShifts,
  getPatientShifts,
} from "../../../features/shifts/shiftsSlice";
import ShiftItem from "./ShiftItem";

const ShiftList = () => {
  const { shifts, status, error } = useSelector(selectShifts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientShifts());
  }, [dispatch]);

  let content;
  if (status === "loading") {
    content = <p>Loading...</p>;
  } else if (status === "succeeded") {
    content = (
      <ul>
        {shifts.length > 0 &&
          shifts.map((shift) => <ShiftItem key={shift.id} shift={shift} />)}
      </ul>
    );
  } else if (status === "failed") {
    content = <p>{error}</p>;
  }

  return (
    <section>
      <Container>
        <div className="bg-white rounded-[5px]">
          <h3 className="text-lg text-primary-dark font-bold px-4 py-5">
            Tus turnos
          </h3>
          <Divider className="border-primary-dark" />
          {content}
        </div>
      </Container>
    </section>
  );
};

export default ShiftList;
