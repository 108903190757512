import React from "react";
import { Link } from "react-router-dom";
import { BsTelephone as PhoneIcon } from "react-icons/bs";
import { BsGlobe as GlobeIcon } from "react-icons/bs";
import { BiEnvelope as EnvelopIcon } from "react-icons/bi";
import { IoLogoInstagram as InstagramIcon } from "react-icons/io";
import { MdFacebook as FacebookIcon } from "react-icons/md";
import { IoLogoTwitter as TwitterIcon } from "react-icons/io";
import { IoLogoLinkedin as LinkedinIcon } from "react-icons/io";

import Container from "../UI/Container";
import Emoji from "../UI/Emoji";

import footerLogo from "../../assets/images/footer_logo.png";

const Footer = () => {
  return (
    <footer className="bg-white">
      {/* Main Footer */}
      <div className="md:py-[50px]">
        <Container>
          <div className="font-bold text-secondary-text-color md:flex md:justify-between md:items-center">
            {/* Logo Container */}
            <div>
              <img
                src={footerLogo}
                alt="Footer logo"
                className="w-[127px] mb-10 md:max-w-full"
              />
            </div>

            {/* Navigation Container */}
            <div>
              <h3 className="text-primary-text-color mb-5 mt-5">Carendar</h3>
              <ul className="text-sm">
                <li className="mb-[10px]">
                  <Link
                    to="/profesionales"
                    className="hover:text-primary-light"
                  >
                    Profesionales
                  </Link>
                </li>
                <li className="mb-[10px]">
                  <Link
                    to="/instituciones"
                    className="hover:text-primary-light"
                  >
                    Centros de salud / Pacientes
                  </Link>
                </li>
                <li className="mb-[10px]">
                  <Link to="/nosotros" className="hover:text-primary-light">
                    Nosotros
                  </Link>
                </li>
                <li className="mb-[10px]">
                  <Link to="/privacy_policy" className="hover:text-primary-light">
                    Política de Privacidad
                  </Link>
                </li>
                <li className="mb-[10px]">
                  <Link to="/terms_of_service" className="hover:text-primary-light">
                    Términos de Servicio
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact Container */}
            <div>
              <h3 className="text-primary-text-color mb-5 mt-5">Contáctanos</h3>
              <ul className="text-sm">
                <li className="mb-[10px] flex items-center">
                  <PhoneIcon className="text-2xl text-primary-light mr-[10px]" />
                  <p>(+56) 9 7161 3704</p>
                </li>
                <li className="mb-[10px] flex items-center">
                  <GlobeIcon className="text-2xl text-primary-light mr-[10px]" />
                  <p>www.carendar.app</p>
                </li>
                <li className="flex items-center">
                  <EnvelopIcon className="text-2xl text-primary-light mr-[10px] mb-2" />
                  <p>contacto@carendar.app</p>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>

      {/* Secondary Footer */}
      <div className="bg-light-gray py-6">
        <Container>
          <div className="font-bold text-sm flex items-center justify-center">
            <p className="text-secondary-text-color">
              Hecho con <Emoji symbol="💜" label="Love" /> por Carendar
            </p>
            <div className="ml-32">
              <ul className="text-2xl text-primary-light flex items-center">
                <li>
                  <a href="https://www.facebook.com/Carendarapp">
                    <FacebookIcon />
                  </a>
                </li>
                <li className="ml-5">
                  <a href="https://www.instagram.com/carendarapp/">
                    <InstagramIcon />
                  </a>
                </li>
                <li className="ml-5">
                  <a href="https://www.linkedin.com/company/carendar/">
                    <LinkedinIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
